import React, { Component } from 'react'

export default class CookiePolicy extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            showPolicy: true
        }
    }

    componentDidMount = () =>{
        let ADPolicy = JSON.parse(localStorage.getItem('ad_policy'))
        if (ADPolicy)
            this.setState({showPolicy:false})
    }

    acceptCookie = () => {
        let ADPolicy = JSON.parse(localStorage.getItem('ad_policy'))
        if (!ADPolicy) {
            localStorage.setItem('ad_policy', true)
            this.setState({ showPolicy: false })
        }
    }
    
    render() {
        return (
            <>
                {this.state.showPolicy && (
                    <div className="cookie-policy-wrapper">
                        <p>We use cookies on our websites to accumulate view count on our blog analytics and nothing more.</p>
                        <button onClick={this.acceptCookie}>OK</button>
                    </div>
                )}
            </>
        )
    }
}
