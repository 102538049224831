import axios from "axios"
import $ from "jquery"
import React, {Component} from 'react'
import Select from "react-dropdown-select";
import Swal from "sweetalert2"
import AnimationLoader from '../Animations/AnimeLoader'
import Config from '../pages/Config'


//import ReCAPTCHA from "react-google-recaptcha"





class AllPageContactForm extends React.Component{
    

    constructor(props) {
        super(props)
    
        this.state = {
            name: "",
            name_err: false,
            email: "",
            email_err: false,
            phone: "",
            phone_err: false,
            /*subjects: [
                'Regarding', 'Website design & development', 'Digital marketing', 'Branding', 'Content marketing', 'SaaS development', 'SEO', 'Graphic designing',
            ],*/
            subjects :[{ label: "Website design & development", value: 1 },{ label: "Digital marketing", value: 2 },{ label: "Branding", value: 3 },{ label: "Content marketing", value: 4 },{ label: "SaaS development", value: 5 },{ label: "SEO", value: 6 },{ label: "Graphic designing", value: 7 }],
            subject: '',
            subject_err: false,
            message_err: false,
            message: "",
            message_err: false,
            sitekey: '6Le49NwaAAAAABo2E3vYaGDeHxHqB02M5eNYGY35',
            captcha_value: '',
            show_loader: false,
            show_animate_loader: false,
        }
    }

    componentDidMount = () => {
        this.emptyForm()
    }

    emptyForm = () => {
        this.setState({
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        switch (e.target.name) {
            case 'name': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        name_err: '*Name is required'
                    })
                }else {
                    this.setState({
                        name_err: false
                    })
                }
            }
            case 'email': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        email_err: '*Email is required'
                    })
                }else {
                    this.setState({
                        email_err: false
                    })
                }
                break;
            }
            case 'phone': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        phone_err: '*Phone is required'
                    })
                }else {
                    this.setState({
                        phone_err: false
                    })
                }
                break;
            }
            case 'subject': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        subject_err: '*Subject is required'
                    })
                }else {
                    this.setState({
                        subject_err: false
                    })
                }
                break;
            }
            case 'message': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        message_err: '*Message is required'
                    })
                }else {
                    this.setState({
                        message_err: false
                    })
                }
                break;
            }
        }
    }

    captchaChange = (value) => {
        this.setState({
            captcha_value: value
        })
    }

    emptyCaptcha = (e) => {
        this.setState({
            captcha_value: ''
        })
    }
    subjectChange = (value)=>{
        
        this.setState({
            subject: value[0]['label']
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        console.log('subject'+this.state.subject);
        if(this.state.name == '' || this.state.phone == '' || this.state.email == '' || this.state.subject == '' || this.state.subject == this.state.subjects[0] || this.state.message == ''){
            // alert("Please fill all necessary field")
            Swal.fire({  
                icon: 'error',   
                text: 'Please fill all necessary field' 
            });
            return
        }
        /*if(this.state.captcha_value == ''){
            // alert("Please select the captcha")
            Swal.fire({  
                icon: 'error',   
                text: 'Please select the captcha'
            });
            return
        }*/
        const headers = {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
        }
        let formData = new FormData();
        formData.append('name', this.state.name)
        formData.append('phone', this.state.phone)
        formData.append('email', this.state.email)
        formData.append('subject', this.state.subject)
        formData.append('message', this.state.message)
        let url = Config.API_URL+'/api/lead'
        this.setState({
            show_loader: true
            // show_animate_loader: true
        })
        axios.post(url, formData, headers)
        
        .then(response => {
            window.location.href = "/thank-you-alchem";
            const img = new Image()
            img.src = `https://px.ads.linkedin.com/collect/?pid=2789156&conversionId=4483804&fmt=gif%22`
            this.emptyForm()
            this.emptyCaptcha()
            this.captcha.reset()
        }).catch(error => {
            //alert(error.response.data.error)
        }).finally(() => {
            this.setState({
                show_loader: false
                // show_animate_loader: false
            })
        })
    }
    
    render(){
        return(
            <React.Fragment>
                    <div className="container all-page-form-container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-4">
                                <div>
                                    <h1 className="ad-form-main-title mb-0 text-align-change">Break the ice!</h1>
                                    <h2 className="ad-form-top-title mb-1 text-align-change">Let's create digital alchemy together!</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-8">
                                <div className="all-page-contact-bg">
                                    <form action="" onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor=""></label>
                                                    <input type="text" name="name" id="name" className="form-control ad-form-control" placeholder="Full Name" onChange={this.handleChange} value={this.state.name}/>
                                                </div>
                                                <span className="alert-ui-new">{this.state.name_err}</span>
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor=""></label>
                                                    <input type="number" name="phone" id="phone" className="form-control ad-form-control" placeholder="Phone No." onChange={this.handleChange} value={this.state.phone}/>
                                                </div>
                                                <span className="alert-ui-new">{this.state.phone_err}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor=""></label>
                                                    <input type="email" className="form-control ad-form-control" name="email" id="email" placeholder="Email ID" onChange={this.handleChange} value={this.state.email}/>
                                                </div> 
                                                <span className="alert-ui-new">{this.state.email_err}</span>
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor=""></label>
                                                    {/*<Select className="ad-form-control ad-form-select form-select-arrow" name="subject" onChange={this.handleChange} value={this.state.subject}>
                                                        { 
                                                            this.state.subjects.map((subject, key) => {
                                                                if (key == 0)
                                                                    return (
                                                                        <option key={key} selected disabled>{subject}</option>
                                                                    )
                                                                return (
                                                                    <option key={key} value={subject}>{subject}</option>
                                                                )
                                                            })
                                                        }
                                                    </Select>*/}
                                                    <Select className="all-page-contact-form-select" options={this.state.subjects} name="subject" onChange={this.subjectChange} value={this.state.subject} placeholder="Regarding"/>
                                                    <span className="alert-ui-new">{this.state.subject_err}</span>
                                                </div> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor=""></label>
                                            <textarea className="form-control ad-form-control ad-form-control-textarea" placeholder="Your Message" name="message" id="message" rows="5" onChange={this.handleChange} value={this.state.message}></textarea>
                                        </div>
                                        <span className="alert-ui-new">{this.state.message_err}</span>
                                        {/*<ReCAPTCHA
                                            ref={e => (this.captcha = e)}
                                            onChange = {this.captchaChange}    
                                            sitekey={this.state.sitekey}
                                            onExpired={this.emptyCaptcha}
                                            onErrored={this.emptyCaptcha}
                                        />*/}
                                        {
                                            this.state.show_loader ? 
                                                <div className="text-align-change mt-3">
                                                    <button type="button" className="alchem-digi-submit-button button-gradient" disabled>Sending...</button>
                                                </div>
                                            :
                                                <div className="text-align-change mt-3">
                                                    <button type="submit" className="alchem-digi-submit-button button-gradient">Send Message</button>
                                                </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {
                    this.state.show_animate_loader ? 
                    <div className="contact-form-loader-bg">
                        <AnimationLoader/>
                    </div>
                    :
                    <div className="contact-form-loader-bg" hidden>
                        <AnimationLoader/>
                    </div>
                    } */}
            </React.Fragment>
        );
    }
}

export default AllPageContactForm 
