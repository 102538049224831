import React from 'react';
import error404 from './error-404.json'
import LottieAnimation from './Lottie'


const ADPreloader = () => {
    return ( 
        <div className='not-found-loader'> 
            <LottieAnimation lotti={error404}/>
        </div>
    )
}

export default ADPreloader;