import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import React, {Component} from 'react'
import Config from '../pages/Config'
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

class AllPageTestimonial extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
            testiContent: [
                {
                    clientName: "LakshmiDevi Ethirajan",
                    clientdesc: "/  CEO - Srida Herbals",
                    content: "I am so happy that I was introduced to the Alchem Digital team when I was looking for someone to support with building our new website. The team was so understanding of my requirements and they guided me in the best path to get the best website that was visually so clean and eye pleasing. It is also easy for the customers to navigate, purchase, and checkout products. Since our launch I have many visitors provide nice compliments about our website. I would recommend Alchem digital to anyone wanting to have their best website at an affordable price. The Alchem team also helped me with my social media management. They made my profile look really good. I had an increase in the number of followers and engagement with their help. Thank you, Alchem!",
                    mobImg: "testi-res-1.jpg",
                    deskImg: "testimonial-1.jpg",
                    Imgalt: "Srida Herbals"
                },
                {
                    clientName: "Dr.Arun",
                    clientdesc: "/  Founder - Alshay",
                    content: "It was great working with Alchem Digital. I had a timeline to establish my business and had my work done on time. I had a prompt response every time. The quality of the work is exceptional. Wishing the Alchem team good luck.",
                    mobImg: "arun-mob.jpg",
                    deskImg: "arun.jpg",
                    Imgalt: "Arun"
                },
                {
                    clientName: "Sucharitha",
                    clientdesc: "/  Founder - Leia’s Organics",
                    content: "Alchem Digital is simply THE BEST web design company you can work with! As a small business, we were looking for a reasonably-priced design team to help bring to life the dream website we had in mind. Alchem Team offered the same services as competitors three or four times the expense and provided examples of their work to back up their talent. The design elements we requested were unique and this team not only created our vision but provided guidance and insight throughout the whole process. Leaving us with an absolutely stunning finished product! This team even continued to assist after our launch whenever we needed it. I would recommend THIS AMAZING TEAM to anyone who is looking to build a website for their business!",
                    mobImg: "sucharitha-mob.jpg",
                    deskImg: "sucharitha.jpg",
                    Imgalt: "Sucharitha"
                },
                {
                    clientName: "Cheryl",
                    clientdesc: "/  Founder - keys 4 Life, Inc",
                    content: "In 2020, we re-branded our business, which meant our website needed a face lift as well. We encountered many challenges as we looked for companies who could capture the story we wanted our brand to tell, and then we were introduced to Alchem Digital. Alchem came highly recommended by a friend and after our introductory meeting, we knew we were partnering with a great company. From day one, we have enjoyed working with the members of the Alchem team. They provided new ideas, fresh concepts and were professional in all aspects of their work. We are very pleased with our new website, but most importantly, Alchem Digital made us feel valued as a customer. If you are preparing to launch a new business or re-brand a current one, we highly recommend the team of Alchem Digital.",
                    mobImg: "testi-res-2.jpg",
                    deskImg: "key-4-life.jpg",
                    Imgalt: "Keys 4 Life"
                },
                {
                    clientName: "Dr. Nourah",
                    clientdesc: "/  Co-founder CAAPID Simplified",
                    content: "As a US-based dentist of color, I had many problems when I launched my admissions counseling business in the US in the department of marketing. Alchem was amazing to work with. They do a good job understanding US customers, educating me on how to allocate digital marketing budgets, and provide leverage in day-to-day marketing. I trust the team's intuition and think of them as a partner, rather than a service provider. I have been able to improve my customer numbers 3-fold while maintaining the same CAC.",
                    mobImg: "nourah-mob.jpg",
                    deskImg: "nourah.jpg",
                    Imgalt: "Nourah"
                },
            ],
        }
    }
    
    render(){
        const options = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: true,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            autoHeight:true,
            loop: true,
            lazyLoad: true,
            autoplay: false,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <section className={this.props.color == "white" ? "res-dev-testimonial-carousel-1" :"res-dev-testimonial-carousel"} >
                    <div className="container home-page-testimonial">
                        <div className="row">
                            <div className="col-xs-12 col-sm-1 p-zero">
                                <div className="quote-icon">
                                    <img src={Config.HOST+"/assets/img/res-dev-asset/quote.svg"} alt="Quote" />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-11 p-zero">
                                <div className="owl-carousel-container transition-issue">
                                    {typeof window != 'undefined' &&
                                        <OwlCarousel className="testimonial-carousel" {...options}>
                                            {
                                                this.state.testiContent.map((testimonial, key) => {
                                                    {
                                                        return (
                                                            <div className="home-page-testimonial-container pl-3" key={key}>
                                                                <div className="home-page-testimonial-content">
                                                                    <div className="res-dev-testimonial-part">
                                                                        <h1 className="res-dev-testimonial-subtitle">Testimonial</h1>
                                                                            <div className="quote-icon-1">
                                                                                <img src={Config.HOST+"/assets/img/res-dev-asset/quote.svg"} alt="Quote" />
                                                                            </div>
                                                                        <p className="res-dev-testimonial-content">{testimonial.content}</p>
                                                                        <div className="mob-testi-img">
                                                                            <div className="res-dev-testimonial-img-sec">
                                                                                <img src={Config.HOST+'/assets/img/res-dev-asset/' + testimonial.mobImg} alt={testimonial.Imgalt}/>
                                                                            </div>
                                                                        </div>
                                                                        <p className="res-dev-testimonial-author">{testimonial.clientName} <span>{testimonial.clientdesc}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className="home-page-testimonial-img">
                                                                    <div className="res-dev-testimonial-img-sec">
                                                                        <img src={Config.HOST+'/assets/img/res-dev-asset/'+ testimonial.deskImg} alt={testimonial.Imgalt}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </OwlCarousel>
                                    }
                                </div>
                                <div className="gradient-collabs"><img src={Config.HOST+'/assets/img/res-dev-asset/gradient-collab.png'} alt="gradient-collabs"/></div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default AllPageTestimonial