import React, {Component} from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react'
import AlchemMapStyles from './AlchemMapStyles';


class AlchemMap extends React.Component{

    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      };

    _mapLoaded(mapProps, map) {
        map.setOptions({
        styles: AlchemMapStyles.greyStyle
        })
    }

    onMouseoverMarker(props, marker, e) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
    }

    // onMarkerClick = (props, marker, e) =>
    // this.setState({
    //   selectedPlace: props,
    //   activeMarker: marker,
    //   showingInfoWindow: true
    // });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: true,
        activeMarker: null
      })
    }
  };

    render(){
        return(
            <React.Fragment>
                <div className="map-container">
                    <Map google={this.props.google} zoom={14} initialCenter={{lat: 13.049586032941967,lng: 80.22171507371975}} onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}>
                        <Marker
                            onMouseover={this.onMarkerClick}
                            name={'Current location'}
                            // name={'Alchem Digital'}
                            position={{lat: 13.049586032941967,lng: 80.22171507371975}}
                            icon={{
                              url: "../../assets/img/map-marker.png",
                              anchor: new window.google.maps.Point(40,40),
                              scaledSize: new window.google.maps.Size(80,70)
                            }} />
                        <InfoWindow
                          marker={this.state.activeMarker}
                          visible={this.state.showingInfoWindow}>
                            <div>
                              <h1>{this.state.selectedPlace.name}</h1>
                            </div>
                        </InfoWindow>
                    </Map>
                </div>
            </React.Fragment>
        );
    }
}


export default GoogleApiWrapper({
    apiKey: ("AIzaSyDinLJFY961JTNVF5KPjOoKogP_YLWVsmg")
  })(AlchemMap)