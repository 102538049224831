/* eslint-disable no-lone-blocks */

import $ from "jquery"
import React, {Component, useState} from 'react'
import { Link } from 'react-router-dom'
import FloatingIcons from '../FloatingIcons'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import { Helmet } from 'react-helmet-async'
import Config from "./Config"

class Portfolio extends React.Component{

    componentDidMount(){

        $(".portfolio-filter-cont span a").click(function(){
            $(".portfolio-filter-cont span a").removeClass("active");
            $(this).addClass("active");
        });

        $(".ad-portfolio-main-container .view-all  a").click(function(){
            $(".portfolio-filter-cont span a").removeClass("active");
        })

        // When an image is clicked
        $(".open-lightbox").click(function(event) {
            event.preventDefault();
            var imageLocation = $(this).attr("href");
            $("#main-img").attr("src", imageLocation);
            $("#overlay").fadeIn("slow");
            $('body').css('overflow','hidden');
            $('#overlay').animate({scrollTop: 0});
            // window.scrollTo(0, 0)
        });

        // When the overlay is clicked
        // $overlay.click(function() {
        //     $(this).fadeOut("slow");
        // });

        // When next button is clicked
        // $nextButton.click(function(event) {
        // $("#overlay img#main-img").hide();
        // var $currentImgSrc = $("#overlay img#main-img").attr("src");
        // var $currentImg = $('#image-gallery img[src="' + $currentImgSrc + '"]#enlarge-image');
        // var $nextImg = $($currentImg.closest(".ad-port-fadeInUp").next().find("img#enlarge-image"));
        // var $images = $("#image-gallery img#enlarge-image");
        // if ($nextImg.length > 0) { 
        // $("#overlay img#main-img").attr("src", $nextImg.attr("src")).fadeIn(800);
        //     if( $nextImg.attr("src") == '/assets/img/portfolio/ailaysa.png'){
        //         $('.custom-section').addClass('ailaysa-footer');
        //         $('.custom-section').removeClass('carefree-footer');
        //     }
        //     else if( $nextImg.attr("src") == '/assets/img/portfolio/carefree.png')
        //     {
        //         $('.custom-section').addClass('carefree-footer');
        //         $('.custom-section').removeClass('ailaysa-footer');
        //     }
        //     else{
        //         $('.custom-section').addClass('ailaysa-footer');
        //         $('.custom-section').removeClass('carefree-footer');
        //     }
        // } else {
        // $("#overlay img#main-img").attr("src", $($images[0]).attr("src")).fadeIn(800);
        // }
        // event.stopPropagation();
        // });

        // When previous button is clicked
        // $prevButton.click(function(event) {
        //     $("#overlay img#main-img").hide();
        //     var $currentImgSrc = $("#overlay img#main-img").attr("src");
        //     var $currentImg = $('#image-gallery img[src="' + $currentImgSrc + '"]#enlarge-image');
        //     var $nextImg = $($currentImg.closest(".ad-port-fadeInUp").prev().find("img#enlarge-image"));
        //     $("#overlay img#main-img ").attr("src", $nextImg.attr("src")).fadeIn(800);
        //     if( $nextImg.attr("src") == '/assets/img/portfolio/ailaysa.png'){
        //         $('.custom-section').addClass('ailaysa-footer');
        //         $('.custom-section').removeClass('carefree-footer');
        //     }
        //     else if( $nextImg.attr("src") == '/assets/img/portfolio/carefree.png')
        //     {
        //         $('.custom-section').addClass('carefree-footer');
        //         $('.custom-section').removeClass('ailaysa-footer');
        //     }
        //     else{
        //         $('.custom-section').addClass('ailaysa-footer');
        //         $('.custom-section').removeClass('carefree-footer');
        //     }
        //     event.stopPropagation();
        // });

        // When the exit button is clicked
        $("#exitButton").click(function() {
            $("#overlay").fadeOut("slow");
            $('body').css('overflow','auto');
        });

        // Open Lightbox
        // $('.open-lightbox').on('click', function(e) {
        //     e.preventDefault();
        //     var image = $(this).attr('href');
        //     $('html').addClass('no-scroll');
        //     $('body').append('<div className="lightbox-opened"><img src="' + image + '"></div>');
        // });
        
        // Close Lightbox
        // $('body').on('click', '.lightbox-opened', function() {
        //     $('html').removeClass('no-scroll');
        //     $('.lightbox-opened').remove();
        // });
    }
    constructor(props) {
        super(props);
        this.state = {
            portfolio : [
                {"category":"Website & Social ","image":"carefree-thumbnail.png","name": "Embrace prevention care", stateName: 'showWebsite', "tech": "UI/UX Design & Development | Social Media Marketing", "enlargeImg":"embrace.png"},
                {"category":"E-Commerce Website","image":"aazhi-thumbnail.png","name": "AazhiBooks", stateName: 'showWebsite', "tech": "Logo | UI/UX Design & Development", "enlargeImg":"aazhi-books.png"},
                {"category":"Web Application","image":"ailaysa-thumbnail.png","name": "Ailaysa", stateName: 'showWebapp', "tech": "Logo | UI/UX Design & Development | Social Media Marketing", "enlargeImg":"ailaysa.png"},
                // {"category":"Social Media Advertising","image":"port_3-min.jpg","name": "facebook", stateName: 'showSocial', "tech": "UX/UI Design", "enlargeImg":"carefree.png"},
                // {"category":"Website","image":"port_3-min.jpg","name": "insta", stateName: 'showSocial', "tech": "UX/UI Design", "enlargeImg":"carefree.png"},
                // {"category":"Website","image":"port_3-min.jpg","name": "insta", stateName: 'showSocial', "tech": "UX/UI Design", "enlargeImg":"carefree.png"},
            ],
            showWebapp: true,
            showWebsite: true,
            showSocial: true,
            showBranding:true,
        }
      }

      filter = (category) =>{
        switch (category) {
            case 'website':{
                this.setState({
                    showWebapp: false,
                    showSocial:false,
                    showBranding:false,
                    showWebsite: true,
                })
                break;
            }
            case 'webapp':{
                this.setState({
                    showWebsite: false,
                    showSocial:false,
                    showBranding:false,
                    showWebapp: true,
                })
                break;
            }
            case 'branding':{
                this.setState({
                    showWebsite: false,
                    showSocial:false,
                    showWebapp:false,
                    showBranding: true,
                })
                break;
            }
            case 'social':{
                this.setState({
                    showWebsite: false,
                    showBranding:false,
                    showWebapp:false,
                    showSocial: true,
                })
                break;
            }
            case 'all':{
                this.setState({
                    showWebsite: true,
                    showBranding:true,
                    showWebapp:true,
                    showSocial: true,
                })
                break;
            }
            default:
                break;
        }

        
      }

    render(){
        // var filterd = this.state.filteredItem[0];
        // console.log(filterd)
        // const pageTransition = {
        //     in: {
        //         opacity: 1,
        //         transition: {
        //             duration: 1.5
        //         }
        //     },
        //     out: {
        //         opacity: 0
        //     }
        // }
        return(
            <React.Fragment>
                <HeadTags path='portfolio' />
                <Header/>
                <section id="ad-portfolio-gallery" className="ad-portfolio-bg">
                    <div className="portfolio-bg-img">
                        <div className="ad-portfolio-main-container">
                            <div className="ad-portfolio-pages-hero-section">
                                <div className="ad-bread-crumbs">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Portfolio</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div>
                                    <p className="ad-about-main-title">We let our work speak!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="ad-portfolio-sections">
                        <div className="ad-portfolio-main-container">
                            <div id="ad-portfolio-galler-cont" className="portfolio-filter-cont">
                                Alchem Digital is a complete technology solution and a digital catalyst company. We create envious experience through<span><a className="active" href="#" onClick={e => e.preventDefault()}></a></span> our <span><a href="#" onClick = {() => this.filter('website')}>websites</a></span>, <span><a href="#" onClick = {() => this.filter('webapp')}>web applications</a></span>, <span><a href="#" onClick = {() => this.filter('branding')}>branding</a></span> and <span><a href="#" onClick = {() => this.filter('social')}>social media advertising</a></span>.
                            </div>
                            {/* <div className="view-all">
                                <a href="javascript:void(0);" onClick = {() => this.filter('all')}>View All</a>
                            </div> */}
                            <div className="portfolio-parts">
                                <div id="image-gallery" className="portfolio-row">
                                    {
                                        this.state.portfolio.map((item, key) => {
                                            {
                                                return ( this.state[item.stateName] &&
                                                    // <div className="portfolio-parts-content ad-port-animated ad-port-animatedFadeInUp ad-port-fadeInUp">
                                                    <div className="portfolio-parts-content" key={key}>
                                                    {/* <img className="ad-core-portfolio-img" src={process.env.PUBLIC_URL+`/assets/img/${item.image}`} alt={item.name}/> */}
                                                            <a href={`${Config.HOST}/assets/img/portfolio/${item.enlargeImg}`} className="open-lightbox" data-toggle="lightbox" data-gallery="gallery">
                                                        <img className="ad-core-portfolio-img" src={`${Config.HOST}/assets/img/${item.image}`} alt={item.image} />
                                                        <img id="enlarge-image" src={`${Config.HOST}/assets/img/portfolio/${item.enlargeImg}`} alt={item.enlargeImg} hidden/>
                                                            <div className="core-portfolio-overlay">
                                                                <div className="core-portfolio-items core-portfolio-top-head">
                                                                    <p>{item.name}</p>
                                                                    <hr/>
                                                                </div>
                                                                <div className="core-portfolio-items core-portfolio-head">
                                                                    <p>{item.category}</p>
                                                                </div>
                                                                <div className="core-portfolio-items core-portfolio-price">
                                                                </div>
                                                                <div className="core-portfolio-items core-portfolio-cart">
                                                                    <span>{item.tech}</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <div id="overlay">
                        <div id="overlay-new">
                            <img id="main-img" src={`${Config.HOST}/assets/img/portfolio/carefree.png`} />
                            <div className="custom-section carefree-footer">
                                {/* <div className="footer-container-1">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-5 alchem-thanks-txt">
                                            <p className="thanks-scrolling">Thanks <br/> for Scrolling by!</p>
                                        </div>
                                        <div className="col-xs-12 col-sm-7 text-center">
                                            <img src="/assets/img/alchem-logo.png" alt="alchem" />
                                            <p className="alchem-footer-txt">We engineer high-quality custom web application that solves business challenges worldwide.</p>
                                            <p className="alchem-footer-txt-1">We’re available for new projects! Drop us an E-mail at <span><a href="#">getintouch@alchemdigital.com</a></span></p><p className="alchem-footer-txt-2">For more portfoilios visit our <span><a href="#">Website</a></span> or <span><a href="#">Behance</a></span> profile.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div id="exitButton"><img src={`${Config.HOST}/assets/img/wrong-icon.svg`} alt="" /></div>
                        </div>
                    </div>
                </section>
                {/* <FloatingIcons/> */}
            </React.Fragment>
        );
    }
}


export default Portfolio
