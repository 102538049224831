import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class SaasDevelopment extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }

    SaasDevProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Gather Prerequisites",
                "img" : "process-1.svg",
                "description" : "To begin with, we get the requirements from our client. We understand our clients’ goals and determine how to achieve them through the SaaS product. At this stage, we will define the functionality of your SaaS product and research the best means to achieve them."
            },
            {
                "id" : 2,
                "title" : "Design",
                "img" : "process-2.svg",
                "description" : "Here is where we decide your SaaS product’s flow. We create a workflow of your SaaS product that will give you an idea of what your SaaS product will look like and permute that into a wireframe. Upon that, we begin working on the prototype of your SaaS product. And, once the prototype is approved, the actual UI design will be commenced."
            },
            {
                "id" : 3,
                "title" : "Develop",
                "img" : "process-3.svg",
                "description" : "In this stage, we settle upon the right framework, platforms, and tools that will complement your needs. Multiple factors like the functionality of your SaaS product, your goals, etc. will be taken into consideration during this stage. This is when we start coding your SaaS product."
            },
            {
                "id" : 4,
                "title" : "QC",
                "img" : "process-4.svg",
                "description" : "Quality assurance is our prime concern. During our SaaS development process, we continuously test your product to make sure that they are free of any errors before we deliver them to you. We test your SaaS product for functionality, usability, and performance. We deliver you quality products and make sure your SaaS products are bug-free before we deliver them to you."
            },
            {
                "id" : 5,
                "title" : "Deliver",
                "img" : "process-5.svg",
                "description" : "Once we make sure that we have the code and the host, it is time to deploy and deliver! We make sure that our final product is in line with our client’s requirements and that the SaaS products work as planned. However, it does not end there."
            },
            {
                "id" : 6,
                "title" : "Maintenance",
                "img" : "process-6.svg",
                "description" : "On delivery, we pay attention to our client’s feedback and implement necessary changes if needed. We extend our technical support to our clients post-launch and work towards optimizing the SaaS products according to our client’s business progress."
            }
        ]

    toggle = (e, tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            setInterval(5000, this.intervalRef.current);
        }
    }


    componentDidMount(){
        // $('.nav-tabs-dropdown')
        // .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        // })
        // .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        // });

        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.saas-dev-process > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.saas-dev-process > li').length
        //     ? 
        //     active.parent().next('.saas-dev-process > li').find('a') 
        //     : 
        //     tabs.parent().filter('.saas-dev-process > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.saas-dev-process a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.6;
        var $bg_images = $(".brand-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.SaasDevProcessContent.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };
    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='saas-development-company-in-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="digi-mark-bg-img sass-dev-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-8">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">SaaS Development Company in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">In this digital era, SaaS has a central role in any business's digital transformation. Revolutionize your online presence with Alchem Digital, a leading SaaS development company.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main e-commerce-main-section">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/saas/saas-dev-img-1.jpg`} alt="e-commerce-img"/>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="branding-container-align">
                                    <h1 className="digi-market-zig-zag-title">What is SaaS?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>SaaS or Software-as-a-Service is a cloud-based application that can be licensed on a subscription model and can be accessed anywhere via the internet. Here, the servers, databases, and application codes are hosted and maintained by the software vendors. Due to its subscription model, SaaS can be an effective choice for several businesses as it eliminates the need to invest in extensive hardware.</p>
                                        <p>Who wouldn't want an option that can save them both cost and time? If you do, SaaS can be the ideal solution for you. SaaS carries out applications over the internet making it easy for you to customize, access, and upgrade your product efficiently. SaaS products can free you from the burden of maintaining your hardware and software.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Advantages of Building a SaaS product at Alchem Digital</h1>
                        <div className="digi-benefits-align e-commerce-benefits-list">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Securely Built</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">User Experience</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Dynamic <span>& Scalable</span></a>
                                <a className="nav-link" id="b-maintenance-tab" data-toggle="pill" href="#maintenance-tab" role="tab" aria-controls="maintenance-tab" aria-selected="false">Maintenance</a>
                                <a className="nav-link" id="b-tech-consult-tab" data-toggle="pill" href="#tech-consult-tab" role="tab" aria-controls="tech-consult-tab" aria-selected="false">Technology <span>consulting</span></a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-6.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Securely Built</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Security is a major concern for several businesses when it comes to adapting to SaaS. Given your data is stored off-site, a SaaS product is subject to multiple threats such as database manipulation and whatnot. At Alchem Digital, we consider security a part of the SaaS development process rather than a separate process. We make sure that your SaaS product is checked for any vulnerabilities and is safe from any external threats.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-1.svg`} alt="benefits-icon-1"/>
                                                <h2 className="digi-market-title-benefit">User Experience</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">User experience is an important aspect of a SaaS product if you want to retain customers. We utilize our time in designing an engaging SaaS product that will fulfil the user’s needs. A seamless UI/UX can ultimately help in creating a positive experience among your customers that will in turn help in retaining them.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-4.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Dynamic & Scalable</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Usually, a SaaS product is developed with regard to the client’s needs in mind. Given SaaS depends on a cloud environment, a SaaS product can be developed/customized as your business undergoes development or change. SaaS products developed at Alchem Digital will let you easily integrate additional modules that are in line with your requirements on the go. A SaaS product is usually built with the client’s needs in mind.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="maintenance-tab" role="tabpanel" aria-labelledby="b-maintenance-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/web-application/web-app-benefits/maintenance.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Maintenance</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">SaaS products are subjected to regular change and require maintenance. Our SaaS maintenance services furnish server monitoring, performance optimizations, updating content, and database management & security. Maintenance can play a major role when it comes to staying updated and we give as much attention to maintenance as we do to the development process.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tech-consult-tab" role="tabpanel" aria-labelledby="b-tech-consult-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/saas/saas-benefits/benefits-icon-5.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Technology consulting</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">We understand the individuality, requirements, and goals of each business and come up with solutions to increase your business’s efficiency. We provide you with a range of technology services that will help you implement the right software solutions and cloud services for your business, make the best use of technologies for your business, and amplify your IT systems. With our bespoke technology consulting services, we help you develop, reduce expenses, and save resources effectively.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Our Process</h1>
                        <div className="wordpress-content-cont">
                            {/* <ul className="nav nav-pills mb-3 saas-dev-process" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-1-tab" data-toggle="pill" href="#pills-seo-content-1" role="tab" aria-controls="pills-seo-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Gather Prerequisites</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Gather Prerequisites</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-2-tab" data-toggle="pill" href="#pills-seo-content-2" role="tab" aria-controls="pills-seo-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Design</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Design</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-3-tab" data-toggle="pill" href="#pills-seo-content-3" role="tab" aria-controls="pills-seo-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Develop</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Develop</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-4-tab" data-toggle="pill" href="#pills-seo-content-4" role="tab" aria-controls="pills-seo-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">QC</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">QC</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-5-tab" data-toggle="pill" href="#pills-seo-content-5" role="tab" aria-controls="pills-seo-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Deliver</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Deliver</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-6-tab" data-toggle="pill" href="#pills-seo-content-6" role="tab" aria-controls="pills-seo-content-6" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Maintenance</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-6"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Maintenance</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active-anim active" id="pills-seo-content-1" role="tabpanel" aria-labelledby="pills-seo-content-1-tab">
                                    <div className="wordpress-process-content saas-dev-content-align">
                                        <h3>Gather Prerequisites</h3>
                                        <p>To begin with, we get the requirements from our client. We understand our clients’ goals and determine how to achieve them through the SaaS product. At this stage, we will define the functionality of your SaaS product and research the best means to achieve them.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-2" role="tabpanel" aria-labelledby="pills-seo-content-2-tab">
                                    <div className="wordpress-process-content saas-dev-content-align">
                                        <h3>Design</h3>
                                        <p>Here is where we decide your SaaS product’s flow. We create a workflow of your SaaS product that will give you an idea of what your SaaS product will look like and permute that into a wireframe. Upon that, we begin working on the prototype of your SaaS product. And, once the prototype is approved, the actual UI design will be commenced.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-3" role="tabpanel" aria-labelledby="pills-seo-content-3-tab">
                                    <div className="wordpress-process-content saas-dev-content-align">
                                        <h3>Develop</h3>
                                        <p>In this stage, we settle upon the right framework, platforms, and tools that will complement your needs. Multiple factors like the functionality of your SaaS product, your goals, etc. will be taken into consideration during this stage. This is when we start coding your SaaS product.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-4" role="tabpanel" aria-labelledby="pills-seo-content-4-tab">
                                    <div className="wordpress-process-content saas-dev-content-align">
                                        <h3>QC</h3>
                                        <p>Quality assurance is our prime concern. During our SaaS development process, we continuously test your product to make sure that they are free of any errors before we deliver them to you. We test your SaaS product for functionality, usability, and performance. We deliver you quality products and make sure your SaaS products are bug-free before we deliver them to you.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-5" role="tabpanel" aria-labelledby="pills-seo-content-5-tab">
                                    <div className="wordpress-process-content saas-dev-content-align">
                                        <h3>Deliver</h3>
                                        <p>Once we make sure that we have the code and the host, it is time to deploy and deliver! We make sure that our final product is in line with our client’s requirements and that the SaaS products work as planned. However, it does not end there.</p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-6" role="tabpanel" aria-labelledby="pills-seo-content-6-tab">
                                    <div className="wordpress-process-content saas-dev-content-align">
                                        <h3>Maintenance</h3>
                                        <p>On delivery, we pay attention to our client’s feedback and implement necessary changes if needed. We extend our technical support to our clients post-launch and work towards optimizing the SaaS products according to our client’s business progress.</p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Local SEO</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '1' ? `${Config.HOST}/assets/img/seo/seo-process-1.svg` : `${Config.HOST}/assets/img/seo/seo-process-1-black.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Local SEO</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <h4 className="padd-bottom-12">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '2' ? `${Config.HOST}/assets/img/seo/seo-process-2-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-2.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Keyword Research</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">On-page Optimization</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '3' ? `${Config.HOST}/assets/img/seo/seo-process-3-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-3.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">On-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <h4 className="padd-bottom-12">Off-page Optimization</h4> 
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '4' ? `${Config.HOST}/assets/img/seo/seo-process-4-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-4.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Off-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Continuous Optimization</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '5' ? `${Config.HOST}/assets/img/seo/seo-process-5-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-5.svg`}  alt="seo-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12">Continuous Optimization</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="seo-process-content">
                                        <h3>Local SEO</h3>
                                        <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="seo-process-content">
                                        <h3>On-page Optimization</h3>
                                        <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="seo-process-content">
                                        <h3>Off-page Optimization</h3>
                                        <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="seo-process-content">
                                        <h3>Continuous Optimization</h3>
                                        <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}
                            <Nav tabs>
                                {
                                    this.SaasDevProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/`+item.img}  alt="e-com-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.SaasDevProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process mob-brand-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-1.svg`} alt="seo-process"/>
                                    <h4>Gather Prerequisites</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>To begin with, we get the requirements from our client. We understand our clients’ goals and determine how to achieve them through the SaaS product. At this stage, we will define the functionality of your SaaS product and research the best means to achieve them. </p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-2.svg`} alt="seo-process"/>
                                    <h4>Design</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Here is where we decide your SaaS product’s flow. We create a workflow of your SaaS product that will give you an idea of what your SaaS product will look like and permute that into a wireframe. Upon that, we begin working on the prototype of your SaaS product. And, once the prototype is approved, the actual UI design will be commenced.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-3.svg`} alt="seo-process"/>
                                    <h4>Develop</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>In this stage, we settle upon the right framework, platforms, and tools that will complement your needs. Multiple factors like the functionality of your SaaS product, your goals, etc. will be taken into consideration during this stage. This is when we start coding your SaaS product.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-4.svg`} alt="seo-process"/>
                                    <h4>QC</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Quality assurance is our prime concern. During our SaaS development process, we continuously test your product to make sure that they are free of any errors before we deliver them to you. We test your SaaS product for functionality, usability, and performance. We deliver you quality products and make sure your SaaS products are bug-free before we deliver them to you.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-5.svg`} alt="seo-process"/>
                                    <h4>Deliver</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we make sure that we have the code and the host, it is time to deploy and deliver! We make sure that our final product is in line with our client’s requirements and that the SaaS products work as planned. However, it does not end there.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-6.svg`} alt="seo-process"/>
                                    <h4>Maintenance</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>On delivery, we pay attention to our client’s feedback and implement necessary changes if needed. We extend our technical support to our clients post-launch and work towards optimizing the SaaS products according to our client’s business progress.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="brand-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Looking for a SaaS development service?</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <Link to="/contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Contact us</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-services digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">How can Alchem Digital help you?</h1>
                        <div className="saas-services-grid web-app-services-grid">
                            <div className="ad-services-grid-align">
                                <div>
                                    <div className="ad-home-mob-remove-right-border saas-grid-width">
                                            <div className="ad-services-sections saas-grid-height">
                                                <img src={Config.HOST+'/assets/img/saas/saas-service-icons/saas-serv-icons-1.svg'} alt="service-1"/>
                                                <h2 className="ad-services-secction-txt">Kick-Off</h2>
                                                <p className="ad-services-secction-excerpt">We help you enhance your avant-garde idea and establish your business in the market swiftly.</p>
                                            </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-remove-right-border saas-grid-width">
                                        <div className="ad-services-sections saas-grid-height">
                                            <img src={Config.HOST+'/assets/img/saas/saas-service-icons/saas-serv-icons-2.svg'} alt="service-2"/>
                                            <h2 className="ad-services-secction-txt">Expand your Market</h2>
                                            <p className="ad-services-secction-excerpt">We help your business reach across the world by boosting your online presence.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-add-right-border ad-home-mob-remove-right-border saas-grid-width">
                                        <div className="ad-services-sections saas-grid-height">
                                            <img src={Config.HOST+'/assets/img/saas/saas-service-icons/saas-serv-icons-3.svg'} alt="service-3"/>
                                            <h2 className="ad-services-secction-txt">Simplified</h2>
                                            <p className="ad-services-secction-excerpt">We increase the efficiency of your company and enhance your business’s development with effective working methods.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-remove-right-border ad-add-right-border saas-grid-width">
                                        <div className="ad-services-sections saas-grid-height">
                                            <img src={Config.HOST+'/assets/img/saas/saas-service-icons/saas-serv-icons-4.svg'} alt="service-4"/>
                                            <h2 className="ad-services-secction-txt">Customizable</h2>
                                            <p className="ad-services-secction-excerpt">We build you products that are customizable according to your business requirements making it easy to integrate new features and modules smoothly as your business develops.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-home-service-box-bottom-border saas-grid-width">
                                        <div className="ad-services-sections saas-grid-height">
                                            <div className="res-dev-key-feature-sections">
                                                <h2 className="digi-market-services-last-box-content">Have an avant-garde idea?</h2>
                                                <Link to='/contact' className="digi-market-content-button button-gradient">Let’s talk now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-home-service-box-bottom-border saas-grid-width">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-technologies saas-dev-tech-logos">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Laravel</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Core PHP</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Python (Django)</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">MySQL</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">React Js</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="saas-dev-laptop-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-serve-you-container ecommerce-content-container">
                                    <h1 className="digi-market-serve-you-title">Why Alchem Digital is your Best SaaS Development Company</h1>
                                    <p className="digi-market-serve-you-content">At Alchem Digital, understanding our client's requirements and producing end products that are in line with our client's goals is our primary concern. Our agile methodology gives us the flexibility to make changes according to our client's needs. Our team utilizes their time to design and develop the best SaaS products for our clients while understanding their goals and requirements in mind.  We have experience in building robust global SaaS products with impeccable UI/UX backed with great database architecture and multiple functionalities.</p>
                                    <p className="digi-market-serve-you-content">Our team of skilled designers and developers thrive on challenges and work consistently to deliver your product on time. Being a leading SaaS development company in Chennai, we provide our clients with SaaS products that are unique and efficient. We build secure and safe SaaS products with our expertise in various platforms like Laravel, Core PHP, Python (Django), MySQL, and React Js.</p>
                                    <Link to="/contact" className="digi-benefits-content-button button-gradient transition-issue">Contact Us</Link>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-mob">
                                <img src={`${Config.HOST}/assets/img/saas/saas-dev-img-2.png`} alt="wp-img-2" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-clients-section">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <h1 className="res-dev-client-content-subtitle mob-text-center">THE HONOUR TO OUR JOURNEY</h1>
                                    <h2 className="res-dev-client-content-title mob-text-center">Our valued <br/> clients</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 new-padd-left-add">
                                {typeof window !== 'undefined' && 
                                    <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                {typeof window !== 'undefined' &&
                                    <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-mega-container-1">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                </OwlCarousel>
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="wordpress-dev-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top SaaS Development Company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>Can you customize SaaS software according to my business?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>Absolutely. One of the most efficient features of SaaS products is that they are customizable according to your business needs. At Alchem Digital, we understand your business requirements and build products that are in line with your business goals.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>How much does it cost to develop a SaaS platform?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>The answer to that question depends on a lot of factors ranging from the nature of your product to what you want a SaaS platform for. Feel free to <span><Link to="/contact">contact us</Link></span> or simply drop us a message through the contact us form. Our team will get back to you regarding your requirements on which you can discuss a ballpark figure of the cost.  </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingThree">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseThree" aria-expanded="false" aria-controls="newcollapseThree">
                                                <span>How long does it take to build a SaaS platform?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseThree" className="collapse" aria-labelledby="newheadingThree" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>The timeframe to build a SaaS platform depends on the scope of your product. It could approximately take anywhere between two months to eight months to develop a SaaS product depending on your requirements.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>Can you develop a SaaS product that is extremely safe and secure?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>At Alchem Digital, we consider the safety and security of our clients' data as one of our primary concerns, and our developers work constantly to make sure that your product is free of any vulnerabilities and is safe from external threats.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>How can a SaaS product help my business?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>SaaS products are easily scalable according to your business requirements. With frequent updates and changes, you increase security and keep your platform bug-free. SaaS can have a major impact on your business's digital presence.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default SaasDevelopment