import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import $ from "jquery"
import React, {Component} from 'react'
import FloatingIcons from "../FloatingIcons"
import AllPageContactForm from '../layout/AllPageContactForm'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import Config from "./Config"
import Testimonial from "../layout/AllpageTestimonial"


class Services extends React.Component{

    
    render(){
        return(
            <React.Fragment>
                    <HeadTags path='services' />
                    <Header/>
                    <section className="ad-services-bg">
                        <div className="services-bg-img">
                            <div className="container">
                                <div className="ad-pages-hero-section">
                                    <div className="ad-bread-crumbs">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Services</li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <div>
                                        <p id="saas-service" className="ad-about-main-title">The buck stops here!</p>
                                    </div>
                                </div>
                            </div>
                            <div id="saas-service"></div>
                        </div>
                        <section className="ad-services-section">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <img className="ad-services-dots-img" src={'assets/img/about-bg-dots.png'} alt="about-bg-dots"/>
                                    <div className="zoom-effect-1">
                                        <img className="ad-services-alchemists-img" src={`${Config.HOST}/assets/img/saas-we.jpg`} alt="saas-services"/>
                                        <div className="ad-banner-content-inner"></div>
                                    </div>
                                </div>
                                <div  className="col-xs-12 col-sm-6">
                                    <div className="ad-services-page-section">
                                        <div className="ad-services-left-align pt-4 pb-3"> 
                                            {/* <p className="ad-about-us-top-title">We are</p> */}
                                            <p className="ad-services-us-title">SaaS & Web Application Development</p>
                                            <div className="ad-services-content">
                                                <p className="ad-services-us-txt">With strong expertise in building efficient and powerful products, we provide unparalleled SaaS products, tailormade for your needs. Our primary goal is to deliver perfectly engineered SaaS products with powerful features and fluid user experience to our clients, be it individuals, startups, or large companies.</p>
                                                <p className="ad-services-us-txt">The core principle of building an excellent SaaS product is to believe in your idea and vice versa.</p>
                                            </div>
                                            <div className="ad-services-quote-align">
                                                <p>
                                                    <i className="ad-services-quote">“Work with people who believe in your idea”</i><br/>
                                                    <strong className="ad-services-quote-author">– Sridhar Vembu, Zoho corp</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="services-no-align">
                                            <p className="services-txt">01</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Web-dev"></div>
                            <div className="row ad-services-padd-top new-mob-row">
                                <div  className="col-xs-12 col-sm-6">
                                    <div className="ad-services-page-section">
                                        <div className="services-no-align-2">
                                            <p className="services-txt">02</p>
                                        </div>
                                        <div className="pt-4 pb-3 ad-services-padd-right"> 
                                            {/* <p className="ad-about-us-top-title">We are</p> */}
                                            <p className="ad-services-us-title">Website Design & Development</p>
                                            <div className="ad-services-content">
                                                <p className="ad-services-us-txt">Website being an essential part of any business, we consider it crucial to bring the best of our clients’ on it. We put immense effort into designing and developing responsive websites for our clients to help them exhaust the digital space. Our technically skilled developers implement the best and the latest technologies for our clients, be it a single-page website, e-commerce website, CMS website, bespoke website, CRM website, or blog sites. We have expertise in building and maintaining top quality, aesthetic websites under various platforms like Laravel, WordPress, Woo commerce, Magento, React JS, and so forth.</p>
                                            </div>
                                            <div className="ad-services-quote-align">
                                                <p>
                                                    <i className="ad-services-quote">“A website is the main business card for any Brand”</i><br/>
                                                    <strong className="ad-services-quote-author ad-services-quote-author-align">-Someone at Alchem Digital <span className="ad-smiley-img-2"><img src="assets/img/smiley-2.svg" alt="smiley-2"/></span> </strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                <img className="ad-services-dots-img-2" src={`${Config.HOST}/assets/img/about-bg-dots.png`} alt="about-bg-dots"/>
                                    <div className="zoom-effect-1">
                                    <img className="ad-services-alchemists-img" src={`${Config.HOST}/assets/img/website-design-dev.jpg`} alt="web-des-dev-services"/>
                                        <div className="ad-banner-content-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div id="brand-ident"></div>
                            <div className="row ad-services-padd-top">
                                <div className="col-xs-12 col-sm-6">
                                <img className="ad-services-dots-img" src={`${Config.HOST}/assets/img/about-bg-dots.png`} alt="about-bg-dots"/>
                                    <div className="zoom-effect-1">
                                    <img className="ad-services-alchemists-img" src={`${Config.HOST}/assets/img/brand-identity.jpg`} alt="brand-identity-services"/>
                                        <div className="ad-banner-content-inner"></div>
                                    </div>
                                </div>
                                <div  className="col-xs-12 col-sm-6">
                                    <div className="ad-services-page-section">
                                        <div className="ad-services-left-align pt-4 pb-3"> 
                                            {/* <p className="ad-about-us-top-title">We are</p> */}
                                            <p className="ad-services-us-title">Brand Identity & Branding</p>
                                            <div className="ad-services-content">
                                                <p className="ad-services-us-txt">A Brand should symbolize, communicate and make itself distinct from it’s competitors in the market. We help in creating such identity by fore laying the fundamental credentials, logo, color scheme and design base & architecture for a brand, that creates an impact and a great recall value.</p>
                                            </div>
                                            <div className="ad-services-quote-align-service-3">
                                                <p>
                                                    <i className="ad-services-quote">“Your brand is what other people say about you when you’re not in the room.”</i><br/>
                                                    <strong className="ad-services-quote-author ad-services-quote-author-align-2">–Jeff Bezos, Amazon</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="services-no-align">
                                            <p className="services-txt">03</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="graphic-desg"></div>
                            <div className="row ad-services-padd-top new-mob-row">
                                <div  className="col-xs-12 col-sm-6">
                                    <div className="ad-services-page-section">
                                        <div className="services-no-align-2">
                                            <p className="services-txt">04</p>
                                        </div>
                                        <div className="pt-4 pb-3 ad-services-padd-right-1"> 
                                            {/* <p className="ad-about-us-top-title">We are</p> */}
                                            <p className="ad-services-us-title">Graphic design & Creative visualization</p>
                                            <div className="ad-services-content">
                                                <p className="ad-services-us-txt">While appearances can be deceptive, they can also be conspicuous. Graphic designing is more than creating banners and posters. Conveying the emotions and character of a brand is an intricate task and our designers are nothing short of experts in it. Our designers adhere to the latest trends and are equipped with a strong sense of branding, communication, and core designing skills that help your brand convey the message it wants in a more compelling way! Being a leading designing company, we have solid experience in multiple design verticals like e-commerce websites, landing pages, website banners, creative posters. brochures, social media ads, and whatnot! Content makes your brand be heard and design makes your brand be seen and we are unequalled in both.</p>
                                            </div>
                                            <div className="ad-services-quote-align-service-4">
                                                <p>
                                                    <i className="ad-services-quote">“Design is not just what it looks like and feels like. Design is how it works”</i><br/>
                                                    <strong className="ad-services-quote-author ad-services-quote-author-align-3">-Steve Jobs, Apple</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                <img className="ad-services-dots-img-2" src={`${Config.HOST}/assets/img/about-bg-dots.png`} alt="about-bg-dots"/>
                                    <div className="zoom-effect-1">
                                    <img className="ad-sevicers-alchemists-img" src={`${Config.HOST}/assets/img/graphic-design.jpg`} alt="web-des-dev-services"/>
                                        <div className="ad-banner-content-inner"></div>
                                    </div>
                                </div>
                            </div>
                            <div id="seo"></div>
                            <div className="row ad-services-padd-top">
                                <div className="col-xs-12 col-sm-6">
                                <img className="ad-services-dots-img" src={`${Config.HOST}/assets/img/about-bg-dots.png`} alt="about-bg-dots"/>
                                    <div className="zoom-effect-1">
                                    <img className="ad-services-alchemists-img" src={`${Config.HOST}/assets/img/digital-marketing-seo.jpg`} alt="brand-identity-services"/>
                                        <div className="ad-banner-content-inner"></div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="ad-services-page-section">
                                        <div className="ad-services-left-align pt-4 pb-3"> 
                                            {/* <p className="ad-about-us-top-title">We are</p> */}
                                            <p className="ad-services-us-title">Digital Marketing & SEO</p>
                                            <div className="ad-services-content-4">
                                                <p className="ad-services-us-txt">We help deliver the right social media strategy and execution that is highly customized for your brand, product/service, audience, and the expected results with a unique ROI and analytics-driven approach.</p>
                                                <p className="ad-services-us-txt">With expertise in offering 360 (Holistic) marketing services for multiple clients, we help you leverage the possibilities from each platform that best suits your business.</p>
                                                <p className="ad-services-us-txt">Our digital marketing services comprise social media marketing, pay-per-click, web analytics, content marketing, and more. With our thorough research and competitor analysis, we find the right approach for your business to flourish in the digital world.</p>
                                                <p className="ad-services-us-txt">Being a leading digital marketing company, we as a team will understand the essence of your business and suggest social media marketing strategies that we know will work for your business.</p>
                                                <p className="ad-services-us-txt">We also help build a sustainable brand position in the search results by implementing continuous On-page and Off-page optimizations over a span of a couple of months, along with the rest of the core marketing strategies.</p>
                                                <p className="ad-services-us-txt">As a comprehensive SaaS development and Design company, we successfully convert ideas into impeccable products, designs, and websites for our clients by adhering to the right and rigorous processes with an excellent feedback mechanism.</p>
                                                <p className="ad-services-us-txt">EOD, the right process is what that’ll lead to the right result!</p>
                                            </div>
                                            <div className="ad-services-quote-align-service-3">
                                                <p>
                                                    <i className="ad-services-quote">“The Internet is becoming the town square for the global village of tomorrow”</i><br/>
                                                    <strong className="ad-services-quote-author ad-services-quote-author-align-4">–Bill Gates</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="services-no-align">
                                            <p className="services-txt">05</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="ad-process-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="ad-process-header text-left">
                                            <p className="ad-about-us-top-title">Our Process</p>
                                            <p className="ad-about-us-title">Our steps to<br/> STEP UP!</p>
                                            <p className="ad-approach-txts">As a comprehensive SaaS development and Design company, we successfully convert ideas into impeccable products, designs and websites for our clients by adhering right and rigorous processes with an excellent feedback mechanism.</p>
                                            <p className="ad-approach-txts">EOD, right process is what that’ll lead to the right result!</p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <div className="row ad-process-container">
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="ad-approach-align">
                                                    <div className="ad-approach-subtile-align">
                                                    <img src={`${Config.HOST}/assets/img/approach/discover-and-understand.svg`} alt="discover-and-understand"/>
                                                    </div>
                                                    <div className="ad-approach-subtile-align">
                                                        <p className="ad-approach-sub-title">Discover & Understand</p>
                                                    </div>
                                                </div>
                                                <p className="ad-approach-txts">We primarily conduct an extensive research and try to understand the idea that has been pitched.</p>
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="ad-approach-align">
                                                    <div className="ad-approach-subtile-align">
                                                    <img src={`${Config.HOST}/assets/img/approach/scale-2.svg`} alt="Scale"/>
                                                    </div>
                                                    <div className="ad-approach-subtile-align">
                                                        <p className="ad-approach-sub-title">Design & Prototyping</p>
                                                    </div>
                                                </div>
                                                <p className="ad-approach-txts">
                                                    We then encapsulate the consumed idea into a design framework and a prototype to build on it furthermore.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row ad-services-padd-top-2 ad-process-container">
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="ad-approach-align">
                                                    <div className="ad-approach-subtile-align">
                                                    <img src={`${Config.HOST}/assets/img/approach/bulb.svg`} alt="coding-dev"/>
                                                    </div>
                                                    <div className="ad-approach-subtile-align">
                                                        <p className="ad-approach-sub-title">Coding & Developement</p>
                                                    </div>
                                                </div>
                                                <p className="ad-approach-txts">The core part of building the product commences by employing strong back-end tech with fluid UX on the product.</p>
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div className="ad-approach-align">
                                                    <div className="ad-approach-subtile-align">
                                                    <img src={`${Config.HOST}/assets/img/approach/testing-delivery.svg`} alt="testing-delivery"/>
                                                    </div>
                                                    <div className="ad-approach-subtile-align">
                                                        <p className="ad-approach-sub-title">Testing & Delivery</p>
                                                    </div>
                                                </div>
                                                <p className="ad-approach-txts">We hawk-eye the product on all ends and make sure it passes our strict testing protocols to deliver you the best SaaS product and service.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="core-service-section" className="ad-core-service-section">
                            <div className="ad-core-container">
                                <div className="text-center">
                                    <p className="ad-portfolio-top-title">Our Core Services</p>
                                    <p className="ad-portfolio-main-title">The tech platter of services</p>
                                    <div className="ad-core-service-container">
                                        <p className="ad-services-sub-title">Alchem Digital offers the best creative visualization, Digital float, branding, revamp and web development services in Chennai and all over India, at an economical and aggressive price. We follow agile methodology to give the best output in time.</p>
                                    </div>
                                </div>
                                <div className="ad-services-grid">
                                    <div className="ad-services-grid-align">
                                        <div>
                                            <div className="ad-core-services-box-align ad-mob-remove-right-border">
                                                <div className="ad-core-services-sections">
                                                <img className="ad-web-dev-img" src={`${Config.HOST}/assets/img/services/web-application-development.svg`} alt="web-app-dev"/>
                                                    <p className="ad-services-secction-txt">Web Application Development</p>
                                                    <p className="ad-services-secction-excerpt">Building creative and sustainable enterprise web application with impeccable UX is our main forte.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ad-core-services-box-align ad-remove-right-border">
                                                <div className="ad-core-services-sections">
                                                <img src={`${Config.HOST}/assets/img/services/CMS-website.svg`} alt="cms-web"/>
                                                    <p className="ad-services-secction-txt">CMS Website<br/> Development</p>
                                                    <p className="ad-services-secction-excerpt">We use Wordpress and Joomla to erect excellent CMS websites for not just the usability but also for the dynamism & aesthetics.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ad-core-services-box-align ad-home-service-box-right-border ad-add-right-border ad-mob-remove-right-border">
                                                <div className="ad-core-services-sections">
                                                <img src={`${Config.HOST}/assets/img/services/E-commerce.svg`} alt="E-commerce"/>
                                                    <p className="ad-services-secction-txt">E-commerce Web<br/> Development</p>
                                                    <p className="ad-services-secction-excerpt">Add to cart – The best E-commerce web application with the latest technology backed up with a fluent front-end and a strong database.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ad-core-services-box-align ad-home-service-box-bottom-border ad-remove-right-border ad-add-right-border">
                                                <div className="ad-core-services-sections">
                                                <img src={`${Config.HOST}/assets/img/services/magento.svg`} alt="Magento"/>
                                                    <p className="ad-services-secction-txt">Magento<br/>  Development</p>
                                                    <p className="ad-services-secction-excerpt">Magento is one of the best ecommerce website development solution, and we are experts at it.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ad-core-services-box-align ad-home-service-box-bottom-border ad-mob-remove-right-border ad-mob-add-bottom-border">
                                                <div className="ad-core-services-sections">
                                                <img src={`${Config.HOST}/assets/img/services/php.svg`} alt="php"/>
                                                    <p className="ad-services-secction-txt">PHP Web<br/>  Development</p>
                                                    <p className="ad-services-secction-excerpt">We’re experts in developing web applications in this widely used scripting language, and we can never go wrong with it!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ad-core-services-box-align ad-home-service-box-right-border ad-home-service-box-bottom-border">
                                                <div className="ad-core-services-sections">
                                                <img src={`${Config.HOST}/assets/img/services/laravel.svg`} alt="laravel"/>
                                                    <p className="ad-services-secction-txt">Laravel<br/>  Development</p>
                                                    <p className="ad-services-secction-excerpt">We build top class websites with our Laravel techies here at Alchem Digital and we have a great muscle in doing it.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Testimonial/>
                        <section className="ad-form-section">
                            <AllPageContactForm/>
                        </section>
                    </section>
                    {/* <FloatingIcons/> */}
            </React.Fragment>
        );
    }
}

export default Services