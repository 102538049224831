import React, {Component} from 'react'


class FloatingIcons extends React.Component{
    render(){
        return(
            <React.Fragment>
                <a data-aos="fade-up" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" href="https://api.whatsapp.com/send?phone=+917305639892" className="ad-float-icons" target="_blank">
                    <i class="fab fa-whatsapp ad-whatsapp-float-icon"></i>
                </a>
            </React.Fragment>
        );
    }
}

export default FloatingIcons