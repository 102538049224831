import React, { Component } from "react"
import Config from './Config'
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from "sweetalert2"
const ReactQuill = (typeof window != 'undefined') && require('react-quill')

export default class Addblog extends Component {
	constructor(props) {
		super(props)
		Config.adminRedirectIfNotLoggedIn(this.props)
		this.state = {
			blogTitle: "",
			blogSlugTitle: "",
			blogCategory: "",
			blogContent: "",
			blogHeader: "",
			banner: "",
			author: "",
			category: [],
			authors: [],
		};
	}

	componentDidMount = () => {
		Config.axios({
			auth: true,
			url: `${Config.API_URL}/api/user/category`,
			success: (res) => {
				this.setState({
					category: res.data,
				})
			},
		})

		Config.axios({
			auth: true,
			url: `${Config.API_URL}/api/user/author`,
			success: (res) => {
				this.setState({
					authors: res.data,
				})
			},
		})
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		})

		if (e.target.name == 'blogTitle') {
			this.setState({
				blogSlugTitle: e.target.value.replace(/\s+/g, '-').toLowerCase()
			})
		}
	}

	handleBannerChange = (e) => {
		this.setState({
			banner: e.target.files[0]
		})
	}

	onDescriptionChange = (content) => {
		this.setState({blogContent: content})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.set('title', this.state.blogTitle)
		formData.set('slug', this.state.blogSlugTitle)
		formData.set('category_id', parseInt(this.state.blogCategory))
		formData.set('content', this.state.blogContent)
		formData.set('banner_image', this.state.banner)
		formData.set('head_tag', this.state.blogHeader)
		formData.set('author_id', parseInt(this.state.author))

		Config.axios({
			// headers: { 'Content-Type': 'multipart/form-data'},
			method: 'post',
			url: `${Config.API_URL}/api/user/post/create`,
			auth: true,
			data: formData,
			success: (response) => {
				let timerInterval
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					html: '<p>New blog added.</p>',
					timer: 4000,
					timerProgressBar: true,
					onBeforeOpen: () => {
						Swal.showLoading()
						timerInterval = setInterval(() => {
							const content = Swal.getContent()
							if (content) {
								const b = content.querySelector('b')
								if (b) {
									b.textContent = Swal.getTimerLeft()
								}
							}
						}, 500)
						window.location.href = "/admin"
					},
					onClose: () => {
						clearInterval(timerInterval)
					}
				});
			},
			error: (error) => {
				console.log(error)
			}
		})
		// return axios({
		// 	method: 'post',
		// 	url: `${Config.API_URL}/api/user/post/create`,
		// 	data: formData,
		// 	headers: { "Content-Type": "multipart/form-data" }
		// }).then((response) => {
		// 		if (response.data.message == "success") {
		// 			alert("success");
		// 		}
		// 	}
		// )
	}

	render() {
		return (
			<>
				<div className="container">
					<div className="card my-3">
						<form action="" onSubmit={this.handleSubmit}>
							<div className="card-header">
								<h4>Add blog</h4>
							</div>
							<div className="card-body">
								<div className="form-group">
									<label htmlFor="">Title</label>
									<input
										type="text"
										name="blogTitle"
										className="form-control"
										value={this.state.blogTitle}
										onChange={this.handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="">Slug Title</label>
									<input
										type="text"
										name="blogSlugTitle"
										className="form-control"
										value={this.state.blogSlugTitle}
										onChange={this.handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="">Category</label>
									<select
										name="blogCategory"
										className="form-control"
										// value={this.state.blogCategory}
										onChange={this.handleChange}
									>
										<option disabled selected>Select</option>
										{
											this.state.category.map((category) =>(
												<option value={category.id}>{category.category}</option>
											))
										}
									</select>
								</div>
								<div className="form-group">
									<label htmlFor="">Content</label>
									{typeof window != 'undefined' && 
										<ReactQuill  value={this.state.blogContent}
											modules={ {
												toolbar: [
													[{ header: [1, 2, 3, 4, 5, 6, false] }],
													['bold', 'italic', 'underline', 'blockquote'],
													[{ 'list': 'ordered' }, { 'list': 'bullet' }],
													[{ 'align': [] }],
													['image', 'code', 'link'],
													['clean']
												]
											}}
											onChange={(content, delta, source, editor) => this.onDescriptionChange(content, editor)}
										
										/>
									}
								</div>
								<div className="form-group">
									<label htmlFor="">Header Tags</label>
									<textarea name="blogHeader" className="form-control" rows="10" onChange={this.handleChange}>{this.state.blogHeader}</textarea>
								</div>
								<div className="form-group">
									<label htmlFor="">Banner Image</label>
									<input type="file" className="form-control" name="banner" onChange={this.handleBannerChange} />
								</div>
								<div className="form-group">
									<label htmlFor="">Author</label>
									<select name="author" className="form-control" onChange={this.handleChange}>
										<option disabled selected>Select</option>
										{
											this.state.authors.map((author)=>(
												<option value={author.id}>{author.name}</option>
											))
										}
										
									</select>
								</div>
							</div>
							<div className="card-footer">
								<div className="d-flex justify-content-end">
									<button className="btn btn-primary">add blog</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		)
	}
}
