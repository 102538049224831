import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import Error from '../Animations/NotFound'
import Header from '../layout/Header'


class NotFound extends React.Component{
    
    render(){
        return(
            <React.Fragment>
                <Header/>
                    <div className="container ad-about-bg not-found-bg">
                        <div className="not-found-mega-container">
                            <Error/>
                            <div className="not-found-content-part">
                                <h1 className="not-found-text">uh-oh! something's missing</h1>
                                <p className="not-found-text-1">No sweat! we can navigate you to the Home page</p>
                                <Link className="alchem-digi-button-new button-gradient not-found" to="/"><span>Back to Home</span></Link>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        );
    }
}

export default NotFound