import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'


class Footer extends React.Component{

    render(){
        return (
            <React.Fragment>
                <section className="ad-footer-bg">
                    <div className="container">
                        <div className="footer-container">
                            <div className="row">
                                <div className="col-xs-12 col-md-3">
                                    <ul className="footer-logo-container">
                                        <li><Link to="/"><img src={'/assets/img/alchem-footer-logo.png'} alt="ad-footer-logo"/></Link></li>
                                        <li><p className="ad-footer-txt">Alchem Digital is a 360° full service digital marketing and website design & development agency that helps you add alchemy to your digital presence through our specialized SEO, SEM, PPC, SMM, content marketing, graphic design and web design and development services.</p></li>
                                        <li><p className="ad-footer-txt mt-0">We are based in Chennai, India, and Chicago, USA.</p></li>
                                        <li className="ad-footer-links-social-icon">
                                            <span>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://www.facebook.com/alchemdigital"><i className="fab fa-facebook-f"></i></a>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://twitter.com/alchemdigital"><i className="fab fa-twitter"></i></a>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://www.instagram.com/alchemdigital/"><i className="fab fa-instagram"></i></a>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://www.linkedin.com/company/alchemdigital/"><i className="fab fa-linkedin-in"></i></a>
                                            </span>
                                        </li>
                                        <li className="ad-footer-links-social-icon">
                                            <span>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://www.quora.com/profile/Alchem-Digital-1"><i className="fab fa-quora"></i></a>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://www.youtube.com/channel/UCYY7UiLUeuQfsmLPfxAwGvw"><i className="fab fa-youtube"></i></a>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://medium.com/@alchemdigital2020"><i className="fab fa-medium-m"></i></a>
                                                <a target="_blank" className="ad-footer-links-social-icon" href="https://in.pinterest.com/AlchemDigitalLLP/"><i className="fab fa-pinterest-p"></i></a>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-3">
                                    <ul className="footer-logo-container-links-1">
                                        <li><h4 className="ad-footer-title">Services</h4></li>
                                        <li className="ad-footer-links-align">
                                            <ul className="ad-footer-links-txt">
                                                <li><Link to="/saas-development-company-in-chennai">SaaS Development</Link></li>
                                                <li><Link to="/responsive-website-design-development" >Responsive Website Development</Link></li>
                                                <li><Link to="/web-application-development-company-chennai" >Web Application Development</Link></li>
                                                <li><Link to="/branding-company-in-chennai" >Brand Identity</Link></li>
                                                <li><HashLink to="/services#graphic-desg" >Graphic Designing</HashLink></li>
                                                <li><Link to="/digital-marketing-agency-chennai" >Digital Marketing</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-2">
                                    <ul className="footer-logo-container-links">
                                        <li><h4 className="ad-footer-title">Quick Links</h4></li>
                                        <li className="ad-footer-links-align">
                                            <ul className="ad-footer-links-txt">
                                                <li><Link to="/about" >About Us</Link></li>
                                                <li><Link to="/services" >Our Services</Link></li>
                                                <li><Link to="/portfolio" >Our Portfolio</Link></li>
                                                <li><Link to="/contact" >Contact Us</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-4">
                                    <ul className="footer-logo-container-links-1">
                                        <li><h4 className="ad-footer-title">Connect</h4></li>
                                        <li className="ad-footer-links-align">
                                            <ul className="ad-footer-links-txt footer-contact-part">
                                                <li>
                                                    <div className="footer-contact-main-content ">
                                                        <ul className="footer-info-content">
                                                            <li><span>Chennai</span> 1st Floor, Flat No.A106/6 and 188/6, Rangarajapuram Main Road, Kodambakkam, Chennai 600 024</li>
                                                            <li><span>Chicago</span>  415 Howard St #1001, Evanston, IL 60202, United States.</li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="footer-contact-main-content marg-left-remove">
                                                        <ul className="footer-info-content">
                                                            <li><span>IN</span> <a href="tel:7305639892" >+91 7305639892</a></li>
                                                            <li><span>US</span>  <a href="tel:469929-1097" >+1 (469) 929-1097</a></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="footer-contact-main-content marg-left-remove">
                                                        <ul className="footer-info-content">
                                                            <li><a href="mailto:getintouch@alchemdigital.com">getintouch@alchemdigital.com</a></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="text-center">
                        <ul className="secondary-footer-container">
                            <li className="secondary-footer-align">&copy; {new Date().getFullYear()} All Rights Reserved by Alchem Digital.</li>
                            <li className="secondary-footer-align"><Link to="/digital-marketing-agency-chennai">Digital Marketing</Link></li>
                            <li className="secondary-footer-align"><Link to="/responsive-website-design-development">Website design & development</Link></li>
                            <li className="secondary-footer-align"><Link to="/seo-company-in-chennai">SEO agency</Link></li>
                        </ul>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Footer
