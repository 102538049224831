import $ from "jquery"
import React, {Component, useState} from 'react'
import {Animated} from "react-animated-css"
import { Link } from 'react-router-dom'
import Config from "./pages/Config";


class  SlickSlider extends React.Component{

    componentDidMount(){
        $('#carouselExampleControls').carousel({
            interval: 7000,
            cycle: true
        }); 
    }

    render() {  
        return(
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100 carousel-position" src={Config.HOST+"/assets/img/home-bg-1.jpg"} alt="First slide"/>
                        <div className="hero-banner-txt-container">
                            {/* <Animated animationIn="slideInRight" animationInDuration="1000" isVisible={true}><p className="hero-banner-text-1 new-slide-width">Your technology partner for</p></Animated>
                            <Animated animationIn="slideInLeft" animationInDuration="2000" isVisible={true}><p className="hero-banner-text-2 new-slide-width">Bespoke Branding, Wacky Websites<br/> and Eccentric Designs</p></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration="3000" isVisible={true}><p className="hero-banner-text-3 new-slide-width">A Web Development Company based in Chicago and Chennai</p></Animated>
                            <Animated animationIn="slideInUp" animationInDuration="3000" isVisible={true}><Link to="/services" className="ad-button">Learn More</Link></Animated> */}
                            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-1 new-slide-width">Your technology partner for</h1></Animated>
                            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-2 new-slide-width">Bespoke Branding, Wacky Websites<br/> and Eccentric Designs</h1></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><h2 className="hero-banner-text-3 new-slide-width">A Web Development Company based in Chicago and Chennai</h2></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><Link to="/services" className="alchem-digi-button button-gradient">Learn More</Link></Animated>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100 carousel-position" src={Config.HOST+"/assets/img/home-bg-4.jpg"} alt="Second slide"/>
                        <div className="hero-banner-txt-container">
                            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-1" >SOCIAL MEDIA MARKETING</h1></Animated>
                            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-2" >SEO | SMM | SEM | PPC</h1></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><h2 className="hero-banner-text-3">Best Digital Marketing Company in Chennai</h2></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><Link to="/services" className="alchem-digi-button button-gradient" data-animation-in="fadeInUp" data-delay-in="1">Learn More</Link></Animated>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100 carousel-position" src={Config.HOST+"/assets/img/home-bg-3.jpg"} alt="Fourth slide"/>
                        <div className="hero-banner-txt-container">
                            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-1">Digital Marketing Agency</h1></Animated>
                            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-2">Extensive digital marketing services</h1></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><h2 className="hero-banner-text-3">Best digital marketing agency in Chennai and Chicago</h2></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><Link to="/services" className="alchem-digi-button button-gradient">Learn More</Link></Animated>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100 carousel-position" src={Config.HOST+"/assets/img/home-bg-2.jpg"} alt="Third slide"/>
                        <div className="hero-banner-txt-container">
                            <Animated animationIn="fadeInRight" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-1">Digitally</h1></Animated>
                            <Animated animationIn="fadeInLeft" animationInDuration={2000} isVisible={true}><h1 className="hero-banner-text-2">Nomad Agency</h1></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><h2 className="hero-banner-text-3">We Create Effective Design for Global Brands</h2></Animated>
                            <Animated animationIn="fadeInUp" animationInDuration={1000} isVisible={true}><Link to="/services" className="alchem-digi-button button-gradient">Learn More</Link></Animated>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="custom-prev" aria-hidden="true"><img src={Config.HOST+'/assets/img/left-arrow.svg'}/></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="custom-next" aria-hidden="true"><img src={Config.HOST+'/assets/img/right-arrow.svg'}/></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        );

    }

}

export default SlickSlider  