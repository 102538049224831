import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import 'react-loading-skeleton/dist/skeleton.css'

import $, { type } from 'jquery';
import React, { Component } from 'react'
// import OwlCarousel from "react-owl-carousel"
import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import HeadTags from "../layout/HeadTags";
import { Helmet } from "react-helmet-async"
import BlogSidebar from './BlogSidebar'
import Config from './Config'
import parse from 'html-react-parser';
import RelatedBlogCarousel from './RelatedBlogCarousel';
import CookiePolicy from "./CookiePolicy"
import Progress from "./Progress";
import Skeleton from 'react-loading-skeleton'
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
const readingTime = require('reading-time');
const OwlCarousel = (typeof window != 'undefined') && require('react-owl-carousel')

var FormData = require('form-data');
// import PageTransition from '../PageTransition'
class BlogFive extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            blogId: "",
            blogTitle: "",
            blogSlugTitle: this.props.match.params.blogName,
            blogContent: "",
            blogHeader: "",
            banner: "",
            blogAuthor: "",
            blogCategory: "",
            relatedBlogs: [],
            previousBlogSlug: "",
            previousBlogTitle: "",
            nextBlogSlug: "",
            nextBlogTitle: "",
            blogViews: 0,
            readingTime: "",
            extension: "default",
            extensionBlog: this.props.match.params.blogName === "how-to-display-icons-as-per-file-type-you-upload-in-html" ? true : false,
            scrollPostion: 0,
            enablePause: false,
            speaking: false
        }
        this.timeoutResumeInfinity = React.createRef()
        this.newUtt = React.createRef()
    }

    componentWillMount = () => {
        this.getBlogData()
    }

    componentCleanup = () => {
        // this will hold the cleanup code
        // whatever you want to do when the component is unmounted or page refreshes
        speechSynthesis.cancel()
    }

    componentDidMount = () => {
        this.getAllBlog()
        this.listenToScrollEvent()
        if(typeof window !== 'undefined')
            window.addEventListener("beforeunload", this.componentCleanup)
    }

    componentWillUnmount = () => {
        document.removeEventListener("scroll", this.scrollEvent, false)
        // speechSynthesis.cancel()
        this.componentCleanup()
        if (typeof window !== 'undefined')
            window.addEventListener("beforeunload", this.componentCleanup)
        if(this.newUtt.current){
            this.newUtt.current.removeEventListener('end', this.handleEndEvent())
        }
    }

    scrollEvent = () => {
        requestAnimationFrame(() => {
            this.calculateScrollDistance();
        });
        // console.log("first")
    }

    getBlogData = () => {
        const formData = new FormData();
        if (typeof formData.set != 'undefined')
            formData.set('slug', this.state.blogSlugTitle)
        Config.axios({
            method: 'post',
            url: `${Config.API_URL}/api/user/post/get`,
            data: formData,
            success: (res) => {
                this.setState({
                    blogId: res.data.post.id,
                    blogTitle: res.data.post.title,
                    blogSlugTitle: res.data.post.slug,
                    blogContent: res.data.post.content,
                    blogHeader: res.data.post.head_tag,
                    banner: res.data.post.banner_image,
                    blogCategory: res.data.post.category.id,
                    blogAuthor: res.data.post.author.name,
                    previousBlogSlug: (res.data?.prev_post?.slug) != null ? res.data.prev_post.slug : '',
                    previousBlogTitle: (res.data?.prev_post?.title) != null ? res.data.prev_post.title : '',
                    nextBlogSlug: (res.data?.next_post?.slug) != null ? res.data.next_post.slug : '',
                    nextBlogTitle: (res.data?.next_post?.title) != null ? res.data.next_post.title : '',
                    blogViews: res.data.post.views,
                    readingTime: readingTime(res.data.post.content.replace(/<\/?[^>]+(>|$)/g, ""))
                }, () => {
                    let viewedPosts = JSON.parse(localStorage.getItem('viewed_posts'))
                    // if (viewedPosts != null || ) {
                    setTimeout(() => {
                        if (viewedPosts == null || viewedPosts?.indexOf(res.data.post.slug) == -1) {
                            viewedPosts = viewedPosts == null ? [] : viewedPosts
                            viewedPosts.push(res.data.post.slug)
                            localStorage.setItem('viewed_posts', JSON.stringify(viewedPosts))
                            formData.set('slug', res.data.post.slug)
                            Config.axios({
                                method: 'PUT',
                                url: `${Config.API_URL}/api/user/post/increase-view`,
                                data: formData,
                                success: (res) => {
                                    this.setState({ blogViews: res.data.views })
                                }
                            })
                        }
                    }, 200);
                    // }
                })
            },
            error: (res) => {
                // console.log(res)
            }
        })
    }

    getAllBlog = () => {
        Config.axios({
            url: `${Config.API_URL}/api/user/post/list`,
            success: (res) => {
                this.setState({
                    relatedBlogs: res.data.results,
                })
            },
            error: (res) => {
                console.log(res)
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            extension: e.target.value
        })
    }

    listenToScrollEvent = () => {
        if (typeof window !== 'undefined') {
            document.addEventListener("scroll", this.scrollEvent);
        }
    }

    calculateScrollDistance = () => {
        if (typeof window !== 'undefined') {
            const scrollTop = window.pageYOffset; // how much the user has scrolled by
            const winHeight = window.innerHeight;
            const docHeight = this.getDocHeight();

            const totalDocScrollLength = docHeight - winHeight;
            const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)

            this.setState({
                scrollPostion,
            });
        }
    }

    getDocHeight = () => {
        if (typeof window !== 'undefined') {
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        }
    }

    playContent = () => {
        var myLongText = this.state.blogContent.replace(/<\/?[^>]+(>|$)/g, "").replaceAll('&nbsp;', ' ').replaceAll('</h1>', ' ').replaceAll('</h2>', ' ')
        // console.log(myLongText);
        var utterance = new SpeechSynthesisUtterance(myLongText);

        //modify it as you normally would
        var voiceArr = speechSynthesis.getVoices();
        utterance.voice = voiceArr[2];

        //pass it into the chunking function to have it played out.
        //you can set the max number of characters by changing the chunkLength property below.
        //a callback function can also be added that will fire once the entire text has been spoken.
        this.speechUtteranceChunker(utterance, {
            chunkLength: myLongText.length
        }, function () {
            //some code to execute when done
            console.log('done');
        });
    }

    controlContent = () => {
        if(!speechSynthesis.speaking){
            this.playContent()
            this.setState({ enablePause: true })
        }
        if(speechSynthesis.speaking){
            if (speechSynthesis.paused) {
                speechSynthesis.resume()
                this.setState({ enablePause: true })
            } else {
                speechSynthesis.pause()
                this.setState({ enablePause: false })
            }
        }   
    }

    handleEndEvent = (callback = null) => {
        if(callback){
            if (this.speechUtteranceChunker.cancel) {
                this.speechUtteranceChunker.cancel = false;
            }
            if (callback !== undefined) {
                callback();
            }
        }
    }

    speechUtteranceChunker = (utt, settings, callback) => {
        settings = settings || {};
        var txt = (settings && settings.offset !== undefined ? utt.text.substring(settings.offset) : utt.text);
        if (utt.voice && utt.voice.voiceURI === 'native') { // Not part of the spec
            this.newUtt.current = utt;
            this.newUtt.current.text = txt;
            this.newUtt.current.addEventListener('end', this.handleEndEvent(callback));
        }
        else {
            var chunkLength = (settings && settings.chunkLength) || 160;
            var pattRegex = new RegExp('^[\\s\\S]{' + Math.floor(chunkLength / 2) + ',' + chunkLength + '}[.!?,]{1}|^[\\s\\S]{1,' + chunkLength + '}$|^[\\s\\S]{1,' + chunkLength + '} ');
            var chunkArr = txt.match(pattRegex);

            if (chunkArr[0] === undefined || chunkArr[0].length <= 2) {
                //call once all text has been spoken...
                if (callback !== undefined) {
                    callback();
                }
                return;
            }
            var chunk = chunkArr[0];
            this.newUtt.current = new SpeechSynthesisUtterance(chunk);
            var x;
            for (x in utt) {
                if (utt.hasOwnProperty(x) && x !== 'text') {
                    this.newUtt.current[x] = utt[x];
                }
            }
            this.newUtt.current.addEventListener('end', function () {
                if (this.speechUtteranceChunker?.cancel) {
                    this.speechUtteranceChunker.cancel = false;
                    return;
                }
                settings.offset = settings.offset || 0;
                settings.offset += chunk.length - 1;
                this.speechUtteranceChunker(utt, settings, callback);
            });
        }

        if (settings.modifier) {
            settings.modifier(this.newUtt.current);
        }
        let text = this.newUtt.current //IMPORTANT!! Do not remove: Logging the object out fixes some onend firing issues.
        //placing the speak invocation inside a callback fixes ordering and onend issues.
        setTimeout(function () {
            speechSynthesis.speak(text);
            // this.setState({ speaking: speechSynthesis.speaking})
        }, 100);
    }

    render() {
        return (
            <React.Fragment>
                <HeadTags path={`blog/${this.props.match.params.blogName}`} />
                <Header />
                <section className="ad-blog-bg">
                    <Progress scroll={`${this.state.scrollPostion}%`} />
                    <div className="blog-bg-img">
                        <div className="ad-portfolio-main-container">
                            <div className="ad-portfolio-pages-hero-section">
                                <div className="ad-bread-crumbs">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb blog-breadcrumb-arrow">
                                            <li className="blog-breadcrumb-link-color breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="blog-breadcrumb-link-color breadcrumb-item"><Link to="/blog">Blog</Link></li>
                                            <li className="blog-breadcrumb-color breadcrumb-item res-header-blog-text active" aria-current="page">{this.state.blogTitle}</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div>
                                    <p className="ad-about-main-title ad-about-main-title-blog ad-blog-main-title-color">{this.state.blogTitle}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-section-part">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-3">
                                <BlogSidebar />
                                <p className="blog-cate-title">Share</p>
                                <FacebookShareButton url={typeof window !== 'undefined' ? window.location.href : ""} >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <LinkedinShareButton url={typeof window !== 'undefined' ? window.location.href : ""}>
                                    <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                                <TwitterShareButton url={typeof window !== 'undefined' ? window.location.href : ""}>
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <WhatsappShareButton url={typeof window !== 'undefined' ? window.location.href : ""}>
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>

                            <div className="col-xs-12 col-sm-9">

                                {
                                    this.state.blogContent !== "" ? (
                                        <div className="blog-post-wrapper">
                                            <h1 className="single-post-title">{this.state.blogTitle}</h1>
                                            <div className="blog-post-img-wrapper">
                                                <div className="blog-post-image">
                                                    {
                                                        this.state.banner !== "" &&
                                                        <img src={Config.API_URL + this.state.banner} alt="What's your LinkedIn strategy?" />
                                                    }
                                                </div>
                                                {/* <div className="single-post-img-cate"><a href="#">Interview</a></div> */}
                                            </div>
                                            <div className="d-flex align-items-center mb-3" >
                                                <p className="mb-0 mr-3"><span className="mr-2"><i class="far fa-clock"></i></span>{this.state.readingTime.text}</p>
                                                <button onClick={this.controlContent} className="media-control" data-toggle="tooltip" data-placement="top" title="Listen the blog">
                                                    {
                                                        this.state.enablePause ? <i class="fa fa-pause"></i> : <i class="fa fa-play"></i>
                                                    }
                                                </button>
                                            </div>
                                            <div className="blog-post-content-wrapper">
                                                {parse(this.state.blogContent)}
                                                {
                                                    this.state.extensionBlog &&
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <input type="text" placeholder="search pdf, docx etc" className="form-control" onChange={this.handleChange} />
                                                        </div>
                                                        <div className="col-2 extension-img">
                                                            <img src={`https://pro.alchemdigital.com/api/extension-image/${this.state.extension}`} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                        :
                                        (
                                            <>
                                                <Skeleton count={2} />
                                                <Skeleton height={'432px'} style={{ marginBottom: 10 }} />
                                                <Skeleton width={'150px'} />
                                                <Skeleton count={4} />
                                            </>
                                        )
                                }
                                <div className="blog-post-navigation">
                                    <div className="prev-navigation">
                                        {
                                            this.state.previousBlogTitle != '' && (
                                                <div>
                                                    <Link className="post-navigation-links" to={'/blog/' + this.state.previousBlogSlug}>
                                                        <span className="prev-post-direction"><i className="fas fa-arrow-left"></i></span><span className="prev-post-nav">Previous</span>
                                                        <div className="post-title-links">{this.state.previousBlogTitle}</div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="next-navigation">
                                        {
                                            this.state.nextBlogTitle != '' && (
                                                <div>
                                                    <Link className="post-navigation-links" to={'/blog/' + this.state.nextBlogSlug}>
                                                        <span className="next-post-nav">Next</span><span className="next-post-direction"><i className="fas fa-arrow-right"></i></span>
                                                        <div className="post-title-links">{this.state.nextBlogTitle}</div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="author-info">
                                    <div className="author-avatar">
                                        <img alt="profile" src={Config.HOST + "/assets/img/favicon.ico"} />
                                    </div>
                                    <div className="author-description">
                                        <h5 className="author-title">Alchem Digital</h5>
                                        <p className="author-bio">{this.state.blogAuthor}</p>
                                        {/* <a href="#" className="author-url">Website</a> */}
                                    </div>
                                    <div className="post-meta-date">
                                        <span className="post-meta-icon"><i className="fas fa-eye"></i></span>
                                        <span>{this.state.blogViews} views</span>
                                    </div>
                                </div>
                                <div className="post-related">
                                    <p className="post-related-title">You may also like this</p>
                                    <RelatedBlogCarousel relatedBlogs={this.state.relatedBlogs.filter(blog => blog.slug !== this.state.blogSlugTitle)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CookiePolicy />
            </React.Fragment>
        );
    }
}

export default BlogFive