import $, { data } from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class Branding extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }

    brandingProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Branding Strategy",
                "img" : "process-1.svg",
                "description" : "We build long-term strategies to elevate your brand and influence how your brand is perceived among your customers. We understand your business goals and implement brand-building strategies that are in line with your business objectives."
            },
            {
                "id" : 2,
                "title" : "Brand Identity",
                "img" : "process-2.svg",
                "description" : "We form your brand’s identity by developing a unique logo for your business, thematic designs, etc., and create a positive brand image among your consumers. The visible elements of your brand can help the audience differentiate your brand from the rest."
            },
            {
                "id" : 3,
                "title" : "Brand Assessment",
                "img" : "process-3.svg",
                "description" : "We evaluate your brand’s dominance among its competitors, how it is perceived by your audience, and make necessary changes while taking customer persona, target markets, and your goal into consideration."
            },
            {
                "id" : 4,
                "title" : "Brand Promotion",
                "img" : "process-4.svg",
                "description" : "Brand promotions establish and find a place for your business in the marketplace. Our brand promotion techniques help in increasing brand awareness, and influence consumers to make a purchasing decision."
            },
            {
                "id" : 5,
                "title" : "Brand Management",
                "img" : "process-5.svg",
                "description" : "We help increase your brand value by influencing how it is perceived among your prospects and gain loyal customers for your brand. Through our brand management efforts, we increase your brand awareness, promote your brand image and also help in brand positioning."
            }
        ]
    
    toggle = (e, tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            setInterval(5000, this.intervalRef.current);
        }
    }

    componentDidMount(){
        // $('.nav-tabs-dropdown')
        // .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        // })
        // .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        // });
        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.branding-process > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.branding-process > li').length
        //     ? 
        //     active.parent().next('.branding-process > li').find('a') 
        //     : 
        //     tabs.parent().filter('.branding-process > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.branding-process a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.3;
        var $bg_images = $(".brand-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        // this.intervalRef.current = setInterval((prevState, index) => {
        //     if (prevState >= 5) {
        //         this.setState({
        //             activeTab: 1
        //         });
        //     } 
        //     else {
        //         this.setState({
        //             activeTab: prevState + 1
        //         });
        //     }
        // }, 5000);


        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.brandingProcessContent.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='branding-company-in-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="branding-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="res-dev-content-sec">
                                        <h1 style={{ lineHeight: 1.2 }} className="digi-mark-main-banner-title">Branding Company in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle pt-4">Branding creates a compelling perception of your services and products among your customers and the best branding company in Chennai can create you a branding strategy that sells.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/branding/branding-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="branding-container-align">
                                    <h1 className="digi-market-zig-zag-title">What is Branding?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>Creating a strong perception is an important step for any business and branding is the way for you to do it. An effective branding strategy is important to get your prospect’s attention and make them your loyal customers. Branding gives you an opportunity to stand out from the crowd and tell your customers how you are different from your competitors and what you can offer them. Your brand is more than just a bunch of logos and themes for your brand, it is how your business, products, and services are perceived by your customers and audience. Your brand can be enhanced by having the right branding strategy in place.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2 branding-main-align">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse align-items-center">
                            <div className="col-xs-12 col-sm-7">
                                <div className="branding-container-align-2">
                                    <h1 className="digi-market-zig-zag-title">Why is Branding Important?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>How else are you going to shape and promote your brand? Branding makes your company recognizable by increasing your brand awareness. Branding gives you a chance to stand out from the rest of your competition by shaping your brand and establishing your brand’s identity. Your brand should tell your customers who you are and what they get by investing in you. Branding can be essential to elevate your business irrespective of your industry and business size. Consistent marketing efforts can push your customers to recognize your brand and make a purchase decision easily.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-5 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/branding/branding-2.jpg`} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="branding-ofc-desk-section">
                    <div className="row align-items-center">
                        <div className="col-xs-12 col-sm-6">
                            <img src={`${Config.HOST}/assets/img/branding/branding-3.png`} alt="branding-ofc-desk"/>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className="digi-market-zig-zag-content branding-content-box">
                                <p>With the increasing amount of competition in today’s world, it has become mandatory for you to stand out from the crowd for you to even be noticed by your prospects. Branding tells your customers what they can expect from you and how they will benefit from you. Building a strong brand makes your customers get emotionally inclined to your products and make a purchase decision. Reputation is inevitable, be it positive or negative. Branding efforts give you authority over what kind of reputation your business forms. Your business values can be enhanced by various branding activities.</p>
                                <h2>Want to more know about how branding can uplift your company? <Link to="/contact">Talk to us now.</Link></h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="branding-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Benefits of Branding</h1>
                        <div className="digi-benefits-align branding-benefits-list">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Increased <span>Credibility</span></a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">Stand Out of <span>the Crowd</span></a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Customer Retention</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Better Marketing <span>Efforts</span></a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="branding-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/branding/benefits-icons/benefits-1.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Increased Credibility</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Consistent and right branding strategy will help you increase your brand’s credibility thereby increasing trust in your brand among your customers. Building credibility and trust among your customers can help them make a purchasing decision quicker. Both verbal and non-verbal branding efforts help form a strong perception of your brand amidst your customers.</p>
                                        <a href="#branding-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="branding-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/branding/benefits-icons/benefits-2.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Stand Out of the Crowd</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Your branding strategy can help you differentiate your business from the rest. With the ever-growing number of businesses, it has become important for you to stand out from the crowd. Branding lets you tell your prospects how you are different from your competitors and what you can bring to their table.</p>
                                        <a href="#branding-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="branding-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/branding/benefits-icons/benefits-3.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Customer Retention</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Your ultimate aim is to attract more customers and retain your existing ones. Branding lets you tell your customers about the values of your company, which can bring you and your customer together. Building trust among your customers can help them be more loyal to your brand and business and purchase from you.</p>
                                        <a href="#branding-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="branding-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/branding/benefits-icons/benefits-4.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Better Marketing Efforts</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Marketing efforts are reached better when you have a branding strategy in line with you. Branding makes your company, products, and services memorable among your consumers and gives them an idea as to what to expect from you. By branding, you get to tell your prospects how you are different from your competitors and what makes you the better option for them.</p>
                                        <a href="#branding-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="branding-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Our Branding Process</h1>
                        <div className="branding-content-cont">
                            {/* <ul className="nav nav-pills branding-process mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-1-tab" data-toggle="pill" href="#pills-seo-content-1" role="tab" aria-controls="pills-seo-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Branding Strategy</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Branding Strategy</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-2-tab" data-toggle="pill" href="#pills-seo-content-2" role="tab" aria-controls="pills-seo-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Brand Identity</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Brand Identity</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-3-tab" data-toggle="pill" href="#pills-seo-content-3" role="tab" aria-controls="pills-seo-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Brand Assessment</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Brand Assessment</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-4-tab" data-toggle="pill" href="#pills-seo-content-4" role="tab" aria-controls="pills-seo-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Brand Promotion</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Brand Promotion</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-5-tab" data-toggle="pill" href="#pills-seo-content-5" role="tab" aria-controls="pills-seo-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Brand Management</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Brand Management</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active active-anim" id="pills-seo-content-1" role="tabpanel" aria-labelledby="pills-seo-content-1-tab">
                                    <div className="brand-process-content">
                                        <h3>Branding Strategy</h3>
                                        <p>We build long-term strategies to elevate your brand and influence how your brand is perceived among your customers. We understand your business goals and implement brand-building strategies that are in line with your business objectives.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-2" role="tabpanel" aria-labelledby="pills-seo-content-2-tab">
                                    <div className="brand-process-content">
                                        <h3>Brand Identity</h3>
                                        <p>We form your brand’s identity by developing a unique logo for your business, thematic designs, etc., and create a positive brand image among your consumers. The visible elements of your brand can help the audience differentiate your brand from the rest.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-3" role="tabpanel" aria-labelledby="pills-seo-content-3-tab">
                                    <div className="brand-process-content">
                                        <h3>Brand Assessment</h3>
                                        <p>We evaluate your brand’s dominance among its competitors, how it is perceived by your audience, and make necessary changes while taking customer persona, target markets, and your goal into consideration.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-4" role="tabpanel" aria-labelledby="pills-seo-content-4-tab">
                                    <div className="brand-process-content">
                                        <h3>Brand Promotion</h3>
                                        <p>Brand promotions establish and find a place for your business in the marketplace. Our brand promotion techniques help in increasing brand awareness, and influence consumers to make a purchasing decision.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-5" role="tabpanel" aria-labelledby="pills-seo-content-5-tab">
                                    <div className="brand-process-content">
                                        <h3>Brand Management</h3>
                                        <p>We help increase your brand value by influencing how it is perceived among your prospects and gain loyal customers for your brand. Through our brand management efforts, we increase your brand awareness, promote your brand image and also help in brand positioning.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })}>
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Branding Strategy</h4>
                                        <div className="seo-process-circles" onClick={() => { this.toggle('1'); }}>
                                            <img src={`${Config.HOST}/assets/img/branding/process-icons/process-1.svg`}  alt="brand-process"/>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Branding Strategy</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })}>
                                        <h4 className="padd-bottom-12 process-top-align">Brand Identity</h4>
                                        <div className="seo-process-circles" onClick={() => { this.toggle('2'); }}>
                                            <img src={`${Config.HOST}/assets/img/branding/process-icons/process-2.svg`}  alt="brand-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Brand Identity</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })}>
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Brand Assessment</h4>
                                        <div className="seo-process-circles" onClick={() => { this.toggle('3'); }}>
                                            <img src={`${Config.HOST}/assets/img/branding/process-icons/process-3.svg`}  alt="brand-process"/>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Brand Assessment</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })}>
                                        <h4 className="padd-bottom-12 process-top-align">Brand Promotion</h4> 
                                        <div className="seo-process-circles" onClick={() => { this.toggle('4'); }}>
                                            <img src={`${Config.HOST}/assets/img/branding/process-icons/process-4.svg`}  alt="brand-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Brand Promotion</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })}>
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Brand Management</h4>  
                                        <div className="seo-process-circles" onClick={() => { this.toggle('5'); }}>
                                            <img src={`${Config.HOST}/assets/img/branding/process-icons/process-5.svg`}  alt="brand-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12 process-bottom-align">Brand Management</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                <TabPane tabId="1" className="active-anim">
                                    <div className="seo-process-content">
                                        <h3>Branding Strategy</h3>
                                        <p>We build long-term strategies to elevate your brand and influence how your brand is perceived among your customers. We understand your business goals and implement brand-building strategies that are in line with your business objectives.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" className="active-anim">
                                    <div className="seo-process-content">
                                        <h3>Brand Identity</h3>
                                        <p>We form your brand’s identity by developing a unique logo for your business, thematic designs, etc., and create a positive brand image among your consumers. The visible elements of your brand can help the audience differentiate your brand from the rest.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3" className="active-anim">
                                    <div className="seo-process-content">
                                        <h3>Brand Assessment</h3>
                                        <p>We evaluate your brand’s dominance among its competitors, how it is perceived by your audience, and make necessary changes while taking customer persona, target markets, and your goal into consideration.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4" className="active-anim">
                                    <div className="seo-process-content">
                                        <h3>Brand Promotion</h3>
                                        <p>Brand promotions establish and find a place for your business in the marketplace. Our brand promotion techniques help in increasing brand awareness, and influence consumers to make a purchasing decision.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5" className="active-anim">
                                    <div className="seo-process-content">
                                        <h3>Brand Management</h3>
                                        <p>We help increase your brand value by influencing how it is perceived among your prospects and gain loyal customers for your brand. Through our brand management efforts, we increase your brand awareness, promote your brand image and also help in brand positioning.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}
                            <Nav tabs>
                                {
                                    this.brandingProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={`${Config.HOST}/assets/img/branding/process-icons/`+item.img}  alt="brand-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.brandingProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process mob-brand-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/branding/process-icons/process-1.svg`} alt="seo-process"/>
                                    <h4>Branding Strategy</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>We build long-term strategies to elevate your brand and influence how your brand is perceived among your customers. We understand your business goals and implement brand-building strategies that are in line with your business objectives.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/branding/process-icons/process-2.svg`} alt="seo-process"/>
                                    <h4>Brand Identity</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>We form your brand’s identity by developing a unique logo for your business, thematic designs, etc., and create a positive brand image among your consumers. The visible elements of your brand can help the audience differentiate your brand from the rest.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/branding/process-icons/process-3.svg`} alt="seo-process"/>
                                    <h4>Brand Assessment</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>We evaluate your brand’s dominance among its competitors, how it is perceived by your audience, and make necessary changes while taking customer persona, target markets, and your goal into consideration.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/branding/process-icons/process-4.svg`} alt="seo-process"/>
                                    <h4>Brand Promotion</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Brand promotions establish and find a place for your business in the marketplace. Our brand promotion techniques help in increasing brand awareness, and influence consumers to make a purchasing decision.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/branding/process-icons/process-5.svg`} alt="seo-process"/>
                                    <h4>Brand Management</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>We help increase your brand value by influencing how it is perceived among your prospects and gain loyal customers for your brand. Through our brand management efforts, we increase your brand awareness, promote your brand image and also help in brand positioning.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="globe-design-img">
                    <img src={`${Config.HOST}/assets/img/branding/branding-bg-1.jpg`} alt="process-flower-design"/>
                </section>
                <section className="brand-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Create a winning branding strategy now</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <Link to="/contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Ping us</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Our Branding Services</h1>
                        <div className="res-dev-key-features-container digi-market-mega-cont">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/branding/brand-services/brand-serv-1.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Logo Design</h2>
                                    <p className="res-dev-key-feature-excerpt">Logo is the most important identity of your brand that leaves an impression about your brand to your audience and also remember it. Logo defines your brand’s foundation, lets it stand out of the crowd, and influences your other marketing materials. Being a leading branding company in Chennai, we make sure that your logo effectively communicates your brand’s identity.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/branding/brand-services/brand-serv-2.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Product branding</h2>
                                    <p className="res-dev-key-feature-excerpt">Product branding helps your audience differentiate your brand from the rest in the market and also helps them make a purchase decision easily. We create your product branding strategy that is consistent and helps your audience easily identify your brand, products, and services.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/branding/brand-services/brand-serv-3.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Corporate identity</h2>
                                    <p className="res-dev-key-feature-excerpt">Your corporate identity defines how your brand is perceived by your stakeholders. Your corporate identity shows your brand’s designs and visual assets. An effective corporate identity strategy can help your brand be recognized by the audience and can also help your brand be memorable among them. We see that your company’s identity is connected on the whole and our designers always work towards getting you the best of results.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/branding/brand-services/brand-serv-4.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Brand Collateral</h2>
                                    <p className="res-dev-key-feature-excerpt">Brand collaterals help you enhance your sales and marketing efforts and also helps in promoting your business. Brand collaterals can help your products and services stand out across different channels. For your customers or prospects to easily recognize or identify your brand, you need to be consistent across all channels of your business. We provide you with landing pages, brochures, packaging, letter heads, merch, etc. that are consistent with your brand.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/branding/brand-services/brand-serv-5.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Brand Consistency</h2>
                                    <p className="res-dev-key-feature-excerpt">We maintain consistency across various branding services as it can help reinforce your brand’s tone and image. We offer unified branding services that can impact your customer’s experience with your brand. Brand continuity increases the familiarity of your brand among your audience, thereby giving you a better reach. We offer you a consistent branding services beginning from your logo to your marketing copies.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove digi-market-services-last-align">
                                <div className="res-dev-key-feature-sections">
                                    <h2 className="digi-market-services-last-box-content">What branding services are you looking for?</h2>
                                    <Link to='/contact' className="digi-market-content-button button-gradient">Drop in your deets</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main pt-4">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/branding/branding-4.png`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="branding-container-align">
                                    <h1 className="digi-market-zig-zag-title">Why is Alchem Digital the Best Branding Company in Chennai?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>At <Link to="/">Alchem Digital</Link>, we understand your brand’s goals and create branding strategies that will help achieve these goals and effectively position your brand in the marketplace. We join forces in adding value to your brand by combining our creative and marketing talents and <Link to="/portfolio">our works</Link> stand a testament to it. <Link to="/about">Our team</Link> of young minds work on a unanimous goal, and that is to present the best possible solution for your business. We work together to turn your audience into your loyal customers and ultimately help you reach your business goals.</p>
                                    </div>
                                    <Link to="/contact" className="digi-benefits-content-button button-gradient transition-issue">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section id="branding-page-contact" className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default Branding