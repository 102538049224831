import axios from "axios"
import $ from "jquery"
import React, {Component} from 'react'
import Select from "react-dropdown-select";
import ReCAPTCHA from "react-google-recaptcha"
import Swal from "sweetalert2"
import AnimationLoader from '../Animations/AnimeLoader'
import AlchemMap from '../layout/Alchem-map'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import Config from './Config'


class Contact extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
            name: "",
            name_err: false,
            email: "",
            email_err: false,
            phone: "",
            phone_err: false,
            /*subjects: [
                '--select subject--', 'Website design & development', 'Digital marketing', 'Branding', 'Content marketing', 'SaaS development', 'SEO', 'Graphic designing', 'Careers',
            ],*/
            subjects :[{ label: "Website design & development", value: 1 },{ label: "Digital marketing", value: 2 },{ label: "Branding", value: 3 },{ label: "Content marketing", value: 4 },{ label: "SaaS development", value: 5 },{ label: "SEO", value: 6 },{ label: "Graphic designing", value: 7 }],
            subject: '',
            subject_err: false,
            message_err: false,
            message: "",
            message_err: false,
            sitekey: '6Le49NwaAAAAABo2E3vYaGDeHxHqB02M5eNYGY35',
            captcha_value: '',
            show_loader: false,
            show_animate_loader: false,
        }
    }

    componentDidMount = () => {
        this.emptyForm()
    }

    emptyForm = () => {
        this.setState({
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        switch (e.target.name) {
            case 'name': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        name_err: 'Name is required'
                    })
                }else {
                    this.setState({
                        name_err: false
                    })
                }
            }
            case 'email': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        email_err: 'Email is required'
                    })
                }else {
                    this.setState({
                        email_err: false
                    })
                }
                break;
            }
            case 'phone': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        phone_err: 'phone is required'
                    })
                }else {
                    this.setState({
                        phone_err: false
                    })
                }
                break;
            }
            case 'subject': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        subject_err: 'Subject is required'
                    })
                }else {
                    this.setState({
                        subject_err: false
                    })
                }
                break;
            }
            case 'message': {
                if (e.target.value === '' || e.target.value.length === 0) {
                    this.setState({
                        message_err: 'Message is required'
                    })
                }else {
                    this.setState({
                        message_err: false
                    })
                }
                break;
            }
        }
    }

    captchaChange = (value) => {
        this.setState({
            captcha_value: value
        })
    }

    emptyCaptcha = (e) => {
        this.setState({
            captcha_value: ''
        })
    }
    subjectChange = (value)=>{
        
        this.setState({
            subject: value[0]['label']
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if(this.state.name == '' || this.state.phone == '' || this.state.email == '' || this.state.subject == '' || this.state.message == ''){
            // alert("Please fill all necessary field")
            Swal.fire({  
                icon: 'error',   
                text: 'Please fill all necessary field' 
            });
            return
        }
        if(this.state.captcha_value == ''){
            // alert("Please select the captcha")
            Swal.fire({  
                icon: 'error',   
                text: 'Please select the captcha'
            });
            return
        }
        const headers = {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
        }
        let formData = new FormData();
        formData.append('name', this.state.name)
        formData.append('phone', this.state.phone)
        formData.append('email', this.state.email)
        formData.append('subject', this.state.subject)
        formData.append('message', this.state.message)
        let url = Config.API_URL+'/api/lead'
        this.setState({
            show_loader: true
            // show_animate_loader: true
        })
        axios.post(url, formData, headers)
        
        .then(response => {
            window.location.href = "/thank-you-alchem";
            const img = new Image()
            img.src = `https://px.ads.linkedin.com/collect/?pid=2789156&conversionId=4483804&fmt=gif%22`
            this.emptyForm()
            this.emptyCaptcha()
            this.captcha.reset()
        }).catch(error => {
            alert(error.response.data.error)
        }).finally(() => {
            this.setState({
                show_loader: false
                // show_animate_loader: false
            })
        })
    }
    
    render(){
        return(
            <React.Fragment>
                <HeadTags path='contact' />
                <Header/>
                <section className="ad-contact-bg">
                    <AlchemMap/>
                    <section className="ad-contact-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <div className="ad-contact-form-container">
                                        <div className="text-left">
                                            <p className="ad-contact-form-main-title mb-0">Get in Touch!</p>
                                        </div>
                                        <form action="" onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor=""></label>
                                                        <input type="text" name="name" id="" className="form-control ad-contact-form-control" placeholder="Full Name" onChange={this.handleChange} value={this.state.name}/>
                                                    </div>
                                                    <span className="alert-ui">{this.state.name_err}</span>
                                                </div>
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor=""></label>
                                                        <input type="number" name="phone" id="" className="form-control ad-contact-form-control" placeholder="Phone No." onChange={this.handleChange} value={this.state.phone}/>
                                                    </div>
                                                    <span className="alert-ui">{this.state.phone_err}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor=""></label>
                                                        <input type="email" className="form-control ad-contact-form-control" name="email" id="" placeholder="Email ID" onChange={this.handleChange} value={this.state.email}/>
                                                    </div> 
                                                    <span className="alert-ui">{this.state.email_err}</span>
                                                </div>
                                                <div className="col-xs-12 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor=""></label>
                                                        {/*<select className="ad-contact-form-control ad-contact-form-select form-contact-select-arrow" name="subject" onChange={this.handleChange} value={this.state.subject}>
                                                            { 
                                                                this.state.subjects.map((subject, key) => {
                                                                    if (key == 0)
                                                                        return (
                                                                            <option key={key} selected disabled>{subject}</option>
                                                                        )
                                                                    return (
                                                                        <option key={key} value={subject}>{subject}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>*/}
                                                        <Select options={this.state.subjects} name="subject" className="contact-form-select" onChange={this.subjectChange} value={this.state.subject} placeholder="Regarding"/>
                                                        <span className="alert-ui">{this.state.subject_err}</span>
                                                    </div> 
                                                </div>
                                            </div>
                                            {/* <div id="career" className="form-group">
                                                <div className="file-upload">
                                                    <div className="file-select">
                                                        <div className="file-select-button">Browse</div>
                                                        <div className="file-select-name" id="noFile">No file chosen...</div> 
                                                        <input type="file" className="form-control ad-contact-form-control" id="ad-resume"/>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="form-group">
                                                <label htmlFor=""></label>
                                                <textarea className="form-control ad-contact-form-control ad-contact-form-control-textarea" placeholder="Your Message" name="message" id="" rows="5" onChange={this.handleChange} value={this.state.message}></textarea>
                                            </div>
                                            <span className="alert-ui">{this.state.message_err}</span>
                                            <ReCAPTCHA
                                                ref={e => (this.captcha = e)}
                                                onChange = {this.captchaChange}    
                                                sitekey={this.state.sitekey}
                                                onExpired={this.emptyCaptcha}
                                                onErrored={this.emptyCaptcha}
                                            />
                                            {
                                                this.state.show_loader ? 
                                                    <div className="mt-5 text-center">
                                                        <button type="button" className="alchem-digi-services-button-new button-gradient" disabled>Sending...</button>
                                                    </div>
                                                :
                                                    <div className="mt-5 text-center">
                                                        <button type="submit" className="alchem-digi-services-button-new button-gradient">Send Message</button>
                                                    </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="ad-social-med-container">
                                        <div className="text-left">
                                            <p className="ad-social-media-main-title mb-0">Contact Info</p>
                                        </div>
                                        <div className="ad-contact-info">
                                            <ul className="ad-contact-list">
                                                <li>
                                                    <div className="ad-contact-main-content">
                                                        <p className="contact-info-part">ADDRESS</p>
                                                        <ul className="contact-info-content">
                                                            <li><span>Chennai</span> 1st Floor, Flat No.A106/6 and 188/6, Rangarajapuram Main Road, Kodambakkam, Chennai 600 024</li>
                                                            <li><span>Chicago</span>  415 Howard St #1001, Evanston, IL 60202, United States.</li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ad-contact-main-content">
                                                        <p className="contact-info-part">PHONE</p>
                                                        <ul className="contact-info-content">
                                                            <li className="mb-0"><span>IN</span> <a href="tel:+91 7305639892">+91 7305639892</a></li>
                                                            <li className="mb-0"><span>US</span> <a href="tel:+1 (469) 929-1097">+1 (469) 929-1097</a> </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ad-contact-main-content">
                                                        <p className="contact-info-part">EMAIL</p>
                                                        <ul className="contact-info-content">
                                                            <li><a href="mailto:getintouch@alchemdigital.com">getintouch@alchemdigital.com</a> </li>
                                                            <li></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="ad-social-links">
                                                <ul className="ad-social-link-cont">
                                                    <li><a target="_blank" href="https://www.facebook.com/alchemdigital"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a target="_blank" href="https://twitter.com/alchemdigital"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a target="_blank" href="https://www.instagram.com/alchemdigital/"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a target="_blank" href="https://www.linkedin.com/company/alchemdigital/"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                {/* {
                    this.state.show_animate_loader ? 
                    <div className="contact-form-loader-bg">
                        <AnimationLoader/>
                    </div>
                    :
                    <div className="contact-form-loader-bg" hidden>
                        <AnimationLoader/>
                    </div>
                } */}
                {/* <FloatingIcons/> */}
            </React.Fragment>
        );
    }
}

export default Contact