import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class ECommerceWebsiteDevelopment extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }

    EComWebProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Gather requirements",
                "img" : "process-1.svg",
                "description" : "To begin with, we understand your business and your eCommerce objectives to build you an ideal website that will complement your business. We analyze your requirements and decide on the perfect technology that will best suit your needs and help you build your virtual storefront."
            },
            {
                "id" : 2,
                "title" : "Design",
                "img" : "process-2.svg",
                "description" : "On understanding your business’s E-commerce objective, we create a wireframe of what your website will consist of and begin designing a unique prototype. Once we have approval for the prototype, we begin the UI design for your website."
            },
            {
                "id" : 3,
                "title" : "Development",
                "img" : "process-3.svg",
                "description" : "Once we design the UI/UX for your business, we begin developing your eCommerce site understanding what your audience is looking for, and ensuring a fluid user experience overall."
            },
            {
                "id" : 4,
                "title" : "Testing",
                "img" : "process-4.svg",
                "description" : "Once your eCommerce website is developed, we test it end-to-end to make sure that it is free of all bugs and ensure all the functionalities of your site are in place. We make sure your site is fully functional and ready for launch."
            },
            {
                "id" : 5,
                "title" : "Launch",
                "img" : "process-5.svg",
                "description" : "Upon testing the e-store completely and after the iterations, if need be, based on the client’s feedback, the virtual store would be up and live for the customers to make those purchases."
            },
            {
                "id" : 6,
                "title" : "Training",
                "img" : "process-6.svg",
                "description" : "It does not end with the launch. Our process also includes training and educating you to work around your eCommerce site’s functionalities like adding to or removing a product from your catalog, adding coupons, if necessary, adding offers, etc. without having to look for external support."
            }
        ]

    toggle = (e, tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            setInterval(5000, this.intervalRef.current);
        }
    }


    componentDidMount(){
        // $('.nav-tabs-dropdown')
        // .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        // })
        // .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        // });

        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.e-commerce-process > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.e-commerce-process > li').length
        //     ? 
        //     active.parent().next('.e-commerce-process > li').find('a') 
        //     : 
        //     tabs.parent().filter('.e-commerce-process > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.e-commerce-process a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.6;
        var $bg_images = $(".brand-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.e.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='e-commerce-website-development-company-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="digi-mark-bg-img e-commerce-web-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-10">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">E-Commerce website development in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">Reach a wider audience and boost your online sales with the best eCommerce website development company in Chennai.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main e-commerce-main-section">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-img-1.jpg`} alt="e-commerce-img"/>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="branding-container-align">
                                    <h1 className="digi-market-zig-zag-title">E-Commerce website development</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>With the rapid increase in online shoppers, a physical store might not be all you need to keep your business up and running. While building an online store for your business can sound complicated, with the right understanding of your eCommerce site and specific functionalities, an eCommerce website can have a positive impact on your business performance.</p>
                                        <p>At Alchem Digital, we provide a competent online storefront for any business be it B2Bs or B2Cs. We build custom eCommerce sites for businesses of any size while taking into account your budget, requirements, and extensive market research.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">How can an E-Commerce website help your business grow?</h1>
                        <div className="digi-benefits-align e-commerce-benefits-list">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Increased brand <span>visibility</span></a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">Better marketing opportunities</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Personalized <span>experience</span></a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-benefits/e-commerce-benefits-1.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Increased brand visibility</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">By investing in an eCommerce website, your customers are no longer limited just to your geographical location. An eCommerce site can help you reach your target audience in various places and increase your brand visibility.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-benefits/e-commerce-benefits-2.svg`} alt="benefits-icon-1"/>
                                                <h2 className="digi-market-title-benefit">Better marketing opportunities</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">With the right strategies, like optimizing your site for the search engine, email marketing, etc. eCommerce websites can help you reach your prospective customers and increase your sales opportunities.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-benefits/e-commerce-benefits-3.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Personalized experience</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">An eCommerce website allows you to learn your customers’ behavior and offer them a customized experience that can improve their shopping experience and in turn increase your conversion rates.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">E-Commerce development process at <br/> Alchem Digital</h1>
                        <div className="wordpress-content-cont">
                            {/* <ul className="nav nav-pills e-commerce-process mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-1-tab" data-toggle="pill" href="#pills-seo-content-1" role="tab" aria-controls="pills-seo-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Gather requirements</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Gather requirements</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-2-tab" data-toggle="pill" href="#pills-seo-content-2" role="tab" aria-controls="pills-seo-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Design</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Design</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-3-tab" data-toggle="pill" href="#pills-seo-content-3" role="tab" aria-controls="pills-seo-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Development</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Development</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-4-tab" data-toggle="pill" href="#pills-seo-content-4" role="tab" aria-controls="pills-seo-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Testing</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Testing</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-5-tab" data-toggle="pill" href="#pills-seo-content-5" role="tab" aria-controls="pills-seo-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Launch</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Launch</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-6-tab" data-toggle="pill" href="#pills-seo-content-6" role="tab" aria-controls="pills-seo-content-6" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Training</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-6"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Training</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active active-anim" id="pills-seo-content-1" role="tabpanel" aria-labelledby="pills-seo-content-1-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Gather requirements</h3>
                                        <p>To begin with, we understand your business and your eCommerce objectives to build you an ideal website that will complement your business. We analyze your requirements and decide on the perfect technology that will best suit your needs and help you build your virtual storefront.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-2" role="tabpanel" aria-labelledby="pills-seo-content-2-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Design</h3>
                                        <p>On understanding your business’s E-commerce objective, we create a wireframe of what your website will consist of and begin designing a unique prototype. Once we have approval for the prototype, we begin the UI design for your website.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-3" role="tabpanel" aria-labelledby="pills-seo-content-3-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Development</h3>
                                        <p>Once we design the UI/UX for your business, we begin developing your eCommerce site understanding what your audience is looking for, and ensuring a fluid user experience overall.</p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-4" role="tabpanel" aria-labelledby="pills-seo-content-4-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Testing</h3>
                                        <p>Once your eCommerce website is developed, we test it end-to-end to make sure that it is free of all bugs and ensure all the functionalities of your site are in place. We make sure your site is fully functional and ready for launch.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-5" role="tabpanel" aria-labelledby="pills-seo-content-5-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Launch</h3>
                                        <p>Upon testing the e-store completely and after the iterations, if need be, based on the client’s feedback, the virtual store would be up and live for the customers to make those purchases.</p>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-6" role="tabpanel" aria-labelledby="pills-seo-content-6-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Training</h3>
                                        <p>It does not end with the launch. Our process also includes training and educating you to work around your eCommerce site’s functionalities like adding to or removing a product from your catalog, adding coupons, if necessary, adding offers, etc. without having to look for external support.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Local SEO</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '1' ? `${Config.HOST}/assets/img/seo/seo-process-1.svg` : `${Config.HOST}/assets/img/seo/seo-process-1-black.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Local SEO</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <h4 className="padd-bottom-12">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '2' ? `${Config.HOST}/assets/img/seo/seo-process-2-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-2.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Keyword Research</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">On-page Optimization</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '3' ? `${Config.HOST}/assets/img/seo/seo-process-3-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-3.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">On-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <h4 className="padd-bottom-12">Off-page Optimization</h4> 
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '4' ? `${Config.HOST}/assets/img/seo/seo-process-4-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-4.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Off-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Continuous Optimization</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '5' ? `${Config.HOST}/assets/img/seo/seo-process-5-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-5.svg`}  alt="seo-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12">Continuous Optimization</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="seo-process-content">
                                        <h3>Local SEO</h3>
                                        <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="seo-process-content">
                                        <h3>On-page Optimization</h3>
                                        <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="seo-process-content">
                                        <h3>Off-page Optimization</h3>
                                        <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="seo-process-content">
                                        <h3>Continuous Optimization</h3>
                                        <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}
                            <Nav tabs>
                                {
                                    this.EComWebProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/`+item.img}  alt="e-com-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.EComWebProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process mob-brand-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-1.svg`} alt="seo-process"/>
                                    <h4>Gather requirements</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>To begin with, we understand your business and your eCommerce objectives to build you an ideal website that will complement your business. We analyze your requirements and decide on the perfect technology that will best suit your needs and help you build your virtual storefront.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-2.svg`} alt="seo-process"/>
                                    <h4>Design</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>On understanding your business’s E-commerce objective, we create a wireframe of what your website will consist of and begin designing a unique prototype. Once we have approval for the prototype, we begin the UI design for your website.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-3.svg`} alt="seo-process"/>
                                    <h4>Development</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we design the UI/UX for your business, we begin developing your eCommerce site understanding what your audience is looking for, and ensuring a fluid user experience overall.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-4.svg`} alt="seo-process"/>
                                    <h4>Testing</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once your eCommerce website is developed, we test it end-to-end to make sure that it is free of all bugs and ensure all the functionalities of your site are in place. We make sure your site is fully functional and ready for launch.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-5.svg`} alt="seo-process"/>
                                    <h4>Launch</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Upon testing the e-store completely and after the iterations, if need be, based on the client’s feedback, the virtual store would be up and live for the customers to make those purchases.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-6.svg`} alt="seo-process"/>
                                    <h4>Training</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>It does not end with the launch. Our process also includes training and educating you to work around your eCommerce site’s functionalities like adding to or removing a product from your catalog, adding coupons, if necessary, adding offers, etc. without having to look for external support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="brand-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Need a robust eCommerce website?</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <Link to="/contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Contact us now</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">E-Commerce solutions we provide</h1>
                        <div className="res-dev-key-features-container digi-market-mega-cont">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-1.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Custom E-commerce solutions</h2>
                                    <p className="res-dev-key-feature-excerpt">Thoroughly analyzing your business requirements, we come up with a unique eCommerce website that will complement your business. We provide your eCommerce website with robust functionalities that are in line with your specifications. We develop responsive sites that can engage with your users and convert them into your customers.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-2.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">E-Commerce marketplace</h2>
                                    <p className="res-dev-key-feature-excerpt">We provide you with effective eCommerce marketplace solutions to showcase and get your products to your target customers efficiently. We also help build multi-vendor eCommerce marketplaces that support showcasing products from multiple third-party sellers and build sites that are easily manageable for the site owners, sellers, and consumers. We build marketplaces that help you easily manage several aspects of your business beginning from your product lists to your shipping reports. With our e-commerce marketplace services, you will be able to reach customers not limited to your local customers.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-3.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">E-Commerce optimization</h2>
                                    <p className="res-dev-key-feature-excerpt">We provide our clients with eCommerce optimization services to improve your website and ensure it is user-friendly that will turn your users into customers. We offer our clients effective eCommerce optimization services including search engine optimization, speed optimization, performance optimization, etc. to help you increase sales and generate revenue from your online store.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-4.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">E-commerce migration</h2>
                                    <p className="res-dev-key-feature-excerpt">We help businesses migrate from any eCommerce platform to the other without losing any data or the integrity of your website. We provide our clients with seamless migration services that ensure seamless migration of products, customer data, order details, payment history, extensions, and integrations.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-5.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">E-Commerce integration</h2>
                                    <p className="res-dev-key-feature-excerpt">Our eCommerce services allow you to coordinate with your website and your backend systems for the smooth functioning of your business. We develop eCommerce websites that are compatible with all third-party integrations like payment gateway, CRM, ERP, email campaigns, and more.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-6.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">E-Commerce maintenance</h2>
                                    <p className="res-dev-key-feature-excerpt">We provide our clients with maintenance services to make sure your eCommerce site is performing to its fullest at all times. We offer end-to-end maintenance services to optimize your online store performance ranging from speed optimization, bug fixing, performance optimization, SEO updates, inventory management, to providing timely backups and monitoring your server health.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-serv-icons/e-commerce-icons-7.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">E-Commerce consulting</h2>
                                    <p className="res-dev-key-feature-excerpt">We also offer eCommerce consulting services to provide our clients, especially those who are new to eCommerce, with enough insight into the right platform for your business depending on your business requirements, the products you are selling, the size and scale of a business, and much more. Apart from this, we also provide technology consulting for B2Cs, B2Bs, etc., and operational consulting that can align your technology needs with your eCommerce objectives, and maximize your customer experience.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove digi-market-services-last-align">
                                <div className="res-dev-key-feature-sections">
                                    <h2 className="digi-market-services-last-box-content">Leverage the power of an online store</h2>
                                    <Link to='/contact' className="digi-market-content-button button-gradient">Reach us now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-technologies e-commerce-tech-logos">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Magento</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">WordPress WooCommerce</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Core PHP</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Laravel</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Python (Django)</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="ecommerce-laptop-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-serve-you-container ecommerce-content-container">
                                    <h1 className="digi-market-serve-you-title">Why choose Alchem Digital?</h1>
                                    <p className="digi-market-serve-you-content">At Alchem Digital, we gain an insight into what you want to accomplish through your eCommerce website and provide you customized eCommerce solution that complements your business. We have experienced designers and developers who work together in providing you with the best eCommerce website that can enhance your business. We provide our clients with flexible, scalable, and user-friendly eCommerce sites that increase their business efficiency.</p>
                                    <p className="digi-market-serve-you-content">We understand your unique business requirements and eCommerce objectives and create an eCommerce site that will enhance your business and increase your marketing opportunities.</p>
                                    <Link to="/contact" className="digi-benefits-content-button button-gradient transition-issue">Contact Us</Link>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-mob">
                                <img src={`${Config.HOST}/assets/img/e-commerce/e-commerce-img-2.png`} alt="wp-img-2" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-clients-section">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <h1 className="res-dev-client-content-subtitle mob-text-center">THE HONOUR TO OUR JOURNEY</h1>
                                    <h2 className="res-dev-client-content-title mob-text-center">Our valued <br/> clients</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 new-padd-left-add">
                                {typeof window !== 'undefined' && 
                                    <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                {typeof window !== 'undefined' &&
                                    <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-mega-container-1">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                </OwlCarousel>
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="wordpress-dev-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top E-Commerce Website Development Company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>Do you provide SEO services for my eCommerce website?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>We provide our clients with eCommerce websites that are search engine optimized and we also integrate SEO tools that you require to make sure your site is search engine-friendly.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>How much does it cost to build an eCommerce website?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>We offer our clients customized eCommerce websites and therefore the cost will vary according to your requirements and technology. <span><Link to="/contact">Contact us</Link></span> now to set up a quick discussion on your requirements and budget.  </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>How long will it take to build an eCommerce website for my business?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Again, that will depend on your requirements and the technologies needed to achieve them. But, ideally about a month’s time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>Can I approach you if my site has a problem after it is live?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Absolutely. We do provide maintenance as part of the development itself.  We also provide maintenance services for already built eCommerce sites as well.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default ECommerceWebsiteDevelopment