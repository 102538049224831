import axios from "axios"
require('dotenv').config() //For working with node

let userState = null
if (typeof localStorage !== 'undefined')
    userState = JSON.parse(localStorage.getItem('userState'));
if (typeof window == 'undefined')
    var window = {}
const Config = {
    userState: userState,
    API_URL: process.env.REACT_APP_API_URL,
    HOST: process.env.REACT_APP_HOST,
    adminRedirectIfNotLoggedIn: (props) => {
        if (Config.userState == null){
            if (typeof window == 'undefined')
                window.location.href = '/creative-login'
        }
        else if (!Config.userState.isAdminLoggedIn){
            if (typeof window == 'undefined')
                window.location.href = '/creative-login'
        }
    },
    axios: (params) => {
        let token = userState != null ? userState.token : ''
        let headers = {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json'
        }
        if (!params.headers !== null)
            headers = {...headers, ...params.header}
        if (params.auth != null) {
            if (params.auth === true)
                headers['Authorization'] = `Bearer ${token}`
        }
        let axiosObj = {
            method: params.method,
            url: params.url,
            headers: headers,
        }
        if (params.method == null)
            params['method'] = 'GET'
        if ((params?.data != null) || params?.formData != null)
            axiosObj['data'] = (params?.data != null) ? params.data : ((params?.formData != null) && params.formData)
        axios(axiosObj).then(response => {
            if (params?.success != null)
                params.success(response)
        }).catch(error => {
            if (params.error != null)
                params.error(error)
        }).finally(() => {
            if (params.finally != null)
                params.finally()
        })
    }
}
export default Config;
