import React, {Component} from 'react'
import MetaTags from 'react-meta-tags'
import {Helmet} from "react-helmet-async"


class HeadTags extends React.Component{
    constructor(props) {
        super(props)
    }    

    render() {
        switch (this.props.path){
            case 'blog/importance-of-brand-personality-and-how-to-build-one': {
                return (
                    <Helmet>
                        <title>Importance of brand personality and how to build one | Alchem Digital </title>
                        <meta data-react-helmet="true" name="title" content="Importance of brand personality and how to build one | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Your brand personality can have a major impact on how your brand is perceived by your audience. Establishing the right personality can help you enhance brand loyalty. Here’s how." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/importance-of-brand-personality-and-how-to-build-one" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/importance-of-brand-personality-and-how-to-build-one" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Importance of brand personality and how to build one | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="Your brand personality can have a major impact on how your brand is perceived by your audience. Establishing the right personality can help you enhance brand loyalty. Here’s how." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-21.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Importance of brand personality and how to build one | Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="Your brand personality can have a major impact on how your brand is perceived by your audience. Establishing the right personality can help you enhance brand loyalty. Here’s how." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-21.png" />
                    </Helmet>
                )
            }
            case 'blog/tips-to-build-your-brand-social-media-presence-in-2022': {
                return (
                    <Helmet>
                        <title> Tips to build your brand’s social media presence in 2022 | Alchem Digital  | Alchem Digital </title>
                        <meta data-react-helmet="true" name="title" content=" Tips to build your brand’s social media presence in 2022 | Alchem Digital  | Alchem Digital " />
                        <meta data-react-helmet="true" name="description" content="Building a social media presence from scratch can seem quite an extensive and complicated process. Therefore, we have here some tips on how you could build and scale your brand’s social media presence." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/tips-to-build-your-brand-social-media-presence-in-2022" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/tips-to-build-your-brand-social-media-presence-in-2022" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content=" Tips to build your brand’s social media presence in 2022 | Alchem Digital  | Dental marketing agency" />
                        <meta data-react-helmet="true" property="og:description" content="Building a social media presence from scratch can seem quite an extensive and complicated process. Therefore, we have here some tips on how you could build and scale your brand’s social media presence." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-20.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content=" Tips to build your brand’s social media presence in 2022 | Alchem Digital  | Dental marketing agency" />
                        <meta data-react-helmet="true" name="twitter:description" content="Building a social media presence from scratch can seem quite an extensive and complicated process. Therefore, we have here some tips on how you could build and scale your brand’s social media presence." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-20.png" />
                    </Helmet>
                )
                
            }
            case 'blog/digital-marketing-for-finance-companies': {
                return (
                    <Helmet>
                        <title>Digital marketing for finance companies | Alchem Digital </title>
                        <meta data-react-helmet="true" name="title" content="Digital marketing for finance companies | Alchem Digital " />
                        <meta data-react-helmet="true" name="description" content="Enhance your digital presence and customers’ trust by leveraging digital marketing techniques specifically for finance companies." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/digital-marketing-for-finance-companies" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/digital-marketing-for-finance-companies" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Digital marketing for dental clinics | Dental marketing agency" />
                        <meta data-react-helmet="true" property="og:description" content="Enhance your digital presence and customers’ trust by leveraging digital marketing techniques specifically for finance companies." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-19.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Digital marketing for dental clinics | Dental marketing agency" />
                        <meta data-react-helmet="true" name="twitter:description" content="Enhance your digital presence and customers’ trust by leveraging digital marketing techniques specifically for finance companies." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-19.png" />
                    </Helmet>
                )
                
            }
            case 'blog/digital-marketing-for-dental-clinics': {
                return (
                    <Helmet>
                        <title>Digital marketing for dental clinics | Dental marketing agency</title>
                        <meta data-react-helmet="true" name="title" content="Digital marketing for dental clinics | Dental marketing agency" />
                        <meta data-react-helmet="true" name="description" content="With the increasing dependence on the internet by almost all businesses, we discuss why digital marketing for dental clinics is crucial in today's scenario. " />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/digital-marketing-for-dental-clinics" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/digital-marketing-for-dental-clinics" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Digital marketing for dental clinics | Dental marketing agency" />
                        <meta data-react-helmet="true" property="og:description" content="With the increasing dependence on the internet by almost all businesses, we discuss why digital marketing for dental clinics is crucial in today's scenario. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-18.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Digital marketing for dental clinics | Dental marketing agency" />
                        <meta data-react-helmet="true" name="twitter:description" content="With the increasing dependence on the internet by almost all businesses, we discuss why digital marketing for dental clinics is crucial in today's scenario. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-18.png" />
                    </Helmet>
                )
                
            }
            case 'blog/new-year-social-media-campaigns-by-brands': {
                return (
                    <Helmet>
                        <title>New year campaigns for social media by brands | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="New year campaigns for social media by brands | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Stuck in a creative block this new year? Here are some campaigns that can get you some perspective this year. " />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/top-12-christmas-marketing-campaigns-and-ideas" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/top-12-christmas-marketing-campaigns-and-ideas" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="New year campaigns for social media by brands | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="Stuck in a creative block this new year? Here are some campaigns that can get you some perspective this year. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-17.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="New year campaigns for social media by brands | Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="Stuck in a creative block this new year? Here are some campaigns that can get you some perspective this year. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-17.png" />
                    </Helmet>
                )
                
            }
            case 'blog/top-12-christmas-marketing-campaigns-and-ideas': {
                return (
                    <Helmet>
                        <title>Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/top-12-christmas-marketing-campaigns-and-ideas" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/top-12-christmas-marketing-campaigns-and-ideas" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content=" Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-16.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-16.png" />
                    </Helmet>
                )
                
            }
            case 'blog/importance-of-a-responsive-website': {
                return (
                    <Helmet>
                        <title>Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/importance-of-a-responsive-website" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/importance-of-a-responsive-website" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content=" Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-15.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-15.png" />
                    </Helmet>
                )
                
            }
            case 'blog/how-to-display-icons-as-per-file-type-you-upload-in-html': {
                return (
                    <Helmet>
                        <title>How to display icons as per file type you upload in html | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="How to display icons as per file type you upload in html | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Free open source API to display dynamic file type icons based on the uploaded file in HTML." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/how-to-display-icons-as-per-file-type-you-upload-in-html" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/how-to-display-icons-as-per-file-type-you-upload-in-html" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="How to display icons as per file type you upload in html | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="Free open source API to display dynamic file type icons based on the uploaded file in HTML." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-14.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="How to display icons as per file type you upload in html | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="Free open source API to display dynamic file type icons based on the uploaded file in HTML." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-14.png" />
                    </Helmet>
                )
                
            }
            case 'blog/should-you-choose-an-omnichannel-marketing-strategy': {
                return (
                    <Helmet>
                        <title>Should you choose an omnichannel marketing strategy | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Should you choose an omnichannel marketing strategy | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <link rel="canonical" href="https://www.alchemdigital.com/blog/should-you-choose-an-omnichannel-marketing-strategy" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/should-you-choose-an-omnichannel-marketing-strategy" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Should you choose an omnichannel marketing strategy | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-13.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Should you choose an omnichannel marketing strategy | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-13.png" />
                    </Helmet>
                )
                
            }
            case 'blog/benefits-of-leveraging-digital-marketing-for-businesses': {
                return (
                    <Helmet>
                        <title>Benefits of Leveraging Digital Marketing for Businesses | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Benefits of Leveraging Digital Marketing for Businesses | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="With the digitization of the world, it only makes sense for businesses to undergo digital transformation. However, this might not be an easy task. Learn more about the challenges faced in digital transformation and how to overcome them here." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/benefits-of-leveraging-digital-marketing-for-businesses" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/benefits-of-leveraging-digital-marketing-for-businesses" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Benefits of Leveraging Digital Marketing for Businesses | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="With the digitization of the world, it only makes sense for businesses to undergo digital transformation. However, this might not be an easy task. Learn more about the challenges faced in digital transformation and how to overcome them here." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-10.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Benefits of Leveraging Digital Marketing for Businesses | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="With the digitization of the world, it only makes sense for businesses to undergo digital transformation. However, this might not be an easy task. Learn more about the challenges faced in digital transformation and how to overcome them here." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-10.png" />
                    </Helmet>
                )
                
            }
            case 'blog/5-factors-to-consider-when-choosing-a-digital-marketing-agency': {
                return (
                    <Helmet>
                        <title>5 Factors to consider when choosing a digital marketing agency | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="5 Factors to consider when choosing a digital marketing agency | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="Choosing a digital marketing agency can be quite tedious. Consider these factors before you choose a digital marketing agency for your business." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/5-factors-to-consider-when-choosing-a-digital-marketing-agency" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/5-factors-to-consider-when-choosing-a-digital-marketing-agency" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="5 Factors to consider when choosing a digital marketing agency | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content=" Choosing a digital marketing agency can be quite tedious. Consider these factors before you choose a digital marketing agency for your business." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-11.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="5 Factors to consider when choosing a digital marketing agency | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="Choosing a digital marketing agency can be quite tedious. Consider these factors before you choose a digital marketing agency for your business." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-11.png" />
                    </Helmet>
                )
                
            }
            case 'blog/content-marketing-tips-for-beginners': {
                return (
                    <Helmet>
                        <title>Content Marketing Tips for Beginners | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Content Marketing Tips for Beginners | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="A promising content marketing strategy will increase your brand awareness, drive in leads/customers, and increase your sales potential. Here is how you pull it off." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/content-marketing-tips-for-beginners" />

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/content-marketing-tips-for-beginners" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Content Marketing Tips for Beginners | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content=" A promising content marketing strategy will increase your brand awareness, drive in leads/customers, and increase your sales potential. Here is how you pull it off." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-12.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Content Marketing Tips for Beginners | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content=" A promising content marketing strategy will increase your brand awareness, drive in leads/customers, and increase your sales potential. Here is how you pull it off." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-12.png" />
                    </Helmet>
                )
                
            }
            case 'blog/benefits-of-the-ink-seo-tool': {
                return (
                    <Helmet>
                        <title>Benefits of the INK SEO tool | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Benefits of the INK SEO tool | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="Writing with the INK SEO tool increases your chance of ranking on the top of the SERP. Here, we tell you how."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/benefits-of-the-ink-seo-tool"/>

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/benefits-of-the-ink-seo-tool" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Benefits of the INK SEO tool | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content=" Writing with the INK SEO tool increases your chance of ranking on the top of the SERP. Here, we tell you how. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-9.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Benefits of the INK SEO tool | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content=" Writing with the INK SEO tool increases your chance of ranking on the top of the SERP. Here, we tell you how. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-9.png" />
                    </Helmet>
                )
                
            }
            case 'blog/how-to-write-seo-friendly-blogs': {
                return (
                    <Helmet>
                        <title>How to write SEO-friendly blogs | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="How to write SEO-friendly blogs | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="Writing SEO friendly blogs that also appeal to your readers can be quite challenging. Following these simple steps can help you optimize your blog and ultimately lead traffic to your site."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/how-to-write-seo-friendly-blogs"/>

                        {/* <!-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/how-to-write-seo-friendly-blogs" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content=" How to write SEO-friendly blogs | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="Writing SEO friendly blogs that also appeal to your readers can be quite challenging. Following these simple steps can help you optimize your blog and ultimately lead traffic to your site. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-8.png" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content=" How to write SEO-friendly blogs | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="Writing SEO friendly blogs that also appeal to your readers can be quite challenging. Following these simple steps can help you optimize your blog and ultimately lead traffic to your site. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-8.png" />
                    </Helmet>
                )
                
            }
            case 'blog/9-reasons-to-leverage-linkedin-for-b2b-marketing': {
                return (
                    <Helmet>
                        <title>9 Reasons to Leverage LinkedIn for B2B Marketing | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="9 Reasons to Leverage LinkedIn for B2B Marketing | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="LinkedIn is one of the leading professional platforms in the world and here are reasons why you need to leverage LinkedIn for B2B marketing."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/9-reasons-to-leverage-linkedin-for-b2b-marketing"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/9-reasons-to-leverage-linkedin-for-b2b-marketing"/>
                        <meta data-react-helmet="true" property="og:type" content="article"/>
                        <meta data-react-helmet="true" property="og:title" content="9 Reasons to Leverage LinkedIn for B2B Marketing | Alchem Digital"/>
                        <meta data-react-helmet="true" property="og:description" content="LinkedIn is one of the leading professional platforms in the world and here are reasons why you need to leverage LinkedIn for B2B marketing."/>
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-7.jpg"/>

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                        <meta data-react-helmet="true" name="twitter:title" content="9 Reasons to Leverage LinkedIn for B2B Marketing | Alchem Digital"/>
                        <meta data-react-helmet="true" name="twitter:description" content="LinkedIn is one of the leading professional platforms in the world and here are reasons why you need to leverage LinkedIn for B2B marketing."/>
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-7.jpg" />
                    </Helmet>
                )
                
            }
            case 'saas-development-company-in-chennai': {
                return (
                    <Helmet>
                        <title>SaaS Development Company in Chennai | SaaS Development Services</title>
                        <meta data-react-helmet="true" name="title" content="SaaS Development Company in Chennai | SaaS Development Services"/>
                        <meta data-react-helmet="true" name="description" content="Build a SaaS product with some of the best experts in the field at Alchem Digital and scale your business."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/saas-development-company-in-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/saas-development-company-in-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="SaaS Development Company in Chennai | SaaS Development Services" />
                        <meta data-react-helmet="true" property="og:description" content="Build a SaaS product with some of the best experts in the field at Alchem Digital and scale your business." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/web-application/web-app-banner-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="SaaS Development Company in Chennai | SaaS Development Services" />
                        <meta data-react-helmet="true" name="twitter:description" content="Build a SaaS product with some of the best experts in the field at Alchem Digital and scale your business." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/web-application/web-app-banner-bg.jpg" />
                    </Helmet>
                )
                break
            }
            case 'e-commerce-website-development-company-chennai': {
                return (
                    <Helmet>
                        <title>Ecommerce Website Development Company in Chennai | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Ecommerce Website Development Company in Chennai | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="We are a leading eCommerce website development company in Chennai providing high-end eCommerce solutions to businesses of every size."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/e-commerce-website-development-company-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/e-commerce-website-development-company-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Ecommerce Website Development Company in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="We are a leading eCommerce website development company in Chennai providing high-end eCommerce solutions to businesses of every size." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/e-commerce/e-commerce-img-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Ecommerce Website Development Company in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="We are a leading eCommerce website development company in Chennai providing high-end eCommerce solutions to businesses of every size." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/e-commerce/e-commerce-img-bg.jpg" />
                    </Helmet>
                )
                break
            }
            case 'web-application-development-company-chennai': {
                return (
                    <Helmet>
                        <title>Web Application Development Company in Chennai | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Web Application Development Company in Chennai | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="We are a full-service web application development company in Chennai delivering top-notch solutions for your business."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/web-application-development-company-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/web-application-development-company-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Web Application Development Company in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="We are a full-service web application development company in Chennai delivering top-notch solutions for your business." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/web-application/web-app-banner-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Web Application Development Company in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="We are a full-service web application development company in Chennai delivering top-notch solutions for your business." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/web-application/web-app-banner-bg.jpg" />
                    </Helmet>
                )
                break
            }
            case 'wordpress-development-company-chennai': {
                return (
                    <Helmet>
                        <title>WordPress Development Company in Chennai | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="WordPress Development Company in Chennai | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="We are a full-service WordPress development company delivering high-functioning and user-friendly sites in Chennai."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/wordpress-development-company-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/wordpress-development-company-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="WordPress Development Company in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="We are a full-service WordPress development company delivering high-functioning and user-friendly sites in Chennai." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/wordpress/wp-bg-img.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="WordPress Development Company in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="We are a full-service WordPress development company delivering high-functioning and user-friendly sites in Chennai." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/wordpress/wp-bg-img.jpg" />
                    </Helmet>
                )
                break
            }
            case 'branding-company-in-chennai': {
                return (
                    <Helmet>
                        <title>Branding Company in Chennai | Best Branding Services</title>
                        <meta data-react-helmet="true" name="title" content="Branding Company in Chennai | Best Branding Services"/>
                        <meta data-react-helmet="true" name="description" content="Being a top branding company in Chennai, Alchem Digital provides you with the best branding solutions for your business and help you increase your conversion potential."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/branding-company-in-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/branding-company-in-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Branding Company in Chennai | Best Branding Services" />
                        <meta data-react-helmet="true" property="og:description" content="Being a top branding company in Chennai, Alchem Digital provides you with the best branding solutions for your business and help you increase your conversion potential." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/branding/page-banner.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Branding Company in Chennai | Best Branding Services" />
                        <meta data-react-helmet="true" name="twitter:description" content="Being a top branding company in Chennai, Alchem Digital provides you with the best branding solutions for your business and help you increase your conversion potential." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/branding/page-banner.jpg" />
                    </Helmet>
                )
                break
            }
            case 'content-marketing': {
                return (
                    <Helmet>
                        <title>Content Marketing Services in Chennai | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Content Marketing Services in Chennai | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital provides you with the best content marketing services in Chennai and helps you engage and attract customers across digital platforms. "/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/content-marketing"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/content-marketing" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Content Marketing Services in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital provides you with the best content marketing services in Chennai and helps you engage and attract customers across digital platforms. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/content/content-bg.png" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Content Marketing Services in Chennai | Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital provides you with the best content marketing services in Chennai and helps you engage and attract customers across digital platforms. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/content/content-bg.png" />
                    </Helmet>
                )
                
            }
            case 'youtube-marketing': {
                return (
                    <Helmet>
                        <title>YouTube Marketing Services in Chennai | YouTube Marketing Agency</title>
                        <meta data-react-helmet="true" name="title" content="YouTube Marketing Services in Chennai | YouTube Marketing Agency"/>
                        <meta data-react-helmet="true" name="description" content="Enhance your YouTube presence with our customized YouTube marketing services at affordable prices and upgrade your YouTube advertising and management. "/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/youtube-marketing"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/youtube-marketing" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="YouTube Marketing Services in Chennai | YouTube Marketing Agency" />
                        <meta data-react-helmet="true" property="og:description" content="Enhance your YouTube presence with our customized YouTube marketing services at affordable prices and upgrade your YouTube advertising and management. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/youtube/youtube-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="YouTube Marketing Services in Chennai | YouTube Marketing Agency" />
                        <meta data-react-helmet="true" name="twitter:description" content="Enhance your YouTube presence with our customized YouTube marketing services at affordable prices and upgrade your YouTube advertising and management. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/youtube/youtube-bg.jpg" />
                    </Helmet>
                )
                
            }
            case 'ppc-services-in-chennai': {
                return (
                    <Helmet>
                        <title>SEM Company in Chennai | PPC Services in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="SEM Company in Chennai | PPC Services in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital is a leading search engine marketing/PPC company providing you optimum results at affordable prices."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/ppc-services-in-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/ppc-services-in-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="SEM Company in Chennai | PPC Services in Chennai" />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital is a leading search engine marketing/PPC company providing you optimum results at affordable prices." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/ppc/ppc-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="SEM Company in Chennai | PPC Services in Chennai" />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital is a leading search engine marketing/PPC company providing you optimum results at affordable prices." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/ppc/ppc-bg.jpg" />
                    </Helmet>
                )
                
            }
            case 'seo-company-in-chennai': {
                return (
                    <Helmet>
                        <title>SEO Company in Chennai | Best SEO Services in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="SEO Company in Chennai | Best SEO Services in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital provides you the best SEO services in Chennai at affordable prices. Our SEO strategies will drive traffic to your website and increase your revenue potential."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/seo-company-in-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/seo-company-in-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="SEO Company in Chennai | Best SEO Services in Chennai" />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital provides you the best SEO services in Chennai at affordable prices. Our SEO strategies will drive traffic to your website and increase your revenue potential." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/seo/seo-company-bg.png" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="SEO Company in Chennai | Best SEO Services in Chennai" />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital provides you the best SEO services in Chennai at affordable prices. Our SEO strategies will drive traffic to your website and increase your revenue potential." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/seo/seo-company-bg.png" />
                    </Helmet>
                )
                
            }
            case 'digital-marketing-agency-chennai': {
                return (
                    <Helmet>
                        <title>Digital Marketing Agency in Chennai - SEO, SEM, PPC, Social Media</title>
                        <meta data-react-helmet="true" name="title" content="Digital Marketing Agency in Chennai - SEO, SEM, PPC, Social Media"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital - A top-class Digital/Social media Marketing agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/digital-marketing-agency-chennai"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/digital-marketing-agency-chennai" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="Digital Marketing Agency in Chennai - SEO, SEM, PPC, Social Media " />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital - A top-class Digital/Social media Marketing agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/digital-marketing/digi-mark-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Digital Marketing Agency in Chennai - SEO, SEM, PPC, Social Media " />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital - A top-class Digital/Social media Marketing agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/digital-marketing/digi-mark-bg.jpg" />
                    </Helmet>
                )
                
            }
            case 'blog/how-to-start-a-career-in-a-digital-marketing-agency': {
                return (
                    <Helmet>
                        <title>How to Start a Career in a Digital Marketing Agency | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="How to Start a Career in a Digital Marketing Agency | Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="In this digital world, a career in a digital marketing agency can be highly rewarding and here is how you can kick start your digital marketing career."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/how-to-start-a-career-in-a-digital-marketing-agency"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/how-to-start-a-career-in-a-digital-marketing-agency" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content=" How to Start a Career in a Digital Marketing Agency | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="In this digital world, a career in a digital marketing agency can be highly rewarding and here is how you can kick start your digital marketing career." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-6.png" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content=" How to Start a Career in a Digital Marketing Agency | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="In this digital world, a career in a digital marketing agency can be highly rewarding and here is how you can kick start your digital marketing career. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-6.png" />
                    </Helmet>
                )
                
            }
            case 'how-to-start-a-career-in-a-digital-marketing-agency': {
                return (
                    <Helmet>
                        <title>How to Start a Career in a Digital Marketing Agency | Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="How to Start a Career in a Digital Marketing Agency | Alchem Digital" />
                        <meta data-react-helmet="true" name="description" content="In this digital world, a career in a digital marketing agency can be highly rewarding and here is how you can kick start your digital marketing career." />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/how-to-start-a-career-in-a-digital-marketing-agency" />

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/how-to-start-a-career-in-a-digital-marketing-agency" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content=" How to Start a Career in a Digital Marketing Agency | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="In this digital world, a career in a digital marketing agency can be highly rewarding and here is how you can kick start your digital marketing career." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-6.png" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content=" How to Start a Career in a Digital Marketing Agency | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="In this digital world, a career in a digital marketing agency can be highly rewarding and here is how you can kick start your digital marketing career. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-6.png" />
                    </Helmet>
                )
                
            }
            case 'blog/why-e-commerce-startups-need-to-leverage-telegram': {
                return (
                    <Helmet>
                        <title>Best social media marketing company/agency in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Best social media marketing company/agency in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Telegram significantly increases your networking opportunities. With over 500 million active users, Telegram has become one of the best platforms for marketing. Here’s why Telegram is more than just a messenger."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/why-e-commerce-startups-need-to-leverage-telegram"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/why-e-commerce-startups-need-to-leverage-telegram" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Why E-commerce startups need to leverage Telegram – Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="Telegram significantly increases your networking opportunities. With over 500 million active users, Telegram has become one of the best platforms for marketing. Here’s why Telegram is more than just a messenger. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-5.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Why E-commerce startups need to leverage Telegram – Alchem Digital" />
                        <meta data-react-helmet="true" name="twitter:description" content="Telegram significantly increases your networking opportunities. With over 500 million active users, Telegram has become one of the best platforms for marketing. Here’s why Telegram is more than just a messenger. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-5.jpg" />
                    </Helmet>
                )
                
            }
            case 'blog/google-ads-settings–privacy-vs-personalization': {
                return (
                    <Helmet>
                        <title>Best PPC, SMM agency in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Best PPC, SMM agency in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Digital has revolutionized and democratized pretty much everything - Education, Reporting, Entertainment, Marketing, and what not. But at the cost of what?"/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/google-ads-settings–privacy-vs-personalization"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/google-ads-settings–privacy-vs-personalization" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Google ads settings – Privacy vs Personalization | Alchem Digital " />
                        <meta data-react-helmet="true" property="og:description" content="Digital has revolutionized and democratized pretty much everything - Education, Reporting, Entertainment, Marketing, and what not. But at the cost of what? " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-4.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Google ads settings – Privacy vs Personalization | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="Digital has revolutionized and democratized pretty much everything - Education, Reporting, Entertainment, Marketing, and what not. But at the cost of what? " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-4.jpg" />
                    </Helmet>
                )
                
            }
            case 'blog/how-to-unlink-mobile-apps-and-websites-from-facebook': {
                return (
                    <Helmet>
                        <title>Best digital marketing, SMM company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Best digital marketing, SMM company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="There are multiple applications and games that provide you with an option to sign up for a new account in their platform using your Facebook data by giving you an option in the Sign Up page."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/how-to-unlink-mobile-apps-and-websites-from-facebook"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/how-to-unlink-mobile-apps-and-websites-from-facebook" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="How to unlink mobile apps from Facebook | Alchem Digital" />
                        <meta data-react-helmet="true" property="og:description" content="There are multiple applications and games that provide you with an option to sign up for a new account in their platform using your Facebook data by giving you an option in the Sign Up page." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-3.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="How to unlink mobile apps from Facebook | Alchem Digital " />
                        <meta data-react-helmet="true" name="twitter:description" content="There are multiple applications and games that provide you with an option to sign up for a new account in their platform using your Facebook data by giving you an option in the Sign Up page." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-3.jpg" />
                    </Helmet>
                )
                
            }
            case 'blog/elements-of-a-project-requirement-document': {
                return (
                    <Helmet>
                        <title>Best web application development company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Best web application development company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="It is a written document that explains the details about the product/idea and various set of deliverables from a development company/team."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/elements-of-a-project-requirement-document"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/elements-of-a-project-requirement-document" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Elements of a project requirement document | Alchem DIgital" />
                        <meta data-react-helmet="true" property="og:description" content="It is a written document that explains the details about the product/idea and various set of deliverables from a development company/team." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-2.jpg" />

                        {/* <!-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Elements of a project requirement document | Alchem DIgital" />
                        <meta data-react-helmet="true" name="twitter:description" content="It is a written document that explains the details about the product/idea and various set of deliverables from a development company/team." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-2.jpg" />
                    </Helmet>
                )
                
            }
            case 'blog/top-5-reasons-why-laravel-is-the-best-php-framework': {
                return (
                    <Helmet>
                        <title>Best Laravel Website Development company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Best Laravel Website Development company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Top 5 compelling reasons why Laravel is the best PHP framework. Alchem Digital is the best laravel website development company in Chennai"/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog/top-5-reasons-why-laravel-is-the-best-php-framework"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog/top-5-reasons-why-laravel-is-the-best-php-framework" />
                        <meta data-react-helmet="true" property="og:type" content="article" />
                        <meta data-react-helmet="true" property="og:title" content="Best Laravel Website Development company in Chennai" />
                        <meta data-react-helmet="true" property="og:description" content="Top 5 compelling reasons why Laravel is the best PHP framework. Alchem Digital is the best laravel website development company in Chennai " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-1.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Best Laravel Website Development company in Chennai" />
                        <meta data-react-helmet="true" name="twitter:description" content="Top 5 compelling reasons why Laravel is the best PHP framework. Alchem Digital is the best laravel website development company in Chennai " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/thumbnail/blog-1.jpg" />
                    </Helmet>
                )
                
            }
            case 'blog': {
                return (
                    <Helmet>
                        <title>Latest blogs on tech, social media and latest trends from top SMM company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Latest blogs on tech, social media and latest trends from top SMM company in Chennai "/>
                        <meta data-react-helmet="true" name="description" content="Latest blogs on tech, digital marketing, SMM, PPC, SEM from the best digital marketing and website development company in Chennai"/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/blog"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/blog" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="Latest blogs on tech, social media and latest trends from top SMM company in Chennai " />
                        <meta data-react-helmet="true" property="og:description" content="Latest blogs on tech, digital marketing, SMM, PPC, SEM from the best digital marketing and website development company in Chennai " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/blog/blog-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Latest blogs on tech, social media and latest trends from top SMM company in Chennai " />
                        <meta data-react-helmet="true" name="twitter:description" content="Latest blogs on tech, digital marketing, SMM, PPC, SEM from the best digital marketing and website development company in Chennai " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/blog/blog-bg.jpg" />
                    </Helmet>
                )
                
            }
            case 'responsive-website-design-development': {
                return (
                    <Helmet>
                        <title>Best Responsive website Design and Development company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Best Responsive website Design and Development company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital offers extremely dynamic and responsive website design and development services with 100% client satisfaction. We also offer SEO, SEM, PPC and SMM services"/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/responsive-website-design-development"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/responsive-website-design-development" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="Best Responsive website Design and Development company in Chennai" />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital offers extremely dynamic and responsive website design and development services with 100% client satisfaction. We also offer SEO, SEM, PPC and SMM services " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Best Responsive website Design and Development company in Chennai" />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital offers extremely dynamic and responsive website design and development services with 100% client satisfaction. We also offer SEO, SEM, PPC and SMM services " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />
                    </Helmet>
                )
                
            }
            case 'contact': {
                return (
                    <Helmet>
                        <title>Top Website Development and Digital Marketing Company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Top Website Development and Digital Marketing Company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Have a great idea or project in your mind? Great! Let's create an alchemy together. Be it Website, SEO, SEM, Social Media Marketing, Web App Development. We will call you back in 12 hrs and discuss opportunity."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/contact"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/contact" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="Top Website Development and Digital Marketing Company in Chennai" />
                        <meta data-react-helmet="true" property="og:description" content="Have a great idea or project in your mind? Great! Let's create an alchemy together. Be it Website, SEO, SEM, Social Media Marketing, Web App Development. We will call you back in 12 hrs and discuss opportunity." />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/home-bg-1.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Top Website Development and Digital Marketing Company in Chennai" />
                        <meta data-react-helmet="true" name="twitter:description" content="Have a great idea or project in your mind? Great! Let's create an alchemy together. Be it Website, SEO, SEM, Social Media Marketing, Web App Development. We will call you back in 12 hrs and discuss opportunity." />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/home-bg-1.jpg" />
                    </Helmet>
                )
                
            }
            case 'portfolio': {
                return (
                    <Helmet>
                        <title>Portfolio - Website Design, Development and Social Media Company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Portfolio - Website Design, Development and Social Media Company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="Check out Alchem Digital's impeccable works across Design, Development and Digital Marketing. We also offer SEO, SEM, PPC and SMM services"/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/portfolio"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/portfolio" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="Portfolio - Website Design, Development and Social Media Company in Chennai " />
                        <meta data-react-helmet="true" property="og:description" content="Check out Alchem Digital's impeccable works across Design, Development and Digital Marketing. We also offer SEO, SEM, PPC and SMM services " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Portfolio - Website Design, Development and Social Media Company in Chennai " />
                        <meta data-react-helmet="true" name="twitter:description" content="Check out Alchem Digital's impeccable works across Design, Development and Digital Marketing. We also offer SEO, SEM, PPC and SMM services " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />
                    </Helmet>
                )
                
            }
            case 'services': {
                return (
                    <Helmet>
                        <title>Website Design, Development and Social Media Company in Chennai</title>
                        <meta data-react-helmet="true" name="title" content="Website Design, Development and Social Media Company in Chennai"/>
                        <meta data-react-helmet="true" name="description" content="We offer best Graphic Design, Web Application/SaaS Development, Branding and Digital Marketing services, at an affordable price for clients globally."/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/services"/>

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/services" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="Website Design, Development and Social Media Company in Chennai  " />
                        <meta data-react-helmet="true" property="og:description" content="We offer best Graphic Design, Web Application/SaaS Development, Branding and Digital Marketing services, at an affordable price for clients globally. " />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/services-bg.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="Website Design, Development and Social Media Company in Chennai  " />
                        <meta data-react-helmet="true" name="twitter:description" content="We offer best Graphic Design, Web Application/SaaS Development, Branding and Digital Marketing services, at an affordable price for clients globally. " />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/services-bg.jpg" />
                    </Helmet>
                )
                
            }
            case 'about': {
                return (
                    <Helmet>
                        <title>About Us | Web App Design, Development and Digital Marketing</title>
                        <meta data-react-helmet="true" name="title" content="About Us | Web App Design, Development and Digital Marketing"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital was initiated and built by a set of fervent young folks, with solid experience in the field of Design, Web Development and Digital Marketing"/>
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com/about"/>
                        {/* <!-- Google / Search Engine Tags --> */}
                        <meta data-react-helmet="true" itemprop="name" content="About us Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital" />
                        <meta data-react-helmet="true" itemprop="description" content="About us Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world." />
                        <meta data-react-helmet="true" itemprop="image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />/

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com/about" />
                        <meta data-react-helmet="true" property="og:type" content="website" />
                        <meta data-react-helmet="true" property="og:title" content="About Us | Web App Design, Development and Digital Marketing " />
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital was initiated and built by a set of fervent young folks, with solid experience in the field of Design, Web Development and Digital Marketing" />
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                        <meta data-react-helmet="true" name="twitter:title" content="About Us | Web App Design, Development and Digital Marketing " />
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital was initiated and built by a set of fervent young folks, with solid experience in the field of Design, Web Development and Digital Marketing" />
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/home-bg-2.jpg" />
                    </Helmet>
                )
                
            }
            default: {
                return (
                    <Helmet>
                        <title>Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital</title>
                        <meta data-react-helmet="true" name="title" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital"/>
                        <meta data-react-helmet="true" name="description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world."/>
                        <meta data-react-helmet="true" name="keywords" content="social media marketing, web application development, saas development, digital marketing agency, website design company, digital marketing company, SEO company, graphic designing company, paid media marketing, PPC agency" />
                        <link data-react-helmet="true" rel="canonical" href="https://www.alchemdigital.com"/>
                        {/* <!-- Google / Search Engine Tags --> */}
                        <meta data-react-helmet="true" itemprop="name" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital"/>
                        <meta data-react-helmet="true" itemprop="description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world."/>
                        <meta data-react-helmet="true" itemprop="image" content="https://www.alchemdigital.com/assets/img/home-bg-1.jpg"/>/

                        {/* < !-- Facebook Meta Tags --> */}
                        <meta data-react-helmet="true" property="og:url" content="https://www.alchemdigital.com"/>
                        <meta data-react-helmet="true" property="og:type" content="website"/>
                        <meta data-react-helmet="true" property="og:title" content=" Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital "/>
                        <meta data-react-helmet="true" property="og:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world."/>
                        <meta data-react-helmet="true" property="og:image" content="https://www.alchemdigital.com/assets/img/home-bg-1.jpg"/>

                        {/* < !-- Twitter Meta Tags --> */}
                        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image"/>
                        <meta data-react-helmet="true" name="twitter:title" content="Digital Marketing Agency - SEO, SEM, Social Media | Website Design & Development Company in Chennai, Evanston, Chicago, USA - Alchem Digital"/>
                        <meta data-react-helmet="true" name="twitter:description" content="Alchem Digital - A top-class Digital Marketing and Web design & development agency specialized in SEO, SEM, PPC, SMM based in Chennai, Evanston, Chicago, USA, serving clients across the world."/>
                        <meta data-react-helmet="true" name="twitter:image" content="https://www.alchemdigital.com/assets/img/home-bg-1.jpg"/>
                    </Helmet>
                )
                
            }
        }
    }
}

export default HeadTags