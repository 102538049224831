import 'react-loading-skeleton/dist/skeleton.css'
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import Config from './Config'
import Skeleton from 'react-loading-skeleton'

class BlogSidebar extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            featuredPosts: []
        }
    }

    componentDidMount = () => {
        this.getFeaturedPosts()
    }

    getFeaturedPosts = () => {
        Config.axios({
            url: `${Config.API_URL}/api/user/post/list`,
            success: (res) => {
                // console.log(res.data.results)
                this.setState({
                    featuredPosts: res.data.results.slice(0, 3)
                }, () => {
                    // console.log(this.state.featuredPosts)
                })
            },
        })
    }

    render(){
        return(
            <React.Fragment>
                {/* <form className="search-form" action="#">
                    <div class="input-group">
                        <input class="ad-search-form-control" type="search" placeholder="Search..." />
                        <span class="input-group-append">
                            <button class="search-btn" type="button">
                                <i class="fa fa-search"></i>
                            </button>
                        </span>
                    </div>
                </form> */}
                {/* <div className="blog-cate-section">
                    <p className="blog-cate-title">Categories</p>
                    <ul>
                        <li><a href="#">Film Maker</a></li>
                        <li><a href="#">Film Studio</a></li>
                        <li><a href="#">HaruTheme</a></li>
                        <li><a href="#">Interviews</a></li>
                        <li><a href="#">LifeStyle</a></li>
                    </ul>
                </div> */}
                <div className="blog-featured-post">
                    <p className="blog-cate-title">Featured Posts</p>
                    <ul>
                        {
                            this.state.featuredPosts.length > 0 ? (
                                this.state.featuredPosts.map((blog, key) => (
                                    <li className="featured-posts" key={key}>
                                        <div className="featured-posts-img">
                                            <Link to={'/blog/' + blog.slug}><img className="ad-blog-thumbnail-img" src={blog.banner_image} alt={blog.title} /></Link>
                                        </div>
                                        <div className="featured-posts-content">
                                            <Link to={'/blog/' + blog.slug} className="blog-title">{blog.title}</Link>
                                            <p className="blog-time-date">{new Date(blog.created_at).toLocaleString('en-us', { month: 'short' }) + ' ' + new Date(blog.created_at).getDate() + ', ' + new Date(blog.created_at).getFullYear()}</p>
                                        </div>
                                    </li>
                                ))
                            )
                            :
                            (
                                <>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <Skeleton height={'73px'} width={'73px'} />
                                        </div>
                                        <div className="col">
                                            <Skeleton count={3} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <Skeleton height={'73px'} width={'73px'} />
                                        </div>
                                        <div className="col">
                                            <Skeleton count={3} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <Skeleton height={'73px'} width={'73px'} />
                                        </div>
                                        <div className="col">
                                            <Skeleton count={3} />
                                        </div>
                                    </div>
                                </>
                            )
                            
                        }
                    </ul>
                </div>
                {/* <div className="blog-tag-post">
                    <p className="blog-cate-title">Tags</p>
                    <div className="tags">
                        <a className="tag-links" href="#">Actor</a>
                        <a className="tag-links" href="#">Director</a>
                        <a className="tag-links" href="#">Film Maker</a>
                        <a className="tag-links" href="#">Film Online</a>
                        <a className="tag-links" href="#">Film Studio</a>
                    </div>
                </div> */}
            </React.Fragment>
        );
    }
}

export default BlogSidebar