import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class SearchEngineMarketing extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }

    PPCProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Understanding Your PPC Goals",
                "activeImg": "ppc-process-white-1.svg",
                "img" : "ppc-process-black-1.svg",
                "description" : "In the initial stage, we understand what you are trying to achieve through your PPC campaign and who your target audience is. Once we understand your business and campaign goals (while also considering your budget), we begin working on your PPC campaign."
            },
            {
                "id" : 2,
                "title" : "Competitor analysis",
                "activeImg": "ppc-process-white-2.svg",
                "img" : "ppc-process-black-2.svg",
                "description" : "Before we create or launch any campaign, we make sure we conduct thorough competitor research and understand who your competitors are, what they are doing, and analyze what we can do better."
            },
            {
                "id" : 3,
                "title" : "Keyword Research",
                "activeImg": "ppc-process-white-3.svg",
                "img" : "ppc-process-black-3.svg",
                "description" : "Keyword research is crucial for a PPC campaign that actually converts. Using the right keywords can offer you a better return on ad spend (ROAS). Keyword research can also help you understand your target audience, and fulfill their search intent by using the right keywords in your PPC campaign."
            },
            {
                "id" : 4,
                "title" : "Creating PPC Campaign",
                "activeImg": "ppc-process-white-4.svg",
                "img" : "ppc-process-black-4.svg",
                "description" : "Once we understand your target audience and do the necessary keyword research, we create content for your SEM/PPC campaign(s). We create an ad copy that is creative and relevant for your business along with a strong CTA that will drive your audience to make a valuable action through your advertisement."
            },
            {
                "id" : 5,
                "title" : "Campaign Launch",
                "activeImg": "ppc-process-white-5.svg",
                "img" : "ppc-process-black-5.svg",
                "description" : "On having a kickass campaign idea and landing page, we work on launching it. We set the maximum bid costs and also set the ad spend limits for your advertisements."
            },
            {
                "id" : 6,
                "title" : "Campaign Optimization & Management",
                "activeImg": "ppc-process-white-6.svg",
                "img" : "ppc-process-black-6.svg",
                "description" : "Once your campaign is launched, we constantly optimize it to bring you the best results. We track and monitor your ads and make necessary changes to your advertisements so that your investment and our efforts do not go in vain."
            }
        ]

        toggle = (e, tab) => {
            if (this.state.activeTab !== tab) {
                this.setState({
                    activeTab: tab
                });
                setInterval(5000, this.intervalRef.current);
            }
        }


    componentDidMount(){
        // $('.nav-tabs-dropdown')
        // .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        // })
        // .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        // });

        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.seo-marketing > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.seo-marketing > li').length
        //     ? 
        //     active.parent().next('.seo-marketing > li').find('a') 
        //     : 
        //     tabs.parent().filter('.seo-marketing > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.seo-marketing a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.6;
        var $bg_images = $(".res-dev-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.PPCProcessContent.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };
    
    render(){
        return(
            <React.Fragment>
                <HeadTags path='ppc-services-in-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="ppc-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">Search Engine Marketing PPC Services in Chennai </h1>
                                        <h2 className="digi-mark-main-banner-subtitle">Reach your target audience efficiently and get quality leads by leveraging the best SEM/PPC services in Chennai.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/ppc/ppc-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">What is SEM & PPC?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>PPC or pay-per-click advertisements let you run ads on various platforms and payment can be done only when your ads are clicked on by a user. PPC advertisements influence your audience to view your ad, click on it, and ultimately make an action, like making a purchase.</p>
                                        <p>PPC/SEM is one of the most popular ways to drive traffic to your website and have better conversion rates. PPC is an ideal way to reach a new audience and enhance your digital presence.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container-2">
                                    <h1 className="digi-market-zig-zag-title">How Does SEM & PPC Marketing Work?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>Search engines, in order to gather information on the relevancy of an advertisement that appears on the search engine result page (SERP), have an ‘ad auction’. Here is where we bid on relevant keywords or search terms for which you want to display your website and/or products.</p>
                                        <p>A bid is set once we have the keywords and an ad set up on the search platform. Multiple factors like your bid price, relevancy, CTR (click-through-rate), demographics, and more are taken into consideration to get your website in the top position in the SERP.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/ppc/ppc-2.jpg`} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Advantages of Leveraging SEM/PPC Services</h1>
                        <div className="digi-benefits-align">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Reach Your Target Audience</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">It is Trackable</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Better Value for Money</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Brand Awareness</a>
                                <a className="nav-link" id="b-save-time-tab" data-toggle="pill" href="#save-time-tab" role="tab" aria-controls="save-time-tab" aria-selected="false">Saves Time</a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/ppc/adv-ppc-1.svg`} alt="adv-ppc"/>
                                                <h2 className="digi-market-title-benefit">Reach Your Target Audience</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">PPC gives you a chance to reach your prospective customers efficiently. Competent keyword research can tell you what your prospects are searching for and make a bid on keywords that you know will drive customers to click on your advertisement. SEM/PPC advertising lets you target your ads to your audience based on various metrics like age, interests, demographics, etc.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/ppc/adv-ppc-2.svg`} alt="adv-ppc"/>
                                                <h2 className="digi-market-title-benefit">It is Trackable</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">One of the key benefits of PPC advertising is that it is measurable. SEM/PPC advertising lets you know how effective your campaign really is through clicks and conversions. By leveraging PPC advertising, you can track your PPC campaign giving you a chance to upgrade them and scale your results easily.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/ppc/adv-ppc-3.svg`} alt="adv-ppc"/>
                                                <h2 className="digi-market-title-benefit">Better Value for Money</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">While most advertising lets you pay for a spot, irrespective of its reach, with PPC advertising, you can, well, pay-per-click. SEM/PPC advertising lets you pay only when your ad is clicked on, where there is a chance for conversion. And what’s even better, you have complete control over your budget as you can decide how much you want to spend on a campaign well in advance.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/ppc/adv-ppc-4.svg`} alt="adv-ppc"/>
                                                <h2 className="digi-market-title-benefit">Brand Awareness</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">PPC advertising can help in increasing your brand’s awareness as your ads can reach a new audience and introduce them to your products and services. SEM/PPC ads are mostly placed where your target audience is at making it easy for them to come across your brand more often making it memorable.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="save-time-tab" role="tabpanel" aria-labelledby="b-save-time-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/ppc/adv-ppc-5.svg`} alt="adv-ppc"/>
                                                <h2 className="digi-market-title-benefit">Saves Time</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">PPC ads do not typically depend on the SEO ranking of your website. Leveraging PPC ads gives you results quickly as it does not typically depend on the organic results of your website. However, SEO results are important for your brand and therefore we do not recommend you halting your SEO efforts while running SEM/PPC campaigns. Though PPC can accelerate your results, you cannot solely depend on them.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Our PPC Services</h1>
                        <div className="res-dev-key-features-container digi-market-mega-cont">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-serve-1.svg`} alt="ppc-serve"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Search Ads</h2>
                                    <p className="res-dev-key-feature-excerpt">Search Ads are one of the most popular forms of PPC advertisements where you can place your ads on the organic search results for certain keywords. Search ads drive your prospects to click on your advertisements with just a headline and a description.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-serve-2.svg`} alt="ppc-serve"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Display Ads</h2>
                                    <p className="res-dev-key-feature-excerpt">Display ads are a form of paid advertisement that usually takes the form of a text, image, or video that can be displayed on social media platforms, publishers, websites, etc. Display ads can be targeted and visually pleasing, allowing you to effectively convey your message to your audience. Display ads can also be one of the best ways to increase your brand awareness and be beneficial for re-targeting campaigns.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-serve-3.svg`} alt="ppc-serve"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Social Media Ads</h2>
                                    <p className="res-dev-key-feature-excerpt">Social media ads are a form of digital marketing where you can place your ads across various social media platforms which are targeted to the audience based on user demographics which will typically appear on a user’s feed. Social media ad services that we offer.</p>
                                    <ul className="ppc-serve-list">
                                        <li>LinkedIn Ads</li>
                                        <li>Facebook Ads</li>
                                        <li>Instagram Ads</li>
                                        <li>Twitter Ads</li>
                                        <li>Quora Ads</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-serve-4.svg`} alt="ppc-serve"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Remarketing Ads</h2>
                                    <p className="res-dev-key-feature-excerpt">Remarketing or retargeting ads let you show targeted ads to people who have previously associated with your website or ads. Remarketing ads make your brand more memorable for your audience and also provides you with better conversion rates.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-serve-5.svg`} alt="ppc-serve"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Google Shopping</h2>
                                    <p className="res-dev-key-feature-excerpt">Google Shopping offers you increased visibility for your products and services. Google Shopping ads offer you an upper hand since these ads are usually more visually attractive when compared to search ads. Your audience is provided with enough information about your products before even they visit your website and therefore offering you better sales conversions.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove digi-market-services-last-align">
                                <div className="res-dev-key-feature-sections">
                                    <h2 className="digi-market-services-last-box-content">What PPC services are you looking for?</h2>
                                    <Link to='/contact' className="digi-market-content-button button-gradient">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="seo-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">SEO Process</h1>
                        <div className="ppc-content-cont">
                            {/* <ul className="nav nav-pills seo-marketing mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-ppc-content-1-tab" data-toggle="pill" href="#pills-ppc-content-1" role="tab" aria-controls="pills-ppc-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Understanding Your PPC Goals</h4>
                                        <div className="seo-process-circles">
                                            <div className="ppc-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Understanding Your PPC Goals</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-ppc-content-2-tab" data-toggle="pill" href="#pills-ppc-content-2" role="tab" aria-controls="pills-ppc-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Competitor analysis</h4>
                                        <div className="seo-process-circles">
                                            <div className="ppc-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Competitor analysis</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-ppc-content-3-tab" data-toggle="pill" href="#pills-ppc-content-3" role="tab" aria-controls="pills-ppc-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <div className="ppc-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Keyword Research</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-ppc-content-4-tab" data-toggle="pill" href="#pills-ppc-content-4" role="tab" aria-controls="pills-ppc-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Creating PPC Campaign</h4> 
                                        <div className="seo-process-circles">
                                            <div className="ppc-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Creating PPC Campaign</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-ppc-content-5-tab" data-toggle="pill" href="#pills-ppc-content-5" role="tab" aria-controls="pills-ppc-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Campaign Launch</h4>
                                        <div className="seo-process-circles">
                                            <div className="ppc-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Campaign Launch</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-ppc-content-6-tab" data-toggle="pill" href="#pills-ppc-content-6" role="tab" aria-controls="pills-ppc-content-6" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Campaign Optimization & Management</h4>
                                        <div className="seo-process-circles">
                                            <div className="ppc-process-img-6"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Campaign Optimization & Management</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active active-anim" id="pills-ppc-content-1" role="tabpanel" aria-labelledby="pills-ppc-content-1-tab">
                                    <div className="seo-process-content">
                                        <h3>Understanding Your PPC Goals</h3>
                                        <p>In the initial stage, we understand what you are trying to achieve through your PPC campaign and who your target audience is. Once we understand your business and campaign goals (while also considering your budget), we begin working on your PPC campaign.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-ppc-content-2" role="tabpanel" aria-labelledby="pills-ppc-content-2-tab">
                                    <div className="seo-process-content">
                                        <h3>Competitor analysis</h3>
                                        <p>Before we create or launch any campaign, we make sure we conduct thorough competitor research and understand who your competitors are, what they are doing, and analyze what we can do better.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-ppc-content-3" role="tabpanel" aria-labelledby="pills-ppc-content-3-tab">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>Keyword research is crucial for a PPC campaign that actually converts. Using the right keywords can offer you a better return on ad spend (ROAS). Keyword research can also help you understand your target audience, and fulfill their search intent by using the right keywords in your PPC campaign.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-ppc-content-4" role="tabpanel" aria-labelledby="pills-ppc-content-4-tab">
                                    <div className="seo-process-content">
                                        <h3>Creating PPC Campaign</h3>
                                        <p>Once we understand your target audience and do the necessary keyword research, we create content for your SEM/PPC campaign(s). We create an ad copy that is creative and relevant for your business along with a strong CTA that will drive your audience to make a valuable action through your advertisement.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-ppc-content-5" role="tabpanel" aria-labelledby="pills-ppc-content-5-tab">
                                    <div className="seo-process-content">
                                        <h3>Campaign Launch</h3>
                                        <p>On having a kickass campaign idea and landing page, we work on launching it. We set the maximum bid costs and also set the ad spend limits for your advertisements.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-ppc-content-6" role="tabpanel" aria-labelledby="pills-ppc-content-6-tab">
                                    <div className="seo-process-content">
                                        <h3>Campaign Optimization & Management</h3>
                                        <p>Once your campaign is launched, we constantly optimize it to bring you the best results. We track and monitor your ads and make necessary changes to your advertisements so that your investment and our efforts do not go in vain.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Understanding Your PPC Goals</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '1' ? `${Config.HOST}/assets/img/ppc/ppc-process-white-1.svg` : `${Config.HOST}/assets/img/ppc/ppc-process-black-1.svg`}  alt="ppc-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Understanding Your PPC Goals</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <h4 className="padd-bottom-12">Competitor analysis</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '2' ? `${Config.HOST}/assets/img/ppc/ppc-process-white-2.svg` : `${Config.HOST}/assets/img/ppc/ppc-process-black-2.svg`}  alt="ppc-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Competitor analysis</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '3' ? `${Config.HOST}/assets/img/ppc/ppc-process-white-3.svg` : `${Config.HOST}/assets/img/ppc/ppc-process-black-3.svg`}  alt="ppc-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Keyword Research</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <h4 className="padd-bottom-12">Creating PPC Campaign</h4> 
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '4' ? `${Config.HOST}/assets/img/ppc/ppc-process-white-4.svg` : `${Config.HOST}/assets/img/ppc/ppc-process-black-4.svg`}  alt="ppc-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Creating PPC Campaign</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Campaign Launch</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '5' ? `${Config.HOST}/assets/img/ppc/ppc-process-white-5.svg` : `${Config.HOST}/assets/img/ppc/ppc-process-black-5.svg`}  alt="ppc-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12">Campaign Launch</h4>                            
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>
                                        <h4 className="padd-bottom-12">Campaign Optimization & Management</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '6' ? `${Config.HOST}/assets/img/ppc/ppc-process-white-6.svg` : `${Config.HOST}/assets/img/ppc/ppc-process-black-6.svg`}  alt="ppc-process"/>    
                                        </div>   
                                        <h4 className="padd-top-12 opacity-0">Campaign Optimization & Management</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="seo-process-content">
                                        <h3>Understanding Your PPC Goals</h3>
                                        <p>In the initial stage, we understand what you are trying to achieve through your PPC campaign and who your target audience is. Once we understand your business and campaign goals (while also considering your budget), we begin working on your PPC campaign.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="seo-process-content">
                                        <h3>Competitor analysis</h3>
                                        <p>Before we create or launch any campaign, we make sure we conduct thorough competitor research and understand who your competitors are, what they are doing, and analyze what we can do better.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>Keyword research is crucial for a PPC campaign that actually converts. Using the right keywords can offer you a better return on ad spend (ROAS). Keyword research can also help you understand your target audience, and fulfill their search intent by using the right keywords in your PPC campaign.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="seo-process-content">
                                        <h3>Creating PPC Campaign</h3>
                                        <p>Once we understand your target audience and do the necessary keyword research, we create content for your SEM/PPC campaign(s). We create an ad copy that is creative and relevant for your business along with a strong CTA that will drive your audience to make a valuable action through your advertisement.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="seo-process-content">
                                        <h3>Campaign Launch</h3>
                                        <p>On having a kickass campaign idea and landing page, we work on launching it. We set the maximum bid costs and also set the ad spend limits for your advertisements.  </p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="6">
                                    <div className="seo-process-content">
                                        <h3>Campaign Optimization & Management</h3>
                                        <p>Once your campaign is launched, we constantly optimize it to bring you the best results. We track and monitor your ads and make necessary changes to your advertisements so that your investment and our efforts do not go in vain.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}
                            <Nav tabs>
                                {
                                    this.PPCProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={this.state.activeTab >= item.id ? `${Config.HOST}/assets/img/ppc/`+item.activeImg : `${Config.HOST}/assets/img/ppc/`+item.img}  alt="e-com-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.PPCProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-process-black-1.svg`} alt="seo-process"/>
                                    <h4>Understanding Your PPC Goals</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>In the initial stage, we understand what you are trying to achieve through your PPC campaign and who your target audience is. Once we understand your business and campaign goals (while also considering your budget), we begin working on your PPC campaign.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-process-black-2.svg`} alt="seo-process"/>
                                    <h4>Competitor analysis</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Before we create or launch any campaign, we make sure we conduct thorough competitor research and understand who your competitors are, what they are doing, and analyze what we can do better.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-process-black-3.svg`} alt="seo-process"/>
                                    <h4>Keyword Research</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Keyword research is crucial for a PPC campaign that actually converts. Using the right keywords can offer you a better return on ad spend (ROAS). Keyword research can also help you understand your target audience, and fulfill their search intent by using the right keywords in your PPC campaign.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-process-black-4.svg`} alt="seo-process"/>
                                    <h4>Creating PPC Campaign</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we understand your target audience and do the necessary keyword research, we create content for your SEM/PPC campaign(s). We create an ad copy that is creative and relevant for your business along with a strong CTA that will drive your audience to make a valuable action through your advertisement.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-process-black-5.svg`} alt="seo-process"/>
                                    <h4>Campaign Launch</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>On having a kickass campaign idea and landing page, we work on launching it. We set the maximum bid costs and also set the ad spend limits for your advertisements.  </p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/ppc/ppc-process-black-6.svg`} alt="seo-process"/>
                                    <h4>Campaign Optimization & Management</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once your campaign is launched, we constantly optimize it to bring you the best results. We track and monitor your ads and make necessary changes to your advertisements so that your investment and our efforts do not go in vain.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/ppc/ppc-3.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">Why Alchem Digital is the Best SEM & PPC Company in Chennai?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>At Alchem Digital, we understand your business goals and pay attention to what you want to achieve through your SEM/PPC efforts and develop a PPC/SEM strategy accordingly. Through our thorough research, we decipher who your competitions are, your target audience and what they are looking for, and more to get you the full benefits of SEM/PPC. We continuously test, track, and improve your advertisements to get you the best results possible. Being one of the leading SEM/PPC agency, we ensure that our SEM/PPC services efforts give you a better return on your investment and help you achieve your business and PPC/SEM goals.</p>
                                    </div>
                                    <Link to="/portfolio" className="res-dev-call-to-action-button button-gradient transition-issue mt-2" >Our Works</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="res-dev-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Want to reach your customers at a faster pace?</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#digi-market-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Ping us now</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-technologies">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Facebook Ads Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Twitter Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">LinkedIn Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Google Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Google Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-6">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Tag Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-7">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Taboola</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-8">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Hootsuite</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-9">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Trello</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-10">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Creator Studio</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top SEM & PPC Company company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                                <span>How can PPC services help my business? </span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>One of the most captivating benefits of SEM/PPC is that it offers you real time data, letting you know how well your campaigns are performing and make changes on the go. PPC services can help you increase brand awareness and reach your target audience easily.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>Are PPC ads costly?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Not really. The best thing about PPC ads is that you are in control. PPC ads let you pay when your ad is clicked on and you get to decide your maximum budget on PPC ad spend, therefore, giving you the best value for your investment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>Where do you advertise PPC ads?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>SEM/PPC ads can be seen in search results, social media, etc.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>Do people really click PPC ads?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">   
                                            <p>Yes. People do click on PPC ads since they are relevant and useful enough to satisfy their search intent.</p>                                     
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSix">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSix" aria-expanded="false" aria-controls="newcollapseSix">
                                                <span>What makes your PPC services unique?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSix" className="collapse" aria-labelledby="newheadingSix" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>At Alchem Digital, we understand your business goals and pay attention to what you are trying to accomplish through your PPC efforts. We make sure we give you the best possible return on your investment by optimizing your PPC campaign effectively.  </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSeven">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSeven" aria-expanded="false" aria-controls="newcollapseSeven">
                                                <span>Will PPC suit for my business?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSeven" className="collapse" aria-labelledby="newheadingSeven" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>PPC suits for almost most businesses. PPC can be beneficial for your business if people might search for your products and services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingEight">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseEight" aria-expanded="false" aria-controls="newcollapseEight">
                                                <span>How long will it take for PPC to work for me?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseEight" className="collapse" aria-labelledby="newheadingEight" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Once we launch your campaign, we regularly track your ad performance to see how well it is doing. A PPC ad campaign can take around a few days or weeks for it to work and for you to see some beneficial results.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default SearchEngineMarketing