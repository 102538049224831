//import "owl.carousel/dist/assets/owl.carousel.css"
//import "owl.carousel/dist/assets/owl.theme.default.css"

import $ from 'jquery';
import React, {Component} from 'react'
//import OwlCarousel from "react-owl-carousel"
import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import BlogSidebar from './BlogSidebar'


// import PageTransition from '../PageTransition'
class BlogSix extends React.Component{
    constructor(props) {
        super(props)
        if (typeof window != 'undefined')
            window.location.href = '/blog/how-to-start-a-career-in-a-digital-marketing-agency'
    }
    
    render(){
         return (<div></div>)        	
    }
}

export default BlogSix
