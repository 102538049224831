import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import React, { Component } from 'react'
import Config from './Config'
import { Link } from 'react-router-dom'
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

export default class BlogCarousel extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            blogs: [],
        }
    }

    componentDidMount = () => {
        Config.axios({
            url: `${Config.API_URL}/api/user/post/list`,
            success: (res) => {
                this.setState({
                    blogs: res.data.results,
                })
            },
        })

    }

    
    render() {
        const optionsBlog = {
            items: 2,
            margin: 30,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: true,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: false,
            smartSpeed: 800,
            responsive: {
                0: {
                    items: 1
                },
                450: {
                    items: 2
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 2
                }
            }
        };
        return (
            <>
                {
                    this.state.blogs.length && (
                        typeof window != 'undefined' &&
                            <OwlCarousel className="post-related-carousel" {...optionsBlog} >
                                    {
                                        this.state.blogs.map((blog, key) => (
                                                <>
                                                    <div className="related-post-wrapper home-blog-wrapper">
                                                        <div className="blog-post-img-wrapper">
                                                            <div className="home-blog-related-post-image">
                                                                <img src={blog.banner_image} alt={blog.title} />
                                                            </div>
                                                        </div>
                                                        <div className="home-blog-post-meta">
                                                            <div className="home-blog-content-section-left">
                                                                
                                                                <p className="home-blog-num">{(key+1) < 10 && '0'}{key+1}</p>
                                                            </div>
                                                            <div className="home-blog-content-section-right">
                                                                <Link className="home-blog-post-title" to={'/blog/' + blog.slug}>{blog.title}</Link>
                                                                <p>{new Date(blog.created_at).toLocaleString('en-us', { month: 'short' }) + ' ' + new Date(blog.created_at).getDate() + ', ' + new Date(blog.created_at).getFullYear()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        )
                                    }
                            </OwlCarousel>
                    )
                }
            </>
        )
    }
}
