import "aos/dist/aos.css"

import AOS from 'aos'
import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router, Route, Switch, useHistory, useLocation, Redirect} from 'react-router-dom'
import Home from './Home'
import Footer from './layout/Footer'
import About from './pages/About'
import Addblog from './pages/Addblog'
import Admin from './pages/Admin'
import Blog from './pages/Blog'
// import BlogFive from './pages/BlogFive'
// import BlogFour from './pages/BlogFour'
// import BlogOne from './pages/BlogOne'
// import BlogSeven from "./pages/BlogSeven"
// import BlogEight from "./pages/BlogEight"
// import BlogNine from "./pages/BlogNine"
// import BlogSingle from './pages/BlogSingle'
import BlogSix from './pages/BlogSix'
// import BlogThree from './pages/BlogThree'
// import BlogTwo from './pages/BlogTwo'
import Contact from './pages/Contact'
import CreativeLogin from './pages/CreativeLogin'
import DigitalMarketing from './pages/DigitalMarketing'
import EditBlog from './pages/EditBlog'
import NotFound from './pages/NotFound'
import Portfolio from './pages/Portfolio'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ResponsiveWebsiteDesign from './pages/ResponsiveWebsiteDesign'
import Services from './pages/Services'
import ScrollToTop from './ScrollToTop'
import BlogContent from './pages/BlogContent'
import MailSuccess from './pages/MailSuccess'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import SearchEngineOptimization from "./pages/seo-pages/SearchEngineOptimization"
import SearchEngineMarketing from "./pages/seo-pages/SearchEngineMarketing"
import YouTubeMarketing from "./pages/seo-pages/YouTubeMarketing"
import ContentMarketing from "./pages/seo-pages/ContentMarketing"
import Branding from "./pages/seo-pages/Branding"
import WordpressDevelopment from "./pages/seo-pages/WordpressDevelopement"
import WebApplicationDevelopement from "./pages/seo-pages/WebApplicationDevelopment"
import ECommerceWebsiteDevelopment from "./pages/seo-pages/ECommerceWebsiteDevelopment"
import SaasDevelopment from "./pages/seo-pages/SaasDevelopment"

function App() {

    useEffect(() => {
        AOS.init({
            duration : 1200,
            delay: 200,
            once: true
        });

        var Tawk_API=Tawk_API||{};
        (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5fcb3217920fc91564cdaa78/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
        })();
        
    }, []);


    const location = useLocation();

    return (
        <div>
            <ScrollToTop />
                <Switch location={location} key={location.pathname}>
                    <Route exact path='/' component={Home} />
                    <Route path='/about' component={About} />
                    <Route path='/services' component={Services} />
                    <Route exact path='/blog' component={Blog} />
                    {/* <Route path='/top-5-reasons-why-laravel-is-the-best-php-framework' component={BlogOne} />
                    <Route path='/elements-of-a-project-requirement-document' component={BlogTwo} />
                    <Route path='/how-to-unlink-mobile-apps-and-websites-from-facebook' component={BlogThree} />
                    <Route path='/google-ads-settings–privacy-vs-personalization' component={BlogFour} />
                    <Route path='/why-e-commerce-startups-need-to-leverage-telegram' component={BlogFive} /> */}
                    <Route path='/how-to-start-a-career-in-a-digital-marketing-agency' component={BlogSix} />
                    {/* <Route path='/9-reasons-to-leverage-linkedin-for-b2b-marketing' component={BlogSeven} />
                    <Route path='/how-to-write-seo-friendly-blogs' component={BlogEight} />
                    <Route path='/benefits-of-the-ink-seo-tool' component={BlogNine} /> */}
                    <Route path='/contact' component={Contact} />
                    <Route path='/portfolio' component={Portfolio} />
                    <Route path='/responsive-website-design-development' component={ResponsiveWebsiteDesign} />
                    <Route path='/digital-marketing-agency-chennai' component={DigitalMarketing} />
                    <Route path='/seo-company-in-chennai' component={SearchEngineOptimization} />
                    <Route path='/ppc-services-in-chennai' component={SearchEngineMarketing} />
                    <Route path='/youtube-marketing' component={YouTubeMarketing} />
                    <Route path='/content-marketing' component={ContentMarketing} />
                    <Route path='/branding-company-in-chennai' component={Branding} />
                    <Route path='/wordpress-development-company-chennai' component={WordpressDevelopment} />
                    <Route path='/web-application-development-company-chennai' component={WebApplicationDevelopement} />
                    <Route path='/e-commerce-website-development-company-chennai' component={ECommerceWebsiteDevelopment}/>
                    <Route path='/saas-development-company-in-chennai' component={SaasDevelopment}/>
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path = '/creative-login' component = {CreativeLogin}/>
                    <Route path = '/admin' component = {Admin}/>
                    <Route path = '/add-blog' component = {Addblog}/>
                    <Route path = '/edit-blog/:id' component = {EditBlog}/>
                    <Route path='/blog/:blogName' component={BlogContent} />
                    <Route path='/thank-you-alchem' component={MailSuccess} />
                    <Route component={NotFound} />
                    <Route path = '/404' component={NotFound} />
                    <Redirect to="/404"/>
                </Switch>
            <Footer/>
        </div>
    );
}

export default App
