import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class WebApplicationDevelopement extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }


    WebAppProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Gather Prerequisites",
                "img" : "process-1.svg",
                "description" : "To begin with, we get the requirements from our client. We understand our clients’ goals and determine how to achieve them through the web application. At this stage, we will define the functionality of your web app and conduct research on the best means to achieve them."
            },
            {
                "id" : 2,
                "title" : "Design",
                "img" : "process-2.svg",
                "description" : "Here is where we decide your web app’s flow. A workflow of how your web app will give you an idea of what your web app will look like and permute that into a wireframe. Upon that, we begin working on the prototype of your web application. And, once the prototype is approved, the actual UI design will commence."
            },
            {
                "id" : 3,
                "title" : "Develop",
                "img" : "process-3.svg",
                "description" : "In this stage, we settle upon the right framework, platforms, and tools that will complement your needs. Multiple factors like the functionality of your web app, your goals, etc. will be taken into consideration during this stage. This is when we start coding the web application. "
            },
            {
                "id" : 4,
                "title" : "QC",
                "img" : "process-4.svg",
                "description" : "We continuously test your web app to make sure that they are free of any errors before we deliver them to you. We test your web app for functionality, usability, and performance. In order to deliver a flawless product, we make quality assurance our top priority and we also make sure the web application is bug-free."
            },
            {
                "id" : 5,
                "title" : "Delivery",
                "img" : "process-5.svg",
                "description" : "Once we make sure that we have the code and the host, it is time to deploy and deliver! We make sure that our final product is in line with our client’s requirements and that the web app works as planned. However, it does not end there."
            },
            {
                "id" : 6,
                "title" : "Maintenance",
                "img" : "process-6.svg",
                "description" : "On delivery, we pay attention to our client’s feedback and implement necessary changes if needed. We extend our technical support to our clients post-launch and work towards optimizing the web application according to our client’s business progress."
            }
        ]

    toggle = (e, tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            setInterval(5000, this.intervalRef.current);
        }
    }


    componentDidMount(){
        // $('.nav-tabs-dropdown')
        // .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        // })
        // .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        // });

        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.web-app-process > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.web-app-process > li').length
        //     ? 
        //     active.parent().next('.web-app-process > li').find('a') 
        //     : 
        //     tabs.parent().filter('.web-app-process > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.web-app-process a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.6;
        var $bg_images = $(".brand-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.WebAppProcessContent.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='web-application-development-company-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="digi-mark-bg-img web-applicaiton-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-8">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">Web Application Development Company in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">A powerful web application can make it easier for you to provide a great customer experience to reach your customers, and outperform your competitors, all at the same time.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/web-application/web-app-4.png`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="branding-container-align">
                                    <h1 className="digi-market-zig-zag-title">What is a Web Application?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>Web applications can be a unique solution to a number of businesses ranging from E-commerce to entertainment. Web applications have become extremely common now and it has now become necessary for businesses to stand out from the crowd to even be noticed. A web application can promote every aspect of your business. While customers are the king of your business, what are you doing to keep them satisfied? Investing in Customer Relationship Management (CRM) could help in retaining and maintaining a good customer relationship. Likewise, investing in Enterprise Resource Planning (ERP) in improving the management of your business. All you need to do is determine your business needs and invest in a web application that will help in achieving your business goals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2 branding-main-align">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse align-items-center">
                            <div className="col-xs-12 col-sm-7">
                                <div className="branding-container-align-2">
                                    <h1 className="digi-market-zig-zag-title">Develop a powerful Web Application</h1>
                                    <div className="digi-market-zig-zag-content web-app-content-cont">
                                        <p>For a web application to stand out, it needs to be abundant in tech, design implementing UI/UX, and content. A Web application can make it convenient for your customers to carry out any business with you online. With the advent of the internet, the way businesses communicate with their customers has undergone a massive change. Right now, web applications can be a means for businesses to interact with clients, customers and at the same time, surpass their competitors.</p>
                                        <h2>Leverage the power of technology. <Link to="/contact">Talk to us</Link> today.</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-5 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/saas-we.jpg`} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Benefits of building a Web Application at Alchem Digital</h1>
                        <div className="digi-benefits-align">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Securely Built</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">User Experience</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Dynamic & Scalable</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Maintenance</a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-6.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Securely Built</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">A web application has the chance of being subjected to multiple threats such as database manipulation and whatnot. At Alchem Digital, we consider security a part of web app development rather than a separate process. We make sure that your web application is checked for any vulnerabilities and is safe from external threats.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-1.svg`} alt="benefits-icon-1"/>
                                                <h2 className="digi-market-title-benefit">User Experience</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">User experience is an important aspect of a web application if you want to retain customers. We utilize our time in designing an engaging web application that will fulfill the user’s needs. UX can ultimately help in creating a positive experience among your customers that will in turn help in retaining them.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-4.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Dynamic & Scalable</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">A web application is built with the client’s needs in mind. Therefore, a web application can be developed/customized as the business undergoes development or change. Our web applications let you add additional modules that are in line with your project requirements on the go. We facilitate updates for your web application that are in accordance with your needs.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/web-application/web-app-benefits/maintenance.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Maintenance</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Web applications are subjected to regular change and require maintenance. Our maintenance services furnish server monitoring, performance optimizations, updating content, and database management & security. We consider maintenance as important as the development itself as it plays a significant role in staying updated and new.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Our Web Application Development Process</h1>
                        <div className="wordpress-content-cont">
                            {/* <ul className="nav nav-pills web-app-process mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-1-tab" data-toggle="pill" href="#pills-seo-content-1" role="tab" aria-controls="pills-seo-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Gather Prerequisites</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Gather Prerequisites</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-2-tab" data-toggle="pill" href="#pills-seo-content-2" role="tab" aria-controls="pills-seo-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Design</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Design</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-3-tab" data-toggle="pill" href="#pills-seo-content-3" role="tab" aria-controls="pills-seo-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Develop</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Develop</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-4-tab" data-toggle="pill" href="#pills-seo-content-4" role="tab" aria-controls="pills-seo-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">QC</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">QC</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-5-tab" data-toggle="pill" href="#pills-seo-content-5" role="tab" aria-controls="pills-seo-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Delivery</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Delivery</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-6-tab" data-toggle="pill" href="#pills-seo-content-6" role="tab" aria-controls="pills-seo-content-6" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Maintenance</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-6"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Maintenance</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active-anim active" id="pills-seo-content-1" role="tabpanel" aria-labelledby="pills-seo-content-1-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Gather Prerequisites</h3>
                                        <p>To begin with, we get the requirements from our client. We understand our clients’ goals and determine how to achieve them through the web application. At this stage, we will define the functionality of your web app and conduct research on the best means to achieve them.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-2" role="tabpanel" aria-labelledby="pills-seo-content-2-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Design</h3>
                                        <p>Here is where we decide your web app’s flow. A workflow of how your web app will give you an idea of what your web app will look like and permute that into a wireframe. Upon that, we begin working on the prototype of your web application. And, once the prototype is approved, the actual UI design will commence.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-3" role="tabpanel" aria-labelledby="pills-seo-content-3-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Develop</h3>
                                        <p>In this stage, we settle upon the right framework, platforms, and tools that will complement your needs. Multiple factors like the functionality of your web app, your goals, etc. will be taken into consideration during this stage. This is when we start coding the web application.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-4" role="tabpanel" aria-labelledby="pills-seo-content-4-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>QC</h3>
                                        <p>We continuously test your web app to make sure that they are free of any errors before we deliver them to you. We test your web app for functionality, usability, and performance. In order to deliver a flawless product, we make quality assurance our top priority and we also make sure the web application is bug-free.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-5" role="tabpanel" aria-labelledby="pills-seo-content-5-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Delivery</h3>
                                        <p>Once we make sure that we have the code and the host, it is time to deploy and deliver! We make sure that our final product is in line with our client’s requirements and that the web app works as planned. However, it does not end there.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-6" role="tabpanel" aria-labelledby="pills-seo-content-6-tab">
                                    <div className="wordpress-process-content web-app-content-align">
                                        <h3>Maintenance</h3>
                                        <p>On delivery, we pay attention to our client’s feedback and implement necessary changes if needed. We extend our technical support to our clients post-launch and work towards optimizing the web application according to our client’s business progress.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Local SEO</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '1' ? `${Config.HOST}/assets/img/seo/seo-process-1.svg` : `${Config.HOST}/assets/img/seo/seo-process-1-black.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Local SEO</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <h4 className="padd-bottom-12">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '2' ? `${Config.HOST}/assets/img/seo/seo-process-2-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-2.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Keyword Research</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">On-page Optimization</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '3' ? `${Config.HOST}/assets/img/seo/seo-process-3-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-3.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">On-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <h4 className="padd-bottom-12">Off-page Optimization</h4> 
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '4' ? `${Config.HOST}/assets/img/seo/seo-process-4-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-4.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Off-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Continuous Optimization</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '5' ? `${Config.HOST}/assets/img/seo/seo-process-5-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-5.svg`}  alt="seo-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12">Continuous Optimization</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="seo-process-content">
                                        <h3>Local SEO</h3>
                                        <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="seo-process-content">
                                        <h3>On-page Optimization</h3>
                                        <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="seo-process-content">
                                        <h3>Off-page Optimization</h3>
                                        <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="seo-process-content">
                                        <h3>Continuous Optimization</h3>
                                        <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}
                            <Nav tabs>
                                {
                                    this.WebAppProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/`+item.img}  alt="e-com-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.WebAppProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process mob-brand-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-1.svg`} alt="seo-process"/>
                                    <h4>Gather Prerequisites</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>To begin with, we get the requirements from our client. We understand our clients’ goals and determine how to achieve them through the web application. At this stage, we will define the functionality of your web app and conduct research on the best means to achieve them.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-2.svg`} alt="seo-process"/>
                                    <h4>Design</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Here is where we decide your web app’s flow. A workflow of how your web app will give you an idea of what your web app will look like and permute that into a wireframe. Upon that, we begin working on the prototype of your web application. And, once the prototype is approved, the actual UI design will commence.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-3.svg`} alt="seo-process"/>
                                    <h4>Develop</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>OIn this stage, we settle upon the right framework, platforms, and tools that will complement your needs. Multiple factors like the functionality of your web app, your goals, etc. will be taken into consideration during this stage. This is when we start coding the web application.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-4.svg`} alt="seo-process"/>
                                    <h4>QC</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>We continuously test your web app to make sure that they are free of any errors before we deliver them to you. We test your web app for functionality, usability, and performance. In order to deliver a flawless product, we make quality assurance our top priority and we also make sure the web application is bug-free.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-5.svg`} alt="seo-process"/>
                                    <h4>Delivery</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we make sure that we have the code and the host, it is time to deploy and deliver! We make sure that our final product is in line with our client’s requirements and that the web app works as planned. However, it does not end there.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-6.svg`} alt="seo-process"/>
                                    <h4>Maintenance</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>On delivery, we pay attention to our client’s feedback and implement necessary changes if needed. We extend our technical support to our clients post-launch and work towards optimizing the web application according to our client’s business progress.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="brand-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Looking for futuristic web app solutions?</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <Link to="/contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Ping us</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-services digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Web Application that we <br/> Specialize in</h1>
                        <div className="wordpress-services-grid web-app-services-grid">
                            <div className="ad-services-grid-align">
                                <div>
                                    <div className="ad-home-mob-remove-right-border wordpress-grid-width">
                                            <div className="ad-services-sections web-app-grid-height">
                                                <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-3.svg'} alt="service-1"/>
                                                <h2 className="ad-services-secction-txt">E-Commerce Web Applications</h2>
                                                <p className="ad-services-secction-excerpt">Transaction of goods and services through online means has become extremely common now and we are among the best that offer E-Commerce web solutions. We excel at delivering easy-to-use E-Commerce web applications that will perform best in accordance with our client’s requirements, business size, and budget. We develop customizable and efficient B2B, D2C, and B2C applications that are user-friendly. We use platforms like Magento, WordPress (WooCommerce), Laravel, Core PHP, Python (Django) to build your E-Commerce web application and we are nothing short of experts in it.</p>
                                            </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-remove-right-border wordpress-grid-width">
                                        <div className="ad-services-sections web-app-grid-height">
                                            <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-5.svg'} alt="service-2"/>
                                            <h2 className="ad-services-secction-txt">Custom Web Applications</h2>
                                            <p className="ad-services-secction-excerpt">A custom web application will cater to the needs and size of your business. At Alchem Digital, we build robust custom web applications that will cater to our clients’ needs. Our custom web applications are user-friendly and customizable according to your business needs. A custom web application that we build will be in line with your unique project and business requirement and in turn, make managing your business easier. A custom web application can help your business save both time and money. Custom web applications are flexible and scalable, giving you an opportunity to customize your application according to your needs, whenever, need be.s</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-add-right-border ad-home-mob-remove-right-border wordpress-grid-width">
                                        <div className="ad-services-sections web-app-grid-height">
                                            <img src={Config.HOST+'/assets/img/web-application/web-app-services/web-app-serv-1.svg'} alt="service-3"/>
                                            <h2 className="ad-services-secction-txt">CRM</h2>
                                            <p className="ad-services-secction-excerpt">CRM or Customer Relationship Management is one of the most important aspects of a business as it will help you retain and create customer relationships. Investing in CRM helps you in generating more leads, providing better customer service, and in turn, increasing your revenue potential. CRM can also help you to gain customer loyalty as it will help in identifying your customers’ needs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-remove-right-border ad-add-right-border wordpress-grid-width">
                                        <div className="ad-services-sections web-app-grid-height">
                                            <img src={Config.HOST+'/assets/img/web-application/web-app-services/web-app-serv-2.svg'} alt="service-4"/>
                                            <h2 className="ad-services-secction-txt">ERP</h2>
                                            <p className="ad-services-secction-excerpt">ERP or Enterprise Resource Planning is typically used to manage important aspects of a business. ERP software can be useful for a number of purposes beginning from purchase to sales. You can customize your ERP software depending on what needs of your business it will address. ERP is usually easily compatible with any E-Commerce and web portals with easy API integrations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-home-mob-remove-right-border ad-home-mob-add-bottom-border wordpress-grid-width">
                                        <div className="ad-services-sections web-app-grid-height">
                                            <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-2.svg'} alt="service-5"/>
                                            <h2 className="ad-services-secction-txt">CMS</h2>
                                            <p className="ad-services-secction-excerpt">A Content Management System can be the right solution for you if your web application requires constant content updates. CMS is common for blogs and we excel in platforms like Joomla and WordPress to achieve this feat. CMS makes it easier for you to create and manage content easily.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-home-service-box-bottom-border wordpress-grid-width">
                                        <div className="ad-services-sections web-app-grid-height wordpress-last-grid">
                                            <div className="res-dev-key-feature-sections">
                                                <h2 className="digi-market-services-last-box-content">Build a customized web app now</h2>
                                                <Link to='/contact' className="digi-market-content-button button-gradient">Contact us now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-technologies web-app-tech-logos">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Core PHP</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Laravel</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Wordpress</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">React Js</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Python (Django)</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="webapp-laptop-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 digi-market-mob">
                                <img src={`${Config.HOST}/assets/img/web-application/web-app-3.png`} alt="wp-img-2" />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-serve-you-container wordpress-content-container">
                                    <h1 className="digi-market-serve-you-title">Why is Alchem Digital your best Web Application Development Company in Chennai?</h1>
                                    <p className="digi-market-serve-you-content">At Alchem Digital, we conduct comprehensive research on our client’s requirements and develop a custom web application that is in line with our client’s needs. With our expert team, we develop web applications that benefit both our clients and their customers. Here at Alchem Digital, creativity meets technical expertise. We have expanded our expertise in Laravel, Core PHP, Python (Django), Magento, WordPress (WooCommerce), MySQL, and React js.</p>
                                    <p className="digi-market-serve-you-content">Being a leading web application development company in Chennai, we develop web applications that are efficient and user-friendly. Our team of accomplished designers and developers work relentlessly to provide you with the best results possible. Our team comes up with strategies and plans of action that will suit your business goals with their expertise in their respective fields and work together in delivering the best results for your company.</p>
                                    <Link to="/contact" className="digi-benefits-content-button button-gradient transition-issue">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-clients-section">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <h1 className="res-dev-client-content-subtitle mob-text-center">THE HONOUR TO OUR JOURNEY</h1>
                                    <h2 className="res-dev-client-content-title mob-text-center">Our valued <br/> clients</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 new-padd-left-add">
                                {typeof window !== 'undefined' && 
                                    <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                {typeof window !== 'undefined' &&
                                    <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-mega-container-1">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                </OwlCarousel>
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                {/* <section className="wordpress-dev-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top WordPress Development company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>How long does it take to build a WordPress website?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>The answer to that question will depend on what you want your WordPress website to do. The time to build a WordPress website for your business may vary depending on what type of design you choose and what functionalities you are looking for.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>Would you provide a custom theme for my WordPress site?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>Yes, we do provide custom theme designs for your WordPress site that is in line with your specific business requirements.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>How much will it cost to develop a WordPress site for my business?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>The cost of your WordPress site may vary according to your requirements. Feel free to contact us and discuss the pricing of your website.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>Will my WordPress site be responsive?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Yes absolutely! All the websites that we build, including WordPress sites, are compatible across all devices.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section> */}
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default WebApplicationDevelopement