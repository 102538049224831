import React, { Component } from 'react'
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Config from './Config'
import Swal from "sweetalert2"
import { Link } from 'react-router-dom';
const ReactQuill = (typeof window != 'undefined') && require('react-quill')

export default class EditBlog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blogId: "",
            blogTitle: "",
            blogSlugTitle: this.props.match.params.id,
            blogContent: "",
            blogHeader: "",
            banner: "",
            blogAuthor: "",
            blogCategory: "",
            category: [],
            authors: [],
        }
    }

    componentDidMount = () => {
        this.getBlogData()

        Config.axios({
            auth: true,
            url: `${Config.API_URL}/api/user/category`,
            success: (res) => {
                this.setState({
                    category: res.data,
                })
            },
        })

        Config.axios({
            auth: true,
            url: `${Config.API_URL}/api/user/author`,
            success: (res) => {
                this.setState({
                    authors: res.data,
                })
            },
        })
    }

    getBlogData = () => {
        const formData = new FormData();
        formData.set('slug', this.state.blogSlugTitle)
        Config.axios({
            method: 'post',
            auth: true,
            url: `${Config.API_URL}/api/user/post/get`,
            data: formData,
            success: (res) => {
                this.setState({
                    blogId: res.data.post.id,
                    blogTitle: res.data.post.title,
                    blogSlugTitle: res.data.post.slug,
                    blogContent: res.data.post.content,
                    blogHeader: res.data.post.head_tag,
                    banner: res.data.post.banner_image,
                    blogCategory: res.data.post.category.id,
                    blogAuthor: res.data.post.author.id,
                })
            },
            error: (res) => {
                console.log(res)
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name == 'blogTitle') {
            this.setState({
                blogSlugTitle: e.target.value.replace(/\s+/g, '-').toLowerCase()
            })
        }
    }

    handleBannerChange = (e) => {
        this.setState({
            blogBanner: e.target.files[0]
        })
    }

    onDescriptionChange = (content) => {
        this.setState({ blogContent: content })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('id', parseInt(this.state.blogId))
        formData.set('slug', this.state.blogSlugTitle)
        formData.set('title', this.state.blogTitle)
        formData.set('category_id', parseInt(this.state.blogCategory))
        formData.set('content', this.state.blogContent)
        formData.set('banner_image', this.state.blogBanner)
        formData.set('head_tag', this.state.blogHeader)
        formData.set('author_id', parseInt(this.state.blogAuthor))
        
        Config.axios({
            // headers: { 'Content-Type': 'multipart/form-data'},
            method: 'put',
            url: `${Config.API_URL}/api/user/post/update`,
            auth: true,
            data: formData,
            success: (response) => {
                let timerInterval
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    html: '<p>Blog Updated.</p>',
                    timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                        timerInterval = setInterval(() => {
                            const content = Swal.getContent()
                            if (content) {
                                const b = content.querySelector('b')
                                if (b) {
                                    b.textContent = Swal.getTimerLeft()
                                }
                            }
                        }, 500)
                    },
                    onClose: () => {
                        clearInterval(timerInterval)
                    }
                });
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    render() {
        return (
            <>
                <div className="container">
                    <Link to='/admin'>
                        <button className="btn btn-secondary mt-3">Back</button>
                    </Link>
                    <div className="card my-3">
                        <div className="card-header">
                            <h4>Edit Blog</h4>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="Title">Title</label>
                                    <input type="text" className="form-control" name="blogTitle" onChange={this.handleChange} value={this.state.blogTitle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Title">Slug title</label>
                                    <input type="text" className="form-control" name="blogSlugTitle" onChange={this.handleChange} value={this.state.blogSlugTitle} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Title">Category</label>
                                    <select
                                        name="blogCategory"
                                        id=""
                                        className="form-control"
                                        value={this.state.blogCategory}
                                        onChange={this.handleChange}
                                    >
                                        <option disabled selected>Select</option>
                                        {
                                            this.state.category.map((category) => (
                                                <option value={category.id}>{category.category}</option>
                                            ))
                                        }
                                    </select>
                                    {/* <input type="text" className="form-control" name="blogCategory" onChange={this.handleChange} value={this.state.blogCategory} /> */}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Title">Content</label>
                                    {typeof window != 'undefined' &&
                                        <ReactQuill value={this.state.blogContent}
                                            modules={{
                                                toolbar: [
                                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                    ['bold', 'italic', 'underline', 'blockquote'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    [{ 'align': [] }],
                                                    ['image', 'code', 'link'],
                                                    ['clean']
                                                ]
                                            }}
                                            onChange={(content, delta, source, editor) => this.onDescriptionChange(content, editor)}

                                        />
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Header tag</label>
                                    <textarea name="blogHeader" className="form-control" cols="30" rows="10" onChange={this.handleChange} value={this.state.blogHeader}></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Banner image</label>
                                    <input type="file" className="form-control" name="banner" onChange={this.handleBannerChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Author</label>
                                    <select name="author" className="form-control" value={this.state.blogAuthor} onChange={this.handleChange}>
                                        <option disabled selected>Select</option>
                                        {
                                            this.state.authors.map((author) => (
                                                <option value={author.id}>{author.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary" >
                                        Update
                            </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
