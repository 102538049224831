import React from 'react';
import success from './success.json'
import LottieAnimation from './Lottie'


const Success = () => {
    return (
        <div className='success-loader'>
            <LottieAnimation lotti={success} />
        </div>
    )
}

export default Success;