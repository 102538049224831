import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../layout/AllPageContactForm'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import Config from "./Config"
import Testimonial from "../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')
// import PageTransition from '../PageTransition'
class ResponsiveWebsiteDesign extends React.Component{


    componentDidMount(){
        $("a[href^='#'].res-dev-call-to-action-button").on('click', function(e) {
            e.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 1000, function(){
                window.location.hash = hash;
                var noHashURL = window.location.href.replace(/#.*$/, '');
                window.history.replaceState('', document.title, noHashURL) 
            });
        });

        var parallax = -0.5;
        var $bg_images = $(".res-dev-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });
    }

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='responsive-website-design-development' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="res-dev-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <p className="res-dev-title">Responsive website Development</p>
                                        <p className="res-dev-subtitle">It is fundamental these days to have cross-device compatibility and remain device agnostic, 
                                        while designing and developing websites.</p>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-main-section">
                    <div className="container res-dev-main-title">
                        <p className="res-dev-main-title">So, what’s Responsive website design?</p>
                        <p className="res-dev-sub-content">It’s simple. Ensuring to design and develop various graphics and elements of a website that is device and platform agnostic. Top quality user experience through fluid user interface design across multiple device(s) and platform(s) is what a responsive website design is.</p>
                        <p className="res-dev-sub-content no-padding">With a majority of the search and brand discoveries happening through mobile, responsive website design becomes super essential.</p>
                    </div>
                    <div className="res-dev-advantage-section container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img className="res-dev-dots-img" src={`${Config.HOST}/assets/img/about-bg-dots.png`} alt="about-bg-dots"/>
                                <img className="dots-img-align" src={`${Config.HOST}/assets/img/res-dev-asset/adv-res-dev.png`} alt="adv-res-dev"/>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="res-dev-advantage-content-section">
                                    <p className="res-dev-advantage-content-title">Advantages of Responsive Website Designing</p>
                                    <div className="res-dev-advantage-width">
                                        <p className="res-dev-advantage-content">There are multiple advantages of having a responsive website design. First up, it eliminates the cost of investing on a mobile app. While responsive design doesn’t replace all the stuff that a mobile app can do, it certainly simulates one. If you are planning to venture into a mobile app in the future, having a responsive website/web application is the way to go. Having a responsive website is essential even if you do not want to invest on a mobile app.</p> 
                                        <p className="res-dev-advantage-content">That being said, there is a ton of other pros associated with having a responsive website design. Check your Google analytics and you can clearly see the difference in bounce rate stats while having a responsive website design. It also improves the search rankings – But how? Google gives preference to websites that are dynamic and smartphone-friendly. Swift loading time and a fluid UX also attributes to the SEO performance.</p>
                                        <p className="res-dev-advantage-content">So, having a responsive website design is a no-brainer.</p> 
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="res-dev-separate-section">
                        <div className="container">
                            {/* <div className="row mob-res-dev-bullet-advantage-section">
                                <div className="col-xs-12 col-sm-5">
                                </div>
                                <div className="col-xs-12 col-sm-7">

                                </div>
                            </div> */}
                            <div className="res-dev-advantage-sep-content-section"> 
                                <div className="res-dev-advantage-bullets" >
                                    <div className="row first-list">
                                        <div className="col-xs-12 col-sm-4"><p className="res-dev-lists">Improved SEO</p></div>
                                        <div className="col-xs-12 col-sm-4"><p className="res-dev-lists">Lower Bounce Rate</p></div>
                                        <div className="col-xs-12 col-sm-4"><p className="res-dev-lists">Rewarding mobile search</p></div>
                                    </div>
                                    <div className="row last-list">
                                        <div className="col-xs-12 col-sm-4"><p className="res-dev-lists">Enhanced UX</p></div>
                                        <div className="col-xs-12 col-sm-4"><p className="res-dev-lists">Quick loading</p></div>
                                        <div className="col-xs-12 col-sm-4"><p className="res-dev-lists">Future proof</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container res-dev-quote-padding">
                            <div className="row align-items-center">
                                <div className="col-xs-12 col-sm-8">
                                    <p className="res-dev-quote">Want to know more about the power of responsive website design?<br/> We're good people. Don't hesitate</p>
                                </div>
                                <div className="col-xs-12 col-sm-4 text-right">
                                    <Link className="res-dev-button button-gradient transition-issue" to="/contact">Talk to us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <p className="res-dev-key-features-title">Key Features </p>
                        <div className="res-dev-key-features-container">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/res-dev-asset/magnifier-lens.svg`} alt="magnifier-lens"/>
                                    <p className="res-dev-key-feature-txt">SEO Gains</p>
                                    <p className="res-dev-key-feature-excerpt">As mentioned earlier, Google certainly rewards websites that are responsive across multiple devices and platforms, by bettering the search ranks. Improving SEO performance is by far the best and an inexpensive way to attract the best “potentials” for your business.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/res-dev-asset/web-box.svg`} alt="web-box"/>
                                    <p className="res-dev-key-feature-txt">Better UX</p>
                                    <p className="res-dev-key-feature-excerpt">There is no “one fit for all” and websites are no different. The user flow and site architecture vastly change across different dimensions of smartphones, laptops, tablets, phablets and even smartwatches. It is important to adapt, design and align the graphics, texts, and pretty much the overall user interface fit across different screens to deliver a bespoke and best experience for the user.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/res-dev-asset/purse-icon.svg`} alt="purse"/>
                                    <p className="res-dev-key-feature-txt">Bang for the Buck</p>
                                    <p className="res-dev-key-feature-excerpt">Most businesses, if not all, wish to invest and build a mobile application. But, is the investment worth it? Well, kickstarting with a mobile responsive website or a web application would make sense to start with and later analyzing the performance statistics of the mobile version would save a ton of money, especially for startups.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/res-dev-asset/statistics.svg`} alt="statistics"/>
                                    <p className="res-dev-key-feature-txt">Accurate Analytics</p>
                                    <p className="res-dev-key-feature-excerpt">With responsive website design, analyzing the analytics becomes much more accurate and simpler. Ambiguous and redundant data can work against the business. Differentiating the statistics based on the platforms and devices contributes to its accuracy and better reading.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="res-dev-call-to-action-title">Zero in on the experts. Right away.</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#responsive-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Drop in your deets</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-clients-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <p className="res-dev-client-content-subtitle">THE HONOUR TO OUR JOURNEY</p>
                                    <p className="res-dev-client-content-title">Our valued<br/>clients</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7">
                                <div className="res-dev-client-logos-container">
                                    {typeof window != 'undefined' &&
                                        <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                            <div className="home-page-clients-mega-container">
                                                <div className="home-page-client-logos-container">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo carefree-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                    </div>
                                                </div>
                                                <div className="home-page-client-logos-container">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo caapid-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo leia-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="home-page-clients-mega-container">
                                                <div className="home-page-client-logos-container">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo langscape-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                    </div>
                                                </div>
                                                <div className="home-page-client-logos-container">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo aci-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo dosa-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo aptus-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="home-page-clients-mega-container">
                                                <div className="home-page-client-logos-container">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo sukom-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    }
                                    {typeof window != 'undefined' &&
                                        <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                            <div className="home-page-clients-mega-container">
                                                <div className="home-page-client-logos-container-1">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo carefree-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                    </div>
                                                </div>
                                                <div className="home-page-client-logos-container-1">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo caapid-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="home-page-mega-container-1">
                                                <div className="home-page-client-logos-container-1">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo leia-logo"></div>
                                                    </div>
                                                </div>
                                                <div className="home-page-client-logos-container-1">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo dosa-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo aptus-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="home-page-clients-mega-container">
                                                <div className="home-page-client-logos-container-1">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo langscape-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                    </div>
                                                </div>
                                                <div className="home-page-client-logos-container-1">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                    </div>
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo aci-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="home-page-clients-mega-container">
                                                <div className="home-page-client-logos-container">
                                                    <div className="res-dev-client-logos">
                                                        <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-caapid-application-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-5 mob-caapid-img-align">
                                <img className="res-dev-caapid-img" src={`${Config.HOST}/assets/img/res-dev-asset/responsive-caapid.png`} alt="responsive-caapid"/>
                            </div>
                            <div className="col-xs-12 col-sm-7">
                                <div className="res-dev-caapid-responsive-container">
                                    <p className="res-dev-caapid-responsive-container-title">Why Responsive Website Design is the need of the hour?</p>
                                    <p className="res-dev-caapid-responsive-container-content pt-3">The time has come where mobile searches have surpassed desktop searches. Thanks to the technological advancements and advent of the smartphone industry. Of course, due credits to Jio as well.</p>
                                    <p className="res-dev-caapid-responsive-container-content">Here’s why responsive website design is an inevitable need.  </p>
                                    <ul className="res-dev-caapid-responisve-container-points">
                                        <li>Your website is just a click/tap away to be visited and just as much to exit. Hence, delivering the best user experience irrespective of the platform or device through which it has been searched, is crucial. Be it a smartphone, tablet, phablet or even a smartwatch.</li>
                                        <li>It is also vital to ensure the website adapts best across different web browsers if not all, at least the most used browsers like Chrome, Safari, Edge and Firefox.</li>
                                        <li>Your website/web application is not just what it is, but also a talking point, an identity and a trust factor. With such attributes associated to a website, you should never go wrong in this pivotal touchpoint.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-ad-application-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-7">
                                <div className="res-dev-ad-responsive-container">
                                    <p className="res-dev-ad-responsive-container-title">Why Alchem Digital is the Best Responsive website design & development company?</p>
                                    <p className="res-dev-ad-responsive-container-content pt-3">At Alchem Digital, we understand the need and the importance of a responsive website through meticulous research. We have an array of websites and web applications built for our clients across the globe to our credit. The websites are designed and developed across different web technologies. At Alchem Digital, we are best known for our timely execution and the finest results.</p>
                                    <p className="res-dev-ad-responsive-container-quote-content">If you wish to develop a responsive website, web application or a SaaS product don’t hesitate to <span><Link to="/contact">contact us.</Link></span></p>
                                    <div className="res-dev-quote-align">
                                        <Link className="res-dev-container-quote-button" to="/portfolio">Our Works</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-5">
                                <img className="ad-res-dev-ad-website" src={`${Config.HOST}/assets/img/res-dev-asset/ad-responsive-img.png`} alt="responsive-ad"/>
                                <img className="ad-res-dev-dots-img-2" src={`${Config.HOST}/assets/img/about-bg-dots.png`} alt="about-bg-dots"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-ad-technique-section">
                    <div className="container">
                        <p className="res-dev-ad-technique-subtitle text-center">TECHNOLOGIES</p>
                        <p className="res-dev-ad-technique-title text-center">we specialize</p>
                        <div className="res-dev-ad-tech-logo-container">
                            <div className="tech-logo-icons">
                                <div className="res-dev-ad-tech-logo tech-html-logo new-with-img"></div>
                                <p>HTML</p>
                            </div>
                            <div className="tech-logo-icons">
                                <div className="res-dev-ad-tech-logo tech-bootstrap-logo"></div>
                                <p>Bootstrap</p>
                            </div>
                            <div className="tech-logo-icons">
                                <div className="res-dev-ad-tech-logo tech-javascript-logo"></div>
                                <p>Javascript</p>
                            </div>
                            <div className="tech-logo-icons">
                                <div className="res-dev-ad-tech-logo tech-jquery-logo"></div>
                                <p>jQuery</p>
                            </div>
                            <div className="tech-logo-icons">
                                <div className="res-dev-ad-tech-logo tech-wordpress-logo"></div> 
                                <p>Wordpress</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial color="white"/>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default ResponsiveWebsiteDesign