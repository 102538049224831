import React, { Component } from 'react'

import Config from './Config'
import Header from '../layout/Header'
import { Link } from "react-router-dom"

export default class Admin extends Component {
    constructor(props) {
        super(props)
        Config.adminRedirectIfNotLoggedIn(this.props)
        this.state = {
            blogs: [],
            nextPage: null,
            previousPage: null
        }
    }

    logout = () => {
        localStorage.removeItem('userState');
        window.location.href = "/creative-login";
    }

    componentDidMount = () => {
        Config.axios({
            auth: true,
            url: `${Config.API_URL}/api/user/user-data`,
            success: (res) => {
                this.getBlog()
            },
            error: (res) => {
                this.logout()
            }
        })
    }

    getBlog = () => {
        Config.axios({
            auth: true,
            url: `${Config.API_URL}/api/user/post/list`,
            success: (res) => {
                this.setState({
                     blogs: res.data.results,
                })
                if (res.data.next !== null) {
                    this.setState({
                        nextPage: res.data.next.split("=")[1]
                    })
                }
                if (res.data.previous !== null) {
                    this.setState({
                        previousPage: res.data.previous.split("=")[1]
                    })
                }
            },
        })
    }

    deleteBlog = (id) => {
        const formData = new FormData();
        formData.set('id', id)
        Config.axios({
            method: 'delete',
            auth: true,
            url: `${Config.API_URL}/api/user/post/delete`,
            data: formData,
            success: (res) => {
                alert("deleted")
            },
        })
    }

    handlePagination = (pageNumber) => {
        if (pageNumber == null){
            return
        }else if(pageNumber == 1){
            this.getBlog()
        } 

        Config.axios({
            auth: true,
            url: `${Config.API_URL}/api/user/post/list?page=${pageNumber}`,
            success: (res) => {
                this.setState({
                    blogs: res.data.results,
                })
                if (res.data.next !== null) {
                    this.setState({
                        nextPage: res.data.next.split("=")[1]
                    })
                }
                if (res.data.previous !== null) {
                    if (typeof res.data.previous.split("=")[1] == 'undefined') {
                        this.setState({
                            previousPage: 1
                        })
                    }else{
                        this.setState({
                            previousPage: res.data.previous.split("=")[1]
                        })
                    }
                }
            },
        })
    }

    render() {
        // const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });
        let date
        return (
            <>
            <Header />
                <div className="container ad-about-bg pt-8">
                    <button className="btn btn-secondary" onClick={this.logout}>Logout</button>
                    <div className="card m-2">
                        <div className="card-header">
                            <div className="d-flex justify-content-between">
                                <h4>Blog</h4>
                                <Link to="/add-blog">
                                    <button className="btn btn-primary">add</button>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table table-striped table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">S.no</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Slug</th>
                                        <th scope="col">category</th>
                                        <th scope="col">Posted Date</th>
                                        <th scope="col">action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.blogs.map((blog, key) => (
                                        <tr key={key}>
                                            <th scope="row">{key + 1}</th>
                                            <td>{blog.title}</td>
                                            <td>{blog.slug}</td>
                                            <td>
                                                {
                                                    blog.category === null?
                                                    <span>Null</span>
                                                    : 
                                                    blog.category.category
                                                }
                                            </td>
                                            <td>{
                                                new Date(blog.created_at).getDate() + ' - ' + new Date(blog.created_at).getMonth() + ' - ' + new Date(blog.created_at).getFullYear()

                                            }</td>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Link to={`/edit-blog/${blog.slug}`}>
                                                        <button className="btn btn-sm btn-success">
                                                            Edit
                                                        </button>
                                                    </Link>
                                                    <button className="btn btn-sm btn-danger" onClick={() => { this.deleteBlog(blog.id) }}>
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    {
                                        this.state.previousPage !== null &&
                                        <li class="page-item"><button class="page-link" onClick={() => this.handlePagination(this.state.previousPage)}>Previous</button></li>
                                    }
                                    {
                                        this.state.nextPage !== null &&
                                            <li class="page-item"><button class="page-link" onClick={() => this.handlePagination(this.state.nextPage)}>Next</button></li>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
