import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../layout/AllPageContactForm'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import Config from "./Config"
import Testimonial from "../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class DigitalMarketing extends React.Component{


    componentDidMount(){
        $('.nav-tabs-dropdown')
        .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        })
        .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        });

        var parallax = -0.6;
        var $bg_images = $(".res-dev-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });
    }

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='digital-marketing-agency-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="digi-mark-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">Digital Marketing<br/> Company in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">Creating an impact on the digital platform has become an important part of any business in recent times. Your digital presence is as important as your business itself.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-market-side-img-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">What is digital marketing?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>Digital marketing is a boundless term that consists of SEO, Google ads, Facebook/ Instagram ads, paid social, content creation, social engagement, and much more.</p>
                                        <p>Digital marketing can help you get results in a short span if you are ready to invest in paid media marketing. However, in the long run, digital marketing will be one of the most efficient marketing plans for your business, giving you total control over your budget. Significantly economical when compared to traditional media, digital marketing lets you identify what works for your brand and invest more in that.</p>
                                        <p>Digital marketing also lets you track your performance and optimize accordingly. With digital marketing, you can reach your TGA globally with precise targeting based on affinity, interests, behavior, demographics, and so on.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container-2">
                                    <h1 className="digi-market-zig-zag-title">Why do you need to go digital now?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>There are approximately 4.66 billion internet users across the world and it only makes sense for you to be where the customers are at. Who wouldn’t want an efficient means to transform their business, enhance its customer relationship and give the best consumer experience to its customers? Did we hear ‘everyone’? Well, that is it. That is why you need to go digital. However, simply going digital might be a lost cause. Your digital transformation strategy plays a major role when it comes to your digital performance. That is where we aka a digital marketing company come in.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-market-side-img-2.jpg`} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2">
                    <div className="digital-market-glob-container-1">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-market-side-img-3.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">By going digital</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <ul className="digi-market-list-content">
                                            <li>You are not geographically limited. You can find your target audience in various parts of the country. It also allows you to radically change your customer’s experience.</li>
                                            <li>You reach the exact TGA with a precise targeting option and arguably at an economical cost.</li>   
                                            <li>You can track every metric of your digital presence be it engagement, conversations, conversions, website traffic, app installs, sales, and whatnot, which will help you understand the needs of your target audience/customers better.</li>    
                                            <li>It is easy to interpret the campaign results and revisit your business strategies and make changes on the go which might increase your chances of a better return on advertising spend (ROAS).</li>   
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Benefits of digital marketing</h1>
                        <div className="digi-benefits-align">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Cost-effective</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">It is simply limitless</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Measure your results & Experiment</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Brand Credibility</a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-purse-icon.svg`} alt="purse-icon"/>
                                                <h2 className="digi-market-title-benefit">Cost-effective</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">In simple words, ROI is made easier through digital marketing. Therefore, smaller businesses can leverage digital marketing to reach a wider set of customers rapidly without having to worry about the budget. Needless to say, digital marketing is possibly one of the most efficient and cost-effective ways to promote your brand. It is relatively easy to reach a wider audience through digital marketing as it gives you more flexibility than other advertising alternatives. All you need is one post or video that goes viral to gain popularity.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-infinity-icon.svg`} alt="infinity-icon"/>
                                                <h2 className="digi-market-title-benefit">It is simply limitless</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Digital marketing can help you reach your new potential target audience efficiently. Digital marketing allows you to reach the global marketplace. Apart from reaching a lot of people, digital marketing also helps you increase your customer loyalty by allowing you to communicate with them at ease. Digital marketing also gives your customers an option to communicate with you. Two-way communication has become feasible through post-interaction in the forms of likes, comments, DMs, etc. which will give you chance to provide your consumers with what they want. Digital marketing makes it easy for you to keep track of how many of your online audiences are made into customers through conversion metrics.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-statistics-icon.svg`} alt="statistics-icon"/>
                                                <h2 className="digi-market-title-benefit">Measure your results & Experiment</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">As we mentioned earlier, digital marketing gives you an opportunity to keep track of your campaign performance. A/B testing is one of the ways you can measure your campaign performance. A/B testing allows you to compare two versions of a marketing campaign and identify how each one works with different groups. A/B testing will also let you find the way forward with your marketing strategies as conversion rates can be easily determined through it. A/B testing gives you an opportunity to have increased user engagement, better content, detailed analysis of metrics, and more. By leveraging digital marketing, you can experiment with multiple versions of advertisements to audiences of various spectrums which will help in optimizing your campaign results.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/digital-marketing/digi-diamond-icon.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Brand Credibility</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Brand credibility can be easily achieved through digital marketing. Providing interesting and valuable content to your customers increases their trust in your brand. Brand credibility plays a major role in how your brand is perceived by the consumers. Providing informative content, having client testimonials, and offering the best customer experience are a few things that you can do to gain brand credibility Websites and social media handles are the new business cards for brands.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Digital marketing services</h1>
                        <div className="res-dev-key-features-container digi-market-mega-cont">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/res-dev-asset/magnifier-lens.svg`} alt="magnifier-lens"/>
                                    <h2 className="res-dev-key-feature-txt mb-1 pt-2">SEO</h2>
                                    <p className="res-dev-key-feature-excerpt">SEO or search engine optimization is the process of increasing traffic organically by helping your website rank up in the SERP. Ranking on top of the SERP is dependent on various factors like your on-page and off-page SEO techniques. To benefit from SEO, one has to implement multiple activities and not limited to: On-page SEO techniques, Off-page SEO techniques, keyword analysis, etc. to optimize your content easily discoverable. Given that there are millions of searches happening every day, it only makes sense to invest in it. Of course, to win the SEO game, you have to be consistent throughout since it is extremely competitive.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/digital-marketing/smm.svg`} alt="SMM"/>
                                    <h2 className="res-dev-key-feature-txt mb-1 pt-2">SMM</h2>
                                    <p className="res-dev-key-feature-excerpt">It is the process of promoting a brand, product, or service through various social media platforms. In recent times, social media marketing has gained a lot of attention due to its potential to reach a wider set of people by simple means. Increase brand awareness? Check. Maintaining customer relationship? Check. Effective content distribution? Check. Social media marketing can also be used to increase your website traffic and sales potential. Informative and entertaining content always finds its way of being shared among the audience.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/digital-marketing/ppc.svg`} alt="PPC"/>
                                    <h2 className="res-dev-key-feature-txt mb-1 pt-2">PPC</h2>
                                    <p className="res-dev-key-feature-excerpt">PPC or pay-per-click is where an advertiser pays a fee to the social platform every time their ad is clicked. This is one of the smartest ways to gain more visits to your website. PPC falls under the paid media efforts of digital marketing. PPC gives you a lot of flexibility and control by letting you reach to your TGA within a short time and ensuring great ROAS. With PPC one can run multiple social media campaigns like Search ads, Display ads, Traffic ads, Conversion ads, and so on, across major digital platforms like Facebook, Google, Twitter, and LinkedIn.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/digital-marketing/email-marketing.svg`} alt="email-marketing"/>
                                    <h2 className="res-dev-key-feature-txt mb-1 pt-2">Email Marketing</h2>
                                    <p className="res-dev-key-feature-excerpt">Email marketing is one of the most effective and cheapest forms of digital marketing. Email marketing gives you an opportunity to measure every detail of a campaign in the form of open rates, clicks, and the number of people who have subscribed for newsletters, etc. Email marketing’s conversion rates are also significantly better. Without having to spend too much out of your budget, you will be able to measure the outcome of each campaign. Email marketing also paves the way to send out targeted messages depending on your audience.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/digital-marketing/content-marketing.svg`} alt="content-marketing"/>
                                    <h2 className="res-dev-key-feature-txt mb-1 pt-2">Content Marketing</h2>
                                    <p className="res-dev-key-feature-excerpt">Content marketing, a sphere that lets you think about what your audience is looking for rather than just mechanically promoting your product or services. By providing relevant and useful content to your audience, you gain their trust. Content marketing is the staple of digital marketing for any business irrespective of its size. Content marketing lets you show your audience and potential customers what you can bring to their table. Instead of considering content marketing, a separate entity, incorporating content marketing into every aspect of digital marketing could add value to your brand. In simple words, valuable content is the king. Content marketing comprises anything like a blog, story, emailers, GIF, video, podcast, or an image that has the potential to add value and go viral.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove digi-market-services-last-align">
                                <div className="res-dev-key-feature-sections">
                                    <h2 className="digi-market-services-last-box-content">Want to acquire customers at almost a slim-to-nil cost?</h2>
                                    <Link to='/contact' className="digi-market-content-button button-gradient">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-serve-you-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 digi-market-mob">
                                <img src={`${Config.HOST}/assets/img/digital-marketing/take-u-off-mob.jpg`} alt="take-u-off-mob" />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-serve-you-container">
                                    <h1 className="digi-market-serve-you-title">How can a digital marketing company serve you?</h1>
                                    <p className="digi-market-serve-you-content">Social media presence can make a huge difference in how your brand is perceived by the audience. A digital marketing company can help you take your products and services to your TGA across the globe efficiently. The growth of any business is at least partially dependent on its digital presence. Competent marketing strategies help you set a digital tone for your brand which will help in targeting the right audience, influencing your buyers, and with an appropriate strategy you can even attain higher conversion rates.</p>
                                    <p className="digi-market-serve-you-content">As we mentioned earlier, digital marketing does take a lot of effort and one cannot ace it without allocating enough time in executing it. That being said, we do know that brands need to spend their core time enhancing and developing their products and service experiences to retain their customers. Therefore, it is important to outsource the digital marketing efforts to a specialized digital marketing company or agency that has social media strategists, creative designers, and content writers who can help you with specialized creative solutions consistently.</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-go-to">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <h1 className="display-add digi-market-go-to-title">Why Alchem Digital is your go-to digital marketing company in Chennai?</h1>
                                <p className="digi-market-go-to-content">At Alchem Digital, we understand the importance of our client’s digital presence and with a team of qualified digital marketers, designers, and creative writers, we identify the best marketing strategy for your brand. Setting a brand tone is something that we are well-versed in. With our affordable bespoke services, we have helped a number of our clients across the globe. Being the best digital marketing company in Chennai, we strive to find the best digital marketing strategy for your company and come up with digital campaigns that we know will work for you.</p>
                                <p className="digi-market-go-to-content-1"><span>Results:</span> It is what we thrive on. Nothing delights us as much as our clients’ success. We consider our clients’ reach our primary goal and work relentlessly to achieve that. No, you do not just have to take our word for it.</p>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-go-to-img">
                                <h1 className="display-remove digi-market-go-to-title">Why Alchem Digital is your go-to digital marketing company in Chennai?</h1>
                                <img className="display-add" src={`${Config.HOST}/assets/img/digital-marketing/go-to-img.jpg`} alt="go-to-img"/>
                                <img className="display-remove" src={`${Config.HOST}/assets/img/digital-marketing/goto-mob.jpg`} alt="go-to-img"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-clients-section">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <h1 className="res-dev-client-content-subtitle mob-text-center">The honour to our journey</h1>
                                    <h2 className="res-dev-client-content-title mob-text-center">Our valued<br/> clients</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 new-padd-left-add">
                                {typeof window !== 'undefined' && 
                                    <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                {typeof window !== 'undefined' &&
                                    <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-mega-container-1">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                </OwlCarousel>
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Want to acquire customers at almost a slim-to-nil cost?</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#digi-market-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Drop in your deets</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="digi-marketing-technologies">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Facebook Ads Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Twitter Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">LinkedIn Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Google Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Google Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-6">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Tag Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-7">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Taboola</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-8">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Hootsuite</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-9">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Trello</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-10">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Creator Studio</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top Digital marketing company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>What are some of the most common forms of digital marketing?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>A digital marketing company typically relies on social media marketing, search engine optimization, paid media marketing, and content marketing for their client's digital marketing needs.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>What are some of the benefits of digital marketing?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>Some of the prominent benefits of digital marketing are that you can reach a wider set of audiences, increase your follower base, cost efficiency, improve sales and measurable results.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>What are the social media platforms that we use for marketing?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Some of the social media platforms that we use for marketing purposes include Facebook, Instagram, YouTube, LinkedIn, and Twitter. We also work on Google ad campaigns.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>How can search engine optimization help your company?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>SEO practices are one of the cheapest ways to drive traffic to your websites. SEO is also one of the ways in which you can build your brand’s credibility. By ranking on top of a search engine, you drive more traffic to your website which will help in increasing its engagement which will, in turn, lead to a higher conversion rate.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSix">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSix" aria-expanded="false" aria-controls="newcollapseSix">
                                                <span>What is paid media marketing?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSix" className="collapse" aria-labelledby="newheadingSix" data-parent="#newaccordion">
                                        <div className="card-body">
                                        <p>Paid media marketing is the other way of bringing in customers to engage with your posts, amplify reach, drive website traffic & conversions, increase app downloads, and building your follower base by bidding and paying the social media platforms like Google and Facebook to help achieve the marketing objective.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSeven">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSeven" aria-expanded="false" aria-controls="newcollapseSeven">
                                                <span>How has the Coronavirus pandemic impacted digital marketing?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSeven" className="collapse" aria-labelledby="newheadingSeven" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>The surge in digital ad spending has let Facebook’s revenue surge 48%. Yes, just Facebook. The pandemic has created a huge change in consumer behavior and demands. People are now more open to accepting digital trends which have paved a way for a lot of change in curating a marketing strategy. With the increased competition, every digital marketing company is looking for a way to stand out from the crowd. Localization and Vernacular communication are something that a lot of digital media houses are focusing on now.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingEight">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseEight" aria-expanded="false" aria-controls="newcollapseEight">
                                                <span>How long does it take for digital marketing to work for your company?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseEight" className="collapse" aria-labelledby="newheadingEight" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Multiple factors beginning from your type of industry, competitors, ad spends, consistency, timing, and most importantly execution plays a major role here. Since digital is a democratized space, it has a ton of competitors. However, for digital marketing to work, one has to be there for the long haul while you can visibly see growth in a few weeks/months as well.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingNine">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseNine" aria-expanded="false" aria-controls="newcollapseNine">
                                                <span>How long does it take to rank on the first page of a search engine?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseNine" className="collapse" aria-labelledby="newheadingNine" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>It should take anywhere between four to six months for your website to rank right on top of the Google SERP. Multiple things need to be done right consistently, such as keyword research, competitor analysis, website performance, on-page, off-page optimizations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTen">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTen" aria-expanded="false" aria-controls="newcollapseTen">
                                                <span>Is digital marketing expensive?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseTen" className="collapse" aria-labelledby="newheadingTen" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Not really. Digital marketing is extremely flexible and cost-efficient. Digital marketing lets you reach the exact TGA, identify what works with them, and come up with strategies that will increase your engagement and revenue potential. In short, the chances of ROAS are higher in digital marketing.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingEleven">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseEleven" aria-expanded="false" aria-controls="newcollapseEleven">
                                                <span>Do I need to create social media handles for my brand for digital marketing?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseEleven" className="collapse" aria-labelledby="newheadingEleven" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Yes, of course. It is fundamental to create social media handles for your brand to carry out digital communication and promotions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default DigitalMarketing