import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import FloatingIcons from '../FloatingIcons'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import BlogSidebar from './BlogSidebar'
import Config from './Config'
import parse from 'html-react-parser';
import CookiePolicy from "./CookiePolicy"
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton'
const readingTime = require('reading-time');

// import PageTransition from '../PageTransition'
class Blog extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            blogs: [],
            featuredPosts: [],
            next: null,
            previous : null
        }
    }

    componentDidMount = () => {
        Config.axios({
            url: `${Config.API_URL}/api/user/post/list`,
            success: (res) => {
                this.setState({
                    blogs: res.data.results,
                    featuredPosts: res.data.results.slice(0, 3),
                })
                if (res.data.next != null) {
                    this.setState({
                        next: res.data.next.split("=")[1]
                    })
                }
                if(res.data.previous != null){
                    this.setState({
                        previous:res.data.previous.split("=")[1]
                    })
                }
            },
        })
    }

    fetchMoreData = () => {
        Config.axios({
            url: `${Config.API_URL}/api/user/post/list?page=${this.state.next}`,
            success: (res) => {
                // console.log(res)
                this.setState({
                    blogs: this.state.blogs.concat(res.data.results),
                    // featuredPosts: res.data.results.slice(0, 3),
                })
                if (res.data.next != null) {
                    this.setState({
                        next: res.data.next.split("=")[1]
                    })
                }else{
                    this.setState({
                        next: null
                    })
                }
            }
        })
    }

    render(){
        return(
            <React.Fragment>
                <HeadTags path='blog'/>
                <Header/>
                <section className="ad-blog-bg">
                    <div className="blog-bg-img">
                        <div className="ad-portfolio-main-container">
                            <div className="ad-portfolio-pages-hero-section">
                                <div className="ad-bread-crumbs">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb blog-breadcrumb-arrow">
                                            <li className="blog-breadcrumb-link-color breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="blog-breadcrumb-color breadcrumb-item active" aria-current="page">Blog</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div>
                                    <p className="ad-about-main-title ad-blog-main-title-color">Blog</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-section-part">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-3">
                                <BlogSidebar/>
                            </div>
                            <div className="col-xs-12 col-sm-9">
                                <div className="blog-post-wrapper">
                                    {this.state.blogs.length > 0 ? (
                                        <InfiniteScroll
                                            dataLength={this.state.blogs.length}
                                            next={this.fetchMoreData}
                                            hasMore={this.state.next !== null ? true : false}
                                            loader={<span>Loading...</span>}
                                        >
                                            {
                                                this.state.blogs.map((blog) => (
                                                    <React.Fragment>
                                                        <Link to={'/blog/' + blog.slug} >
                                                            <img className="blog-thumbnail" src={blog.banner_image} alt={blog.title} />
                                                        </Link>
                                                        <div className="post-contents">
                                                            <div className="post-excerpt-wrapper">
                                                                <Link to={'/blog/' + blog.slug} className="post-title">{blog.title}</Link>
                                                                <p className="post-excerpt">{blog.content.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                                                                <p><span>{new Date(blog.created_at).toLocaleString('en-us', { month: 'short' }) + ' ' + new Date(blog.created_at).getDate() + ', ' + new Date(blog.created_at).getFullYear()}</span><span className="ml-4"><span className="mr-2"><i class="far fa-clock"></i></span>{readingTime(blog.content.replace(/<\/?[^>]+(>|$)/g, "")).text}</span></p>
                                                            </div>
                                                            <div className="post-read-more">
                                                                <Link to={'/blog/' + blog.slug} className="post-read-more-btn">Read more</Link>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </InfiniteScroll>
                                    )
                                    :
                                    (
                                        <>
                                            <Skeleton height={'432px'} style={{marginBottom: 10}} />
                                            <Skeleton />
                                            <Skeleton count={3}/>
                                            <Skeleton height={'42px'} width={'142px'}/>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <FloatingIcons/> */}
                <CookiePolicy />
            </React.Fragment>
        );
    }
}

export default Blog