import React, {Component} from 'react'

class ScrollToTopBtn extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function(e) {
            scrollComponent.toggleVisibility();
        });
    }

    toggleVisibility() {
        if (window.pageYOffset > 500) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render(){
        const { is_visible } = this.state;
        return(
            <React.Fragment>
                <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0" className="back-to-top">
                    {is_visible && (
                    <a href="javascript:void(0);" onClick={() => this.scrollToTop()}>
                        <i class="fas fa-chevron-up"></i>
                    </a>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default ScrollToTopBtn