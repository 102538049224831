import React, { Component } from 'react'

export default class Progress extends Component {
  render(props) {
    return (
      <>
        <div className="blog-progress" style={{ width: `${this.props.scroll}`}}>
        </div>
      </>
    )
  }
}

