import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
// import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
// import classnames from 'classnames'
// const [TabContent, TabPane, Nav, NavItem, NavLink] = (typeof window !== 'undefined') ? require('reactstrap') : []

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class YouTubeMarketing extends React.Component{

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         activeTab: '1',
    //     }
    // }

    // toggle = (tab) => {
    //     if (this.state.activeTab !== tab) {
    //         this.setState({
    //             activeTab: tab
    //         });
    //     }
    // }

    componentDidMount(){
        $('.nav-tabs-dropdown')
        .on("click", "a:not('.active')", function(event) {  
            $(this).closest('div').removeClass("open");
        })
        .on("click", "a.active", function(event) {        
            $(this).closest('div').toggleClass("open");
        });

        var parallax = -0.6;
        var $bg_images = $(".res-dev-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });
    }

    render(){
        return(
            <React.Fragment>
                <HeadTags path='youtube-marketing' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="youtube-mark-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">YouTube Marketing Services in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">Promote your business on YouTube with one of the best YouTube Marketing Services company in Chennai</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/youtube/youtube-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">Why you should use YouTube for Marketing?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>With YouTube being the most visited streaming service and the second most visited site in the world with approximately over 2.3 billion users, YouTube can be the optimal option for your marketing needs. Video ads tend to have higher engagement if done right. Videos can be one of the most engaging marketing forms, most importantly when it comes to informational and educational content.</p>
                                        <p>With the increasing usage of mobile platforms, leveraging YouTube for your marketing purposes can help in reaching a wider set of audience/potential customers and increasing brand awareness. YouTube also gives you the opportunity to reach your target audience and identify what works with them, letting you produce content accordingly. Videos can be one of the most entertaining forms of marketing and also has the ability to make your customers understand your brand, product, and services better and you know where to go to get the best of YouTube Marketing.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Advantage of YouTube Marketing</h1>
                        <div className="digi-benefits-align">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Reach a Bigger Audience</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">Quality Traffic</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Target Audience</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Track Your Performance</a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/youtube/youtube-adv-1.svg`} alt="youtube-adv"/>
                                                <h2 className="digi-market-title-benefit">Reach a Bigger Audience</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">By creating valuable and consistent content can help your brand reach a wide range of audiences from across the world. Apart from reaching a wider audience, YouTube also lets you understand what your customers are looking for from you and produce content that you know will work with them.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/youtube/youtube-adv-2.svg`} alt="youtube-adv"/>
                                                <h2 className="digi-market-title-benefit">Quality Traffic</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">While the quantity of your traffic is important, you need quality traffic to see any form of conversion. YouTube can help you get qualified traffic as people land on your video because they believe it could be beneficial to them.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/youtube/youtube-adv-3.svg`} alt="youtube-adv"/>
                                                <h2 className="digi-market-title-benefit">Target Audience</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">YouTube lets you target your ad to a specific audience based on their location, affinity, search behaviour, interests, etc. Instead of misspending your time and resources on people who are disinterested in your brand, you can target your ads on people who are actually interested in your products.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/youtube/youtube-adv-4.svg`} alt="youtube-adv"/>
                                                <h2 className="digi-market-title-benefit">Track Your Performance</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">It is important to keep track of your YouTube marketing efforts, identify what works with your audience and make appropriate changes. YouTube analytics lets you keep track of several aspects of marketing like conversion rates, engagement, views, watch time, etc.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="yt-marketing-serve">
                    <div className="container">
                        <h1 className="yt-marketing-services-title">Our YouTube Marketing Services</h1>
                        <div className="yt-tabs">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="pills-organic-promot-tab" data-toggle="pill" href="#pills-organic-promot" role="tab" aria-controls="pills-organic-promot" aria-selected="true">
                                        <h4 className="yt-tab-title">Organic Promotion</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-pmm-tab" data-toggle="pill" href="#pills-pmm" role="tab" aria-controls="pills-pmm" aria-selected="false">
                                        <h4 className="yt-tab-title">Paid Media Marketing</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active active-anim" id="pills-organic-promot" role="tabpanel" aria-labelledby="pills-organic-promot-tab">
                                    <div className="yt-tab-cont">
                                        <div className="yt-tab-row mt-5">
                                            <div className="yt-marketing-services-card">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-1.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>YouTube SEO</h4>
                                                <p>We optimize your channel, title, thumbnail, descriptions, etc. to rank your videos on the top of the YouTube search engine. Through our thorough YouTube keyword research and competitor analysis, we make your videos visible on YouTube. We also optimize your video for Google search</p>
                                            </div>
                                            <div className="yt-marketing-services-card">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-2.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Channel Management and Optimization</h4>
                                                <p>Our YouTube management and optimization services will help you gain more subscribers and engage your customers with entertaining and informative content. We help you reach a wide range of audiences and take your products and services to a new set of customers</p>
                                            </div>
                                            <div className="yt-marketing-services-card">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-3.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Thumbnail Optimization</h4>
                                                <p>Thumbnails play a major role when it comes to your video’s organic visibility. Your thumbnail needs to be engaging enough to have a viewer click on it. We optimize your videos’ thumbnails by maintaining consistency across videos, following trends, and find out what your competitors are doing</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-4.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Analytics and Reporting</h4>
                                                <p>YouTube analytics lets you keep track of your performance of your YouTube marketing. YouTube analytics can help you know what works for your brand and ultimately accentuate your YouTube presence. We will produce timely reports with which we analyse your performance and create content which we know will work with your audience.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-5.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Trend Mapping and Content Ideas</h4>
                                                <p>We stay on top of trends and make sure that your YouTube content is trendy and engaging. We identify what works for your brand and come up with content ideas that we know will work with your target audience and ultimately increase views and engagement.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-6.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Community Management</h4>
                                                <p>Community management is an important part of YouTube marketing. We make sure that we engage with your audience regularly and analyse feedback from the audience. Our community management services can help you increase subscribers and interaction with the audience.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-7.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Video Optimization</h4>
                                                <p>Multiple factors determine how your video ranks on YouTube. Your video needs to be relevant and consistent to get better engagement on YouTube. We make sure we optimize your videos and gain more views and we also build a call to action (CTA) to increase your brand awareness.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-8.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Video Transcriptions</h4>
                                                <p>Video transcriptions come in handy in ranking your videos on top of the search engine. Video transcriptions make it easy for the search engine to rank your video better. Video transcriptions help in video SEO, increases user experience, and more.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-pmm" role="tabpanel" aria-labelledby="pills-pmm-tab">
                                    <div className="yt-tab-cont">
                                        <div className="yt-tab-row mt-5">
                                            <div className="yt-marketing-services-card">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-1.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Skippable In-stream Ads</h4>
                                                <p>Typically played before, during, or after a video, skippable ads give users an option to skip your ad after five seconds. Skippable ads are ideal for getting leads, increasing website traffic, brand awareness, and overall reach.</p>
                                            </div>
                                            <div className="yt-marketing-services-card">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-2.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Non-Skippable In-stream ads</h4>
                                                <p>Non-skippable ads are typically used to increase brand awareness. These ads tend to have a higher CPM (Cost per Mile) when compared to other ad forms as it does not give the users an option to skip.</p>
                                            </div>
                                            <div className="yt-marketing-services-card">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-3.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Bumper Ads</h4>
                                                <p>Bumper ads are six seconds long and are non-skippable. It is important to grab the attention of the viewers within the span of these six seconds and this ad form is mostly used to promote a product or service.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-4.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Discovery Ads</h4>
                                                <p>Discovery ads are displayed on the search result page of YouTube. Discovery ads are said to have a higher Return of Investment (ROI) since people who are drawn by your thumbnail or title tend to click on the ad which signifies the users’ interest in your brand/product. Discovery ads can also help in promoting your channel.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-5.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Banner Ads</h4>
                                                <p>Banner ads are the ones that occupy 20% of the video player. Banner ads can be useful to increase brand awareness.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-6.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Outstream Ads</h4>
                                                <p>Outstream ads are mobile-only ads that help in increasing brand awareness. Outstream ads are skippable and can help you reach potential customers.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-7.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>Masthead Ads</h4>
                                                <p>With masthead ads, your ads appear on the YouTube Home and are typically used to display your products and services that you offer and increase brand awareness.</p>
                                            </div>
                                            <div className="yt-marketing-services-card yt-tab-cont-1">
                                                <div className="yt-gradient-circle">
                                                    <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-8.svg`} alt="yt-organic-promo"/>
                                                </div>
                                                <h4>YouTube Ad Sequence</h4>
                                                <p>Ad sequencing on YouTube lets you tell your brand story in a series of ads. There are several sequence structures that can be used and we identify the right one for your brand and make sure we make your advertisements memorable and recallable.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {typeof window != 'undefined' &&
                                (
                                    <>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink className={classnames({ YtActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                                    <h4 className="yt-tab-title">Organic Promotion</h4>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ YtActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                                    <h4 className="yt-tab-title">Paid Media Marketing</h4>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId="1">
                                                <div className="yt-tab-cont">
                                                    <div className="yt-tab-row mt-5">
                                                        <div className="yt-marketing-services-card">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-1.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>YouTube SEO</h4>
                                                            <p>We optimize your channel, title, thumbnail, descriptions, etc. to rank your videos on the top of the YouTube search engine. Through our thorough YouTube keyword research and competitor analysis, we make your videos visible on YouTube. We also optimize your video for Google search</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-2.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Channel Management and Optimization</h4>
                                                            <p>Our YouTube management and optimization services will help you gain more subscribers and engage your customers with entertaining and informative content. We help you reach a wide range of audiences and take your products and services to a new set of customers</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-3.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Thumbnail Optimization</h4>
                                                            <p>Thumbnails play a major role when it comes to your video’s organic visibility. Your thumbnail needs to be engaging enough to have a viewer click on it. We optimize your videos’ thumbnails by maintaining consistency across videos, following trends, and find out what your competitors are doing</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-4.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Analytics and Reporting</h4>
                                                            <p>YouTube analytics lets you keep track of your performance of your YouTube marketing. YouTube analytics can help you know what works for your brand and ultimately accentuate your YouTube presence. We will produce timely reports with which we analyse your performance and create content which we know will work with your audience.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-5.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Trend Mapping and Content Ideas</h4>
                                                            <p>We stay on top of trends and make sure that your YouTube content is trendy and engaging. We identify what works for your brand and come up with content ideas that we know will work with your target audience and ultimately increase views and engagement.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-6.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Community Management</h4>
                                                            <p>Community management is an important part of YouTube marketing. We make sure that we engage with your audience regularly and analyse feedback from the audience. Our community management services can help you increase subscribers and interaction with the audience.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-7.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Video Optimization</h4>
                                                            <p>Multiple factors determine how your video ranks on YouTube. Your video needs to be relevant and consistent to get better engagement on YouTube. We make sure we optimize your videos and gain more views and we also build a call to action (CTA) to increase your brand awareness.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-organic-promo-8.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Video Transcriptions</h4>
                                                            <p>Video transcriptions come in handy in ranking your videos on top of the search engine. Video transcriptions make it easy for the search engine to rank your video better. Video transcriptions help in video SEO, increases user experience, and more.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="yt-tab-cont">
                                                    <div className="yt-tab-row mt-5">
                                                        <div className="yt-marketing-services-card">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-1.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Skippable In-stream Ads</h4>
                                                            <p>Typically played before, during, or after a video, skippable ads give users an option to skip your ad after five seconds. Skippable ads are ideal for getting leads, increasing website traffic, brand awareness, and overall reach.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-2.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Non-Skippable In-stream ads</h4>
                                                            <p>Non-skippable ads are typically used to increase brand awareness. These ads tend to have a higher CPM (Cost per Mile) when compared to other ad forms as it does not give the users an option to skip.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-3.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Bumper Ads</h4>
                                                            <p>Bumper ads are six seconds long and are non-skippable. It is important to grab the attention of the viewers within the span of these six seconds and this ad form is mostly used to promote a product or service.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-4.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Discovery Ads</h4>
                                                            <p>Discovery ads are displayed on the search result page of YouTube. Discovery ads are said to have a higher Return of Investment (ROI) since people who are drawn by your thumbnail or title tend to click on the ad which signifies the users’ interest in your brand/product. Discovery ads can also help in promoting your channel.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-5.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Banner Ads</h4>
                                                            <p>Banner ads are the ones that occupy 20% of the video player. Banner ads can be useful to increase brand awareness.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-6.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Outstream Ads</h4>
                                                            <p>Outstream ads are mobile-only ads that help in increasing brand awareness. Outstream ads are skippable and can help you reach potential customers.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-7.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>Masthead Ads</h4>
                                                            <p>With masthead ads, your ads appear on the YouTube Home and are typically used to display your products and services that you offer and increase brand awareness.</p>
                                                        </div>
                                                        <div className="yt-marketing-services-card yt-tab-cont-1">
                                                            <div className="yt-gradient-circle">
                                                                <img src={`${Config.HOST}/assets/img/youtube/yt-pmm-8.svg`} alt="yt-organic-promo"/>
                                                            </div>
                                                            <h4>YouTube Ad Sequence</h4>
                                                            <p>Ad sequencing on YouTube lets you tell your brand story in a series of ads. There are several sequence structures that can be used and we identify the right one for your brand and make sure we make your advertisements memorable and recallable.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </>
                                )
                            } */}
                        </div>
                    </div>
                </section>
                <section className="res-dev-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title" style={{ fontStyle: "normal" }}>To Entertain, Engage and Excel</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#digi-market-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Reach us</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="yt-achieve-content">
                    <div className="container">
                        <h1>What can we help you achieve?</h1>
                        <div className="row">
                            <div className="col-xs-12 col-sm-4">
                                <div className="yt-achieve-content-part">
                                    <img src={`${Config.HOST}/assets/img/youtube/yt-achieve-1.svg`} />
                                    <h4 className="pt-3">Increase Subscribers</h4>
                                    <p>With our quality content, we increase views to your video thereby increasing the number of subscribers to the channel.</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div className="yt-achieve-content-part">
                                    <img src={`${Config.HOST}/assets/img/youtube/yt-achieve-2.svg`} />
                                    <h4 className="pt-3">Promote Your Brand</h4>
                                    <p>We help you optimize your YouTube marketing thereby increasing your brand’s visibility across the world</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <div className="yt-achieve-content-part">
                                    <img src={`${Config.HOST}/assets/img/youtube/yt-achieve-3.svg`} />
                                    <h4 className="pt-3">Better ROI</h4>
                                    <p>Through our effective YouTube marketing strategies, we help you gain a better Return on Investment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container-2">
                                    <h1 className="digi-market-zig-zag-title">Why Choose Alchem Digital for YouTube Marketing Services?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>At Alchem Digital, we understand the individuality and brand tone of each business and come up with YouTube marketing strategies that we know will work for you. We create engaging content and optimize your videos for better views and engagement. We stay up-to-date with YouTube’s algorithm and also optimize your videos for the search engine.</p>
                                        <p>As a thriving digital marketing company, we help you enhance your YouTube presence and ultimately increase your brand’s awareness. Our team works relentlessly to understand what works for your brand among your customers. We keep track of your channel’s performance and implement necessary strategies to enhance YouTube presence and ultimately get you better ROI.</p>
                                        <Link to="/portfolio" className="res-dev-call-to-action-button button-gradient transition-issue" >Our Works</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/youtube/youtube-2.jpg`} alt="youtube"/>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="digi-marketing-technologies">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Facebook Ads Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Twitter Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">LinkedIn Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Google Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Google Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-6">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Tag Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-7">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Taboola</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-8">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Hootsuite</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-9">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Trello</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-10">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Creator Studio</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top YouTube Marketing company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>Is YouTube marketing necessary?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>With the increasing use of mobile platforms, YouTube could be one of the most effective marketing tools. YouTube marketing lets you reach your target audience effectively and in turn gain qualified traffic serving you better ROI.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>What form of YouTube ad will work for me?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>Depending on what you are trying to achieve through your YouTube ad, we can choose an ad form that will be ideal for your goals and brand. Feel free to drop your details <span><Link to="/contact">here</Link></span>.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>How can you increase subscribers for my channel?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>We create engaging and informational content which we then promote relentlessly to gain more viewers. We stay on top of the trends on YouTube and implement strategies that will increase subscribers for your channel.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>Is YouTube marketing costly?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Nope! YouTube marketing is actually the most cost-effective video marketing platform when compared to other social media platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSix">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSix" aria-expanded="false" aria-controls="newcollapseSix">
                                                <span>Is YouTube marketing effective?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSix" className="collapse" aria-labelledby="newheadingSix" data-parent="#newaccordion">
                                        <div className="card-body">
                                        <p>Yes! YouTube has a lot of users and with its ability to reach a wider set of audience, YouTube marketing can be effective to increase brand awareness and gain quality traffic and even sales.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default YouTubeMarketing