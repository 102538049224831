import React, { Component } from 'react'

import Config from './Config'
import Header from '../layout/Header'
import axios from "axios"

export default class CreativeLogin extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: '',
			password: ''
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()
		axios.post(`${Config.API_URL}/api/user/login`, {
			email: this.state.email,
			password: this.state.password,
		}).then(
			(response) => {
				if (response.status === 200) {
					let userData = {
						isAdminLoggedIn: true,
						name: response.data.data.username,
						email: response.data.data.email,
						token: response.data.data.token
					};
					localStorage.removeItem('userState');
					localStorage["userState"] = JSON.stringify(userData)
					window.location.href = "/admin";
				}
			},
			(error) => {
				console.log(error);
			}
		);
	}

	render() {
		return (
			<>
			<Header/>
				<div className="container my-5 ad-about-bg">
					<div className="card">
						<div className="card-body">
							<form onSubmit={this.handleSubmit}>
								<div className="form-group">
									<label htmlFor="email">Email</label>
									<input
										type="text"
										name="email"
										className="form-control"
										value={this.state.email}
										onChange={this.handleChange}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="email">Password</label>
									<input
										type="password"
										name="password"
										className="form-control"
										value={this.state.password}
										onChange={this.handleChange}
									/>
								</div>
								<div className="creative-submit">
									<button className="btn btn-primary">Login</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}
}
