import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class ContentMarketing extends React.Component{


    componentDidMount(){
        $('.nav-tabs-dropdown')
        .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        })
        .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        });

        var parallax = -0.6;
        var $bg_images = $(".res-dev-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });
    }

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='content-marketing' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="content-mark-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <h1 style={{ lineHeight: 1.2 }} className="digi-mark-main-banner-title">Content Marketing Services in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle pt-4">The right content marketing strategy can help you increase engagement, brand awareness, and conversion potential. Alchem Digital, a leading content marketing agency in Chennai can help you create a content marketing strategy that converts.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/content/content-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">What is Content Marketing?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>Simply put, content marketing means creating and promoting content that is catered to your target audience. The content marketing process involves ideating a content strategy that will increase engagement, promote the content and track the performance of your marketing strategies which will increase the visibility of your brand to attract your audience and potential customers.</p>
                                        <p>Valuable content is an essential part of any marketing strategy. Effective content marketing strategy can be beneficial for any form of marketing. Content marketing can help you in the long run by letting you build trust among your target audience by providing them relevant content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container-2">
                                    <h1 className="digi-market-zig-zag-title">Why is Content Marketing Important?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>What are your customer’s expectations from you? Content marketing is one of the best ways to identify what your customers are looking for from you. High-quality and relatable content are the backbones of digital marketing. Content marketing can be one of the most effective ways to increase your brand awareness and brand positioning. Building trust with your audience is important to attract customers and content marketing is one of the best ways to do it. Creating and promoting relatable and relevant content will establish your dominance in the field, making your customers trust you better. Content marketing is the optimal way to increase your brand awareness.</p>
                                        <p>Lead generation is an important part of any business as it increases sales and the number of organic customers you have. Your aim of turning your leads into customers will be an achievable feat if you have the right content marketing strategies with you. Having an engaging digital presence, be it your website or social media platforms, increases the chances of turning a lead into a customer. You could have the best of designs, tools, and marketing strategies, but all of those will be in vain without a good content marketing strategy.</p>
                                        <p>As we always believe in, valuable content is the king.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/content/content-2.jpg`} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Benefits of Leveraging a Content <br/> Marketing Agency</h1>
                        <div className="digi-benefits-align">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">Improves Your Google Search Ranking</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">Increased Visibility</a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Increase Brand Awareness</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Better Conversion Potential</a>
                                <a className="nav-link" id="b-customer-retention-tab" data-toggle="pill" href="#customer-retention-tab" role="tab" aria-controls="customer-retention-tab" aria-selected="false">Customer Retention</a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/content/content-benefits-1.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Improves Your Google Search Ranking</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Having keyword optimized content, be it blogs, landing pages, and more can help you enhance your SEO efforts and rank on the top of the search engine thereby increasing the quantity and quality of your traffic. Your chance to convert your prospects to customers increases as your rank higher on the search engine. Therefore, having optimized content on your website that fulfills search intent and meets SEO practices is beneficial.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/content/content-benefits-2.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Increased Visibility</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Having an efficient content marketing strategy is the key to boost your website traffic. The right content marketing strategy will let you leverage organic search for your benefits. Adapting the right strategy can also help you rank on the top of the search engine result page (SERP). Your brand’s visibility can also be increased by promoting social media content that is innovative and informative as it can influence your audience to know more about your brand.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/content/content-benefits-3.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Increase Brand Awareness</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Promoting content that will be beneficial to your customers can help you increase your brand awareness and build credibility. As we mentioned earlier, sharing useful content can establish your dominance in the field and help your customers build trust. Providing well-researched content to your audience can help you grab their attention which will influence them to purchase.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/content/content-benefits-4.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Better Conversion Potential</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">While the primary aim of content marketing is to increase engagement and brand awareness, using the right strategy can also help you gain better conversion rates. Content marketing is one of the most cost-effective ways for you to attract your potential customers and retain your audience. Providing solutions by producing specialized content that your followers and prospects want to consume increases the chances of turning your leads to customers.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="customer-retention-tab" role="tabpanel" aria-labelledby="b-customer-retention-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/content/content-benefits-5.svg`} alt="content-benefits"/>
                                                <h2 className="digi-market-title-benefit">Customer Retention </h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">Retaining your audience and customer is made easier when you consider content marketing an important part of your digital marketing strategy. Content marketing gives you an opportunity to produce and engage with customers with different kinds of content that will cater to various audiences' interests. Catering to your audiences’ needs can also help them make a purchase decision quickly. The best way to achieve customer retention is to keep your audience engaged through interesting content. Increasing engagement with your audience through content can elevate your brand’s identity and also enhance customer loyalty.  </p>
                                        <p className="digi-benefits-content">Provide your audience with ‘valuable’ content. <Link to="/contact">Reach out</Link> to us now.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Content Marketing Services We Provide</h1>
                        <div className="res-dev-key-features-container digi-market-mega-cont">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-1.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Blog Post Creation</h2>
                                    <p className="res-dev-key-feature-excerpt">Blogs are one of the best ways for you to share valuable, informative, and interesting content with your audience. Creating blogs that are relevant also has a way of increasing traffic to your website. Having a blog is one of the most ideal ways for you to build trust among your audience. Blogs can also be the best means for you to stay new and updated through thorough competitor analysis and implementing trends.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-2.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Social Media Posts</h2>
                                    <p className="res-dev-key-feature-excerpt">With social media becoming a huge part of the digital world, it only makes sense for you to make the right use of this platform. Social media marketing gives you an opportunity to grab the attention of your audience in a short span of time. Therefore, it is important to get your message across to your audience most effectively and efficiently possible.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-3.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">SEO Writing</h2>
                                    <p className="res-dev-key-feature-excerpt"><Link to="/seo-company-in-chennai">SEO</Link> writing is a niche technique that requires you to understand the search engine algorithm and the search intent of your audience. Quality content and keyword density are essential factors to rank better on the search engine. Valuable content and the optimum SEO techniques are the ideal techniques to master content marketing.  </p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-4.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Product Description</h2>
                                    <p className="res-dev-key-feature-excerpt">One of the key elements to write a product description that sells is understanding the buyer persona. Your product description tells your potential customer its value for them. An effective product description will show a product’s benefits and features to the customers which will compel them to buy a product.</p>
                                </div>
                            </div>
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-5.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Website & Landing Page Content Writing</h2>
                                    <p className="res-dev-key-feature-excerpt">Effective content can help you retain your customers and also increase your engagement and conversions and this means your landing page too. An optimized landing page can lead a prospect to your services and influence them to take an action. The right website content will be engaging, precise and will integrate the best SEO practices while also having your brand persona in consideration leading to better conversions.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-6.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Infographics</h2>
                                    <p className="res-dev-key-feature-excerpt">You eat with your eyes first and that is applicable in the digital world too. Infographics have the potential to attract readers and retain their attention. Infographics are one of the most effective ways through which you can share valuable information with your audience.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections">
                                    <img src={`${Config.HOST}/assets/img/content/content-serve-7.svg`} alt="content-services"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-3">Video Content</h2>
                                    <p className="res-dev-key-feature-excerpt">Video marketing is one of the most efficient ways for you to deliver short, engaging, and informative content to your audience. We strategize and create engaging video content that grabs your audience's attention be it explainer videos, product demonstrations, DIYs, etc</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove digi-market-services-last-align">
                                <div className="res-dev-key-feature-sections">
                                    <h2 className="digi-market-services-last-box-content">What services are you looking for?</h2>
                                    <Link to='/contact' className="digi-market-content-button button-gradient">Reach out to us now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="res-dev-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Engage and influence your prospects</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#digi-market-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Ping us now</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-go-to">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <h1 className="display-add digi-market-go-to-title">Why Alchem Digital is the Best Content Marketing Agency in Chennai?</h1>
                                <p className="digi-market-go-to-content">At Alchem Digital, we constantly work towards increasing engagement through our combined efforts of creating great content, customized designs, and relentlessly promoting them. We extend our content marketing services and strategies to various platforms and implement strategies according to each platform. We understand your brand’s voice and find the best methods to take that across to your audience and potential customers.</p>
                                <p className="digi-market-go-to-content">We create a strategy that we know will attract customers and increase the visibility of your brand. We create a content strategy with your brand tone and target audience in mind. We promote your content on different platforms, measure performance and tweak the content depending on the success and make sure that the content strategy attracts customers. Most importantly, we believe that valuable content is the king and therefore provide you with the best content marketing services and strategies possible.</p>
                                <p className="digi-market-go-to-content">For creating optimized content that converts, <Link to="/contact">contact us</Link> now.  </p>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-go-to-img">
                                <h1 className="display-remove digi-market-go-to-title">Why Alchem Digital is the Best Content Marketing Agency in Chennai?</h1>
                                <img src={`${Config.HOST}/assets/img/content/content-3.jpg`} alt="content"/>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="digi-marketing-technologies">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Facebook Ads Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Twitter Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">LinkedIn Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Google Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Google Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-6">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Tag Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-7">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Taboola</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-8">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Hootsuite</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-9">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Trello</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-10">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Creator Studio</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default ContentMarketing