import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Success from '../Animations/Success'
import Header from '../layout/Header'


class MailSuccess extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="container ad-about-bg not-found-bg">
                    <div className="not-found-mega-container">
                        <Success />
                        <div className="not-found-content-part">
                            <h1 className="not-found-text">Thank you! We have received your enquiry.</h1>
                            <p className="not-found-text-1">We will get back to you at the earliest.</p>
                            <Link className="alchem-digi-button-new button-gradient not-found" to="/"><span>Back to Home</span></Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MailSuccess