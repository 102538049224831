import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import Config from '../pages/Config'


class Header extends React.Component{

    componentDidMount(){
        $('#nav-icon4').click(function(){
            $(this).toggleClass('open');
            $('.mob-list-cont').toggleClass('mobile-list-toggle-open');
            $('.mob-list-cont').removeClass('mobile-list-toggle');
            if ($("#nav-icon4").hasClass("open")){
            $(this).css('height','45px');
            }
            else{
                $(this).css('height','20px');
            }
        });

        $('.ad-menu-caret').click(function(){
            $(this).toggleClass('active');
            $('.mobile-submenu').toggleClass('mob-submenu-toggle');
            if ($(this).hasClass("active")){
                $('.ad-menu-caret .mob-menu-minus').show();
                $('.ad-menu-caret .mob-menu-plus').hide();
            }
            else{
                $('.ad-menu-caret .mob-menu-minus').hide();
                $('.ad-menu-caret .mob-menu-plus').show();
            }
        });

        $('.ad-menu-caret-1').click(function(){
            $(this).toggleClass('active');
            $('.ad-mob-menu-links-cont-1').toggleClass('mob-submenu-toggle');
            if ($(this).hasClass("active")){
                $('.ad-menu-caret-1 .mob-menu-minus').show();
                $('.ad-menu-caret-1 .mob-menu-plus').hide();
            }
            else{
                $('.ad-menu-caret-1 .mob-menu-minus').hide();
                $('.ad-menu-caret-1 .mob-menu-plus').show();
            }
        });

        $('.ad-menu-caret-2').click(function(){
            $(this).toggleClass('active');
            $('.ad-mob-menu-links-cont-2').toggleClass('mob-submenu-toggle');
            if ($(this).hasClass("active")){
                $('.ad-menu-caret-2 .mob-menu-minus').show();
                $('.ad-menu-caret-2 .mob-menu-plus').hide();
            }
            else{
                $('.ad-menu-caret-2 .mob-menu-minus').hide();
                $('.ad-menu-caret-2 .mob-menu-plus').show();
            }
        });

        $('.ad-menu-caret-3').click(function(){
            $(this).toggleClass('active');
            $('.ad-mob-menu-links-cont-3').toggleClass('mob-submenu-toggle');
            if ($(this).hasClass("active")){
                $('.ad-menu-caret-3 .mob-menu-minus').show();
                $('.ad-menu-caret-3 .mob-menu-plus').hide();
            }
            else{
                $('.ad-menu-caret-3 .mob-menu-minus').hide();
                $('.ad-menu-caret-3 .mob-menu-plus').show();
            }
        });

        $('.mob-submenu-text-links').click(function(){
            $('.mob-list-cont').removeClass('mobile-list-toggle-open');
            $('.mob-list-cont').addClass('mobile-list-toggle');
            $('#nav-icon4').toggleClass('open');
            if ($("#nav-icon4").hasClass("open")){
                $("#nav-icon4").css('height','45px');
            }
            else{
                $("#nav-icon4").css('height','20px');
            }
        });

        $('.ad-menu-caret-4').click(function(){
            $(this).toggleClass('active');
            $('.ad-mob-menu-links-cont-4').toggleClass('mob-submenu-toggle');
            if ($(this).hasClass("active")){
                $('.ad-menu-caret-4 .mob-menu-minus').show();
                $('.ad-menu-caret-4 .mob-menu-plus').hide();
            }
            else{
                $('.ad-menu-caret-4 .mob-menu-minus').hide();
                $('.ad-menu-caret-4 .mob-menu-plus').show();
            }
        });
    }

    render(){
        return (
            <section className="ad-white-nav fixed-top">
                <nav className="ad-nav-container">
                    <input type="checkbox" id="check"/>
                    <label className="logo"><Link to="/" ><img src={Config.HOST +'/assets/img/alchem-black-header-logo.png'} alt="as-black-header-logo"/></Link></label>
                    <div className="alchem-global-right-align">
                        <div className="alchem-whatsapp-mob-icon">
                            <div className="mob-whatsapp-color"><a className="nav-header-icon" href="https://api.whatsapp.com/send?phone=+917305639892" target="_blank"><img src={Config.HOST +'/assets/img/res-dev-asset/whatsapp-new-icon.svg'} alt="whatsapp-color-icon"/></a></div>
                        </div>
                        <label htmlFor="check" className="checkbtn">
                            <div id="nav-icon4" className="new-hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </label>
                        <div className="right-side-nav-section">
                            <ul className="mob-list-cont">
                                <li><NavLink exact activeClassName="active" className="ad-transparent-nav-links" to="/">Home</NavLink></li>
                                <li><NavLink activeClassName="active"  className="ad-transparent-nav-links" to="/about">About Us</NavLink></li>
                                <li className="menu-header-drpdown">
                                    <NavLink activeClassName="active"  className="ad-transparent-nav-links menu-dropdown-section" to="/services">Services</NavLink>
                                    <b className="ad-menu-caret active">
                                        <span className="mob-menu-plus"><img src={Config.HOST +'/assets/img/mob-menu-plus.svg'} alt="mob-menu-plus"/></span>
                                        <span className="mob-menu-minus"><img src={Config.HOST +'/assets/img/mob-menu-minus.svg'} alt="mob-menu-minus"/></span>
                                    </b>
                                    <ul className="submenu submenu-animated submenu_fadeIn">
                                        <div className="ad-mega-submenu-lists">
                                            <div className="ad-mega-submenu-sub-lists">
                                                <p className="mega-menu-title-1"><span>01</span>Website Development</p>
                                                <ul>
                                                    <li className="megamenu-sublinks">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/responsive-website-design-development">
                                                            <span>- </span>  
                                                            <p>
                                                            Responsive website development
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink  activeClassName="active"  className="submenu-text-links megamenu-link-align" to="/wordpress-development-company-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                WordPress website development
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/e-commerce-website-development-company-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                E-Commerce website development
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#core-service-section">
                                                            <span>- </span>
                                                            <p>
                                                                Magento website development
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#core-service-section">
                                                            <span>- </span>
                                                            <p>
                                                                WooCommerce website development
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#core-service-section">
                                                            <span>- </span>
                                                            <p>
                                                                CMS website development
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ad-mega-submenu-sub-lists">
                                                <p className="mega-menu-title-3"><span>02</span>Web App Development</p>
                                                <ul>
                                                    <li className="megamenu-sublinks">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/web-application-development-company-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                Web application development
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/saas-development-company-in-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                SaaS product development
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#Web-dev">
                                                            <span>- </span>
                                                            <p>
                                                                PHP website development
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#Web-dev">
                                                            <span>- </span>
                                                            <p>
                                                                Laravel website development
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#Web-dev">
                                                            <span>- </span>
                                                            <p>
                                                                Django website development
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ad-mega-submenu-sub-lists">
                                                <p className="mega-menu-title-2"><span>03</span>Marketing</p>
                                                <ul>
                                                    <li className="megamenu-sublinks">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/digital-marketing-agency-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                Digital marketing
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#seo">
                                                            <span>- </span>
                                                            <p>
                                                                Social media management
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/ppc-services-in-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                Paid media marketing
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/seo-company-in-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                Search Engine Optimization (SEO)
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/youtube-marketing">
                                                            <span>- </span>
                                                            <p>
                                                                YouTube marketing
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/content-marketing">
                                                            <span>- </span>
                                                            <p>
                                                                Content marketing
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ad-mega-submenu-sub-lists">
                                                <p className="mega-menu-title-4"><span>04</span>Design</p>
                                                <ul>
                                                    <li className="megamenu-sublinks">
                                                        <NavLink activeClassName="active" className="submenu-text-links megamenu-link-align" to="/branding-company-in-chennai">
                                                            <span>- </span>
                                                            <p>
                                                                Brand identity
                                                            </p>
                                                        </NavLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#graphic-desg">
                                                            <span>- </span>
                                                            <p>
                                                                Graphic designing
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                    <li className="megamenu-sublinks-1">
                                                        <NavHashLink activeStyle={{ color: '#f9423a' }} className="submenu-text-links megamenu-link-align" to="/services#graphic-desg">
                                                            <span>- </span>
                                                            <p>
                                                                UI/UX designing
                                                            </p>
                                                        </NavHashLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ul>
                                    <ul className="mobile-submenu mob-submenu-toggle">
                                        <div className="ad-mob-mega-submenu-lists">
                                            <div className="ad-mob-mega-submenu-lists-1">
                                                <div className="ad-mob-menu-title-align">
                                                    <p className="ad-mob-menu-title"><span>01</span> Website Development</p>
                                                    <b className="ad-menu-caret-1 active">
                                                        <span className="mob-menu-plus"><img src={Config.HOST +'/assets/img/mob-menu-plus.svg'} alt="mob-menu-plus"/></span>
                                                        <span className="mob-menu-minus"><img src={Config.HOST +'/assets/img/mob-menu-minus.svg'} alt="mob-menu-minus"/></span>
                                                    </b>
                                                </div>
                                                <ul className="ad-mob-menu-links-cont-1 mob-submenu-toggle">
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/responsive-website-design-development">
                                                                Responsive website development
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/wordpress-development-company-chennai">
                                                                WordPress website development
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/e-commerce-website-development-company-chennai">
                                                                E-Commerce website development
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#core-service-section">
                                                                Magento website development
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#core-service-section">
                                                                WooCommerce website development
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#core-service-section">
                                                                CMS website development
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ad-mob-mega-submenu-lists-3">
                                                <div className="ad-mob-menu-title-align">
                                                    <p className="ad-mob-menu-title"><span>02</span> Web App Development</p>
                                                    <b className="ad-menu-caret-3 active">
                                                        <span className="mob-menu-plus"><img src={Config.HOST +'/assets/img/mob-menu-plus.svg'} alt="mob-menu-plus"/></span>
                                                        <span className="mob-menu-minus"><img src={Config.HOST +'/assets/img/mob-menu-minus.svg'} alt="mob-menu-minus"/></span>
                                                    </b>
                                                </div>
                                                <ul className="ad-mob-menu-links-cont-3 mob-submenu-toggle">
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/web-application-development-company-chennai">
                                                                Web application development
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/saas-development-company-in-chennai">
                                                                SaaS product development
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#Web-dev">
                                                                PHP website development
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#Web-dev">
                                                                Laravel website development
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#Web-dev">
                                                                Django website development
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ad-mob-mega-submenu-lists-2">
                                                <div className="ad-mob-menu-title-align">
                                                    <p className="ad-mob-menu-title"><span>03</span> Marketing</p>
                                                    <b className="ad-menu-caret-2 active">
                                                        <span className="mob-menu-plus"><img src={Config.HOST +'/assets/img/mob-menu-plus.svg'} alt="mob-menu-plus"/></span>
                                                        <span className="mob-menu-minus"><img src={Config.HOST +'/assets/img/mob-menu-minus.svg'} alt="mob-menu-minus"/></span>
                                                    </b>
                                                </div>
                                                <ul className="ad-mob-menu-links-cont-2 mob-submenu-toggle">
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/digital-marketing-agency-chennai">
                                                                Digital marketing
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#seo">
                                                                Social media management
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/ppc-services-in-chennai">
                                                                Paid media marketing
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/seo-company-in-chennai">
                                                                Search Engine Optimization (SEO)
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/youtube-marketing">
                                                                YouTube marketing
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/content-marketing">
                                                                Content marketing
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ad-mob-mega-submenu-lists-4">
                                                <div className="ad-mob-menu-title-align">
                                                    <p className="ad-mob-menu-title"><span>04</span> Design</p>
                                                    <b className="ad-menu-caret-4 active">
                                                        <span className="mob-menu-plus"><img src={Config.HOST +'/assets/img/mob-menu-plus.svg'} alt="mob-menu-plus"/></span>
                                                        <span className="mob-menu-minus"><img src={Config.HOST +'/assets/img/mob-menu-minus.svg'} alt="mob-menu-minus"/></span>
                                                    </b>
                                                </div>
                                                <ul className="ad-mob-menu-links-cont-4 mob-submenu-toggle">
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavLink activeClassName="active" className="mob-submenu-text-links" to="/branding-company-in-chennai">
                                                                Brand identity
                                                            </NavLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#graphic-desg">
                                                                Graphic designing
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="megamenu-link-align">
                                                            <span>- </span>
                                                            <NavHashLink activeStyle={{ color: '#f9423a' }} className="mob-submenu-text-links" to="/services#graphic-desg">
                                                                UI/UX designing
                                                            </NavHashLink>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li><NavLink activeClassName="active"  className="ad-transparent-nav-links" to="/portfolio">Portfolio</NavLink></li>
                                <li><NavLink activeClassName="active"  className="ad-transparent-nav-links" to="/blog">Blog</NavLink></li>
                                <li><NavLink activeClassName="active"  className="ad-transparent-nav-links" to="/contact">Contact</NavLink> </li>   
                                {/* <div className="mob-color-whatsapp"><a className="nav-header-icon" href="https://api.whatsapp.com/send?phone=+917305639892" target="_blank"><img src={Config.HOST +'/assets/img/res-dev-asset/whatsapp-new-icon.svg'} alt="whatsapp-color-icon"/></a></div>                */}
                            </ul>
                        </div>
                        <div className="alchem-whatsapp-icon">
                            <div className="mob-whatsapp-color"><a className="nav-header-icon" href="https://api.whatsapp.com/send?phone=+917305639892" target="_blank"><img src={Config.HOST +'/assets/img/res-dev-asset/whatsapp-new-icon.svg'} alt="whatsapp-color-icon"/></a></div>
                        </div>
                    </div>
                </nav>
            </section>
        );
    }
}

export default Header