import React, { Component } from 'react'
// import OwlCarousel from "react-owl-carousel"
import { Link } from 'react-router-dom'
const OwlCarousel = (typeof window != 'undefined') && require('react-owl-carousel')

export default class RelatedBlogCarousel extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        const optionsBlog = {
            items: 2,
            margin: 30,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: true,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: false,
            smartSpeed: 800,
            responsive: {
                0: {
                    items: 1
                },
                450: {
                    items: 2
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 2
                }
            }
        };

        let relatedBlogs = this.props.relatedBlogs
        return (
            <>
                {
                    relatedBlogs.length && (
                        typeof window != 'undefined' &&
                        <OwlCarousel className="post-related-carousel" {...optionsBlog} >
                                {
                                    relatedBlogs.map((blog, key) => (
                                        <>
                                            <div className="related-post-wrapper" key={key}>
                                                <div className="blog-post-img-wrapper">
                                                    <div className="blog-related-post-image">
                                                        <img src={blog.banner_image} alt={blog.title} />
                                                    </div>
                                                    {/* <div className="single-post-img-cate"><a href="#">Interview</a></div> */}
                                                </div>
                                                <div class="post-meta">
                                                    <h5 class="related-post-title">
                                                        <Link to={'/blog/' + blog.slug}>
                                                            {blog.title}
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                        </OwlCarousel>
                     )
                }
                 
            </>
        )
    }
}
