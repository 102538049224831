import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../../layout/AllPageContactForm'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'

class SearchEngineOptimization extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }

    SEOProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Local SEO",
                "activeImg": "seo-process-1.svg",
                "img" : "seo-process-1-black.svg",
                "description" : "With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business."
            },
            {
                "id" : 2,
                "title" : "Keyword Research",
                "activeImg": "seo-process-2-white.svg",
                "img" : "seo-process-2.svg",
                "description" : "At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google."
            },
            {
                "id" : 3,
                "title" : "On-page Optimization",
                "activeImg": "seo-process-3-white.svg",
                "img" : "seo-process-3.svg",
                "description" : "What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both."
            },
            {
                "id" : 4,
                "title" : "Off-page Optimization",
                "activeImg": "seo-process-4-white.svg",
                "img" : "seo-process-4.svg",
                "description" : "As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website."
            },
            {
                "id" : 5,
                "title" : "Deliver",
                "activeImg": "seo-process-5-white.svg",
                "img" : "seo-process-5.svg",
                "description" : "As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine."
            }
        ]

    toggle = (e, tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            setInterval(5000, this.intervalRef.current);
        }
    }

    componentDidMount(){
        
        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.seo-optimize > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.seo-optimize > li').length
        //     ? 
        //     active.parent().next('.seo-optimize > li').find('a') 
        //     : 
        //     tabs.parent().filter('.seo-optimize > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.seo-optimize a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.6;
        var $bg_images = $(".res-dev-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.SEOProcessContent.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }

    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };

    render(){
        return(
            <React.Fragment>
                <HeadTags path='seo-company-in-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="search-engine-opt-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">SEO Company in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">Search engine optimization: An effective way to drive traffic to your website, make your website visible, and increase the credibility of your site. Learn more from one of the best SEO agencies in Chennai.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/seo/seo-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">What is SEO?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>With approximately 3.8 million searches happening every minute, and that is just on Google, why wouldn’t you want to be where your potential customers are at. SEO or Search Engine Optimization helps in increasing the visibility of your website on the search engine for relevant searches. SEO is considered an important strategy in any business as it increases your organic traffic. The right SEO strategy can help in increasing the quality and quantity of traffic to your website by ranking on top of the Search Engine Result Pages (SERPs). Increasing the visibility of your website increases the chances of reaching your potential customers and improve your sales and conversions.</p>
                                        <p>So how does SEO exactly work? Multiple elements influence how your website ranks on the SERP. Everything beginning from your on-page strategies, off-page strategies, optimal keyword usage, etc. determines how your website ranks on the SERP. In order to provide the best results to the users, search engines see that your site is both relevant and qualified. This is done by scanning and crawling through various website content to provide the searches with relevant information. Therefore, websites need to have both the search engine and the user in mind to provide relevant and optimized content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main-2">
                    <div className="digital-market-glob-container-1">
                        <div className="row digital-market-row-reverse">
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container-2">
                                    <h1 className="digi-market-zig-zag-title">What is Local SEO?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>Optimizing your website/business to rank on top of the map results of Google search for a specific area is what local SEO is. Local SEO can be beneficial for a small, local business whose ultimate aim is to attract the audience of a specific locality. With local SEO, you make sure that you increase your visibility online by ranking for selective searches by the local audience. Multiple ranking factors beginning from your online reviews and reputation can determine how you rank on the SERP.</p>
                                        <p>The right SEO company will help you increase your brand awareness by creating a local SEO strategy for reviews, enhancing your Google My Business account, etc. Optimizing for local searches can help the search engines know that you can service customers in your area. Investing in local SEO can be one of the fastest ways for you to grow effectively. Local SEO can play a vital role in any brick-and-mortar businesses in a particular geographical area. This allows people to find out about your business and eventually become a customer.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 digi-market-align-img-align">
                                <img src={`${Config.HOST}/assets/img/seo/seo-2.jpg`} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">Benefits of SEO</h1>
                        <div className="res-dev-key-features-container digi-market-mega-cont">
                            <div className="mob-res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections benefits-padding">
                                    <img src={`${Config.HOST}/assets/img/seo/benefits-seo-1.svg`} alt="benefits-seo-1"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-2">Build Brand Visibility, <br/> Credibility, and Trust</h2>
                                    <p className="res-dev-key-feature-excerpt">Search Engine Optimization is one of the cheapest ways to increase traffic to your website. Users tend to click on websites that are on the top of the SERP. Ranking on top of the SERP will help you convert your visitors into potential customers. You can provide the best products and services but not ranking on the search engines means you lose potential customers.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-right-border-remove">
                                <div className="res-dev-key-feature-sections benefits-padding">
                                    <img className="mb-3" src={`${Config.HOST}/assets/img/seo/benefits-seo-2.svg`} alt="benefits-seo-2"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-2">Quality Traffic</h2>
                                    <p className="res-dev-key-feature-excerpt">You tell us what’s efficient. Reaching out to the audience about your products and services, irrespective of whether or not they want to pay attention or providing your customers the right information when they are looking for it. Providing the audience with what they want, when they want increases the quality of your traffic. To get quality traffic, you need to provide quality content and experience for your users and you know where to go to get that.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove mob-res-dev-key-feature-box-right-border-remove mob-res-dev-key-feature-box-bottom-border-add">
                                <div className="res-dev-key-feature-sections benefits-padding">
                                    <img src={`${Config.HOST}/assets/img/seo/benefits-seo-3.svg`} alt="benefits-seo-3"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-2">Higher Conversion Rates</h2>
                                    <p className="res-dev-key-feature-excerpt">Search Engine Optimization is one of the best inbound marketing techniques to increase revenue. With consistent inquiries from the audience, the right SEO technique will lead to people visiting your website and when they do, the chances of them becoming your customer are very high.</p>
                                </div>
                            </div>
                            <div className="res-dev-key-feature-box-bottom-border-remove res-dev-key-feature-box-right-border-remove digi-market-services-last-align">
                                <div className="res-dev-key-feature-sections benefits-padding">
                                    <img src={`${Config.HOST}/assets/img/seo/benefits-seo-4.svg`} alt="benefits-seo-4"/>
                                    <h2 className="res-dev-key-feature-txt mb-3 pt-2">Cost-Efficient</h2>
                                    <p className="res-dev-key-feature-excerpt">As we mentioned earlier, SEO is one of the cheapest ways you can increase traffic to your website as it is targeted. Due to its inbound nature, SEO helps companies save their resources. The only prerequisite is to stay consistent with the SEO efforts and not halt at all, to keep achieving the desired conversions/goals. With SEO your ROAS is could be exponential.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="seo-dev-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="seo-call-to-action-title">Let’s get you to the top of SERP</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#digi-market-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Let’s get started</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/seo/seo-3.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">Importance of Organic Traffic</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>You need to be where your potential customers are at. While paid marketing and social media marketing can be some of the ways you can drive traffic to your website, SERP can be one of the most cost-efficient ways for you to drive traffic to your website. While search engines are the first thing that people depend on these days, why wouldn’t you make the best use of them? How is organic traffic any better than paid traffic? Being on top of the SERP is so yearned for by a lot of marketers as organic traffic is here to stay whereas in paid media marketing (paid traffic), your traffic (visibility) says “See ya!” when you stop paying. Organic traffic though takes time to master, can give you lasting results.</p>
                                        <p>While the quantity of your traffic is important, you also need to pay attention to the quality of your traffic. Every person that looks for something on the search engine has a search intent and if you can satisfy that intent, you increase the possibility of making them your customer. SEO in the modern internet era is considered to be a part of the business investment and not necessarily “a marketing spend”.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="seo-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">SEO Process</h1>
                        <div className="seo-content-cont">
                            {/* <ul className="nav nav-pills seo-optimize mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-1-tab" data-toggle="pill" href="#pills-seo-content-1" role="tab" aria-controls="pills-seo-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Local SEO</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Local SEO</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-2-tab" data-toggle="pill" href="#pills-seo-content-2" role="tab" aria-controls="pills-seo-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Keyword Research</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-3-tab" data-toggle="pill" href="#pills-seo-content-3" role="tab" aria-controls="pills-seo-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">On-page Optimization</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">On-page Optimization</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-4-tab" data-toggle="pill" href="#pills-seo-content-4" role="tab" aria-controls="pills-seo-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Off-page Optimization</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Off-page Optimization</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-5-tab" data-toggle="pill" href="#pills-seo-content-5" role="tab" aria-controls="pills-seo-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Continuous Optimization</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12">Continuous Optimization</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active active-anim" id="pills-seo-content-1" role="tabpanel" aria-labelledby="pills-seo-content-1-tab">
                                    <div className="seo-process-content">
                                        <h3>Local SEO</h3>
                                        <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-2" role="tabpanel" aria-labelledby="pills-seo-content-2-tab">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-3" role="tabpanel" aria-labelledby="pills-seo-content-3-tab">
                                    <div className="seo-process-content">
                                        <h3>On-page Optimization</h3>
                                        <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-4" role="tabpanel" aria-labelledby="pills-seo-content-4-tab">
                                    <div className="seo-process-content">
                                        <h3>Off-page Optimization</h3>
                                        <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-5" role="tabpanel" aria-labelledby="pills-seo-content-5-tab">
                                    <div className="seo-process-content">
                                        <h3>Continuous Optimization</h3>
                                        <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Local SEO</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '1' ? `${Config.HOST}/assets/img/seo/seo-process-1.svg` : `${Config.HOST}/assets/img/seo/seo-process-1-black.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Local SEO</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <h4 className="padd-bottom-12">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '2' ? `${Config.HOST}/assets/img/seo/seo-process-2-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-2.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Keyword Research</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">On-page Optimization</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '3' ? `${Config.HOST}/assets/img/seo/seo-process-3-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-3.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">On-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <h4 className="padd-bottom-12">Off-page Optimization</h4> 
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '4' ? `${Config.HOST}/assets/img/seo/seo-process-4-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-4.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Off-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Continuous Optimization</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '5' ? `${Config.HOST}/assets/img/seo/seo-process-5-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-5.svg`}  alt="seo-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12">Continuous Optimization</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="seo-process-content">
                                        <h3>Local SEO</h3>
                                        <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="seo-process-content">
                                        <h3>On-page Optimization</h3>
                                        <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="seo-process-content">
                                        <h3>Off-page Optimization</h3>
                                        <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="seo-process-content">
                                        <h3>Continuous Optimization</h3>
                                        <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}

                            <Nav tabs>
                                {
                                    this.SEOProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={this.state.activeTab >= item.id ? `${Config.HOST}/assets/img/seo/`+item.activeImg : `${Config.HOST}/assets/img/seo/`+item.img}  alt="e-com-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.SEOProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/seo/seo-process-1-black.svg`} alt="seo-process"/>
                                    <h4>Local SEO</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/seo/seo-process-2.svg`} alt="seo-process"/>
                                    <h4>Keyword Research</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/seo/seo-process-3.svg`} alt="seo-process"/>
                                    <h4>On-page Optimization</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/seo/seo-process-4.svg`} alt="seo-process"/>
                                    <h4>Off-page Optimization</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/seo/seo-process-5.svg`} alt="seo-process"/>
                                    <h4>Continuous Optimization</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main">
                    <div className="digital-market-glob-container-1">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/seo/seo-4.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container">
                                    <h1 className="digi-market-zig-zag-title">Why Alchem Digital is your best SEO company in Chennai?</h1>
                                    <div className="digi-market-zig-zag-content">
                                        <p>At Alchem Digital we provide you competent SEO services and strategies to rank your site on top of the SERP. We make use of white-hat techniques to optimize your page and provide you cost-effective SEO strategies. Our SEO experts excel in keyword research, on-page optimization, off-page optimization, etc. with 10+ years of experience, which makes us the best SEO company in Chennai.</p>
                                    </div>
                                    <Link to="/contact" className="res-dev-call-to-action-button button-gradient transition-issue mt-2" >Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="digi-marketing-technologies">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Facebook Ads Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Twitter Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">LinkedIn Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">Google Ads</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Google Analytics</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-6">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Tag Manager</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-7">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Taboola</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-8">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Hootsuite</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-9">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Trello</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-10">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Creator Studio</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="digi-marketing-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top SEO company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>Why is SEO important for my business?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>SEO traffic is organic and is not paid for making it an effective technique to increase traffic to your website. As your visibility increases, you also see an increase in your potential customers.</p>
                                        <p>Apart from increasing visibility, SEO also helps in increasing the credibility of your site. </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>Can I stop investing in SEO once my site ranks on top search results?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>A Big NO. You need to be consistent to retain your position on the SERP. Like we mentioned earlier, SEO is really competitive and you need to be on top of your competitors to make the best use of SEO, and being inconsistent can cost you.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>Is SEO costly?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>SEO is one of the most cost-effective ways to drive traffic to your website when you take Return of Investment (ROI) into consideration. While it might take time for you to see results from SEO, it will be worth both your investment and time. SEO in the modern internet era is considered to be a part of the business investment and not necessarily a “marketing spend”.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>How long will it take to rank on the first page of Google?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>There are several factors that will implement how your site ranks on Google. Anyway, it should take you anywhere between four to six months to rank on the first page of Google provided you make use of the right SEO strategies and the right <span><Link to="/contact">SEO agency in Chennai</Link></span>. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSix">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSix" aria-expanded="false" aria-controls="newcollapseSix">
                                                <span>Can I do SEO by myself without SEO agency support?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSix" className="collapse" aria-labelledby="newheadingSix" data-parent="#newaccordion">
                                        <div className="card-body">
                                        <p>SEO is more than just optimizing keywords. Search Engine Optimization requires expertise and consistency which you can get when you outsource SEO services. SEO requires knowledge of what the audience is looking for, how to provide your audience with what they want while having the search engine algorithm in mind.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingSeven">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSeven" aria-expanded="false" aria-controls="newcollapseSeven">
                                                <span>Why does my ranking keep changing?</span>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseSeven" className="collapse" aria-labelledby="newheadingSeven" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Fluctuation in the ranking is a part of the SEO process. We work to stabilize these rankings by understanding what works with your target audience and the search engines constantly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default SearchEngineOptimization