import $ from "jquery"
import React, {Component} from 'react'
import AllPageContactForm from '../layout/AllPageContactForm'
import Header from '../layout/Header'
import HeadTags from '../layout/HeadTags'
import Config from "./Config"


class About extends React.Component{

    componentDidMount(){
        var parallax = -0.5;

        var $bg_images = $(".ad-tech-banner-section");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        $("a[href^='#'].ad-portfolio-button-new").on('click', function(e) {
            e.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 1000, function(){
                window.location.hash = hash;
            });
        
        });
    }
    
    render(){
        return(
            <>
                <HeadTags path='about' />
                <Header/>
                <section className="ad-about-bg">
                    <div className="about-bg-img">
                        <div className="container">
                            <div className="ad-pages-hero-section">
                                <div className="ad-bread-crumbs">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div>
                                    <p className="ad-about-main-title">Get a glimpse of Alchem Digital</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="ad-alchemists-section">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img className="ad-about-dots-img" src={Config.HOST+'/assets/img/about-bg-dots.png'} alt="about-bg-dots"/>
                                <div className="zoom-effect-1">
                                    <img className="ad-about-alchemists-img" src={Config.HOST+'/assets/img/about-alchemist.jpg'} alt="about-alchemist"/>
                                    <div className="ad-banner-content-inner"></div>
                                </div>
                            </div>
                            <div  className="col-xs-12 col-sm-6">
                                <div className="ad-we-are-alchemists-section">
                                    <div className="pt-4 pb-3"> 
                                        <p className="ad-about-us-top-title">We are</p>
                                        <p className="ad-about-us-title">Digital Alchemists</p>
                                        <div className="ad-about-us-content">
                                            <p className="ad-about-us-txt">Alchem Digital was initiated and built by a set of fervent young minds, with solid experience in the field of Design, Branding & Web development with a yearning to make a difference in the digital industry. We help to build brands and equip them digitally so that they can float into their digital space with ease.</p>
                                            <p className="ad-about-us-txt">We join forces in hunting and delivering creative solutions that will work the best for your brand/business.</p>
                                            <p className="ad-about-us-txt">Each client of ours matters. Our planning, research process, brainstorming sessions, and client servicing are invariable irrespective of the size of the client.</p>
                                            <p className="ad-about-us-txt">Rest assured, our work speaks!</p>
                                            <p className="ad-about-us-txt">With our digital expertise, we have helped multiple clients of ours flourish in the digital space through our web development services, digital marketing, graphic designing services, and more.</p>
                                        </div>
                                    </div>
                                    <div className="about-us-page-text-align">
                                        <p className="about-us-page-txt">about</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="ad-approach-section">
                        <div className="container">
                            <div className="ad-approach-header text-center">
                                <p className="ad-about-us-top-title">Our Approach</p>
                                <p className="ad-about-us-title">Prerequisites for our combined success</p>
                            </div>
                            <div className="row ad-approach-container">
                                <div className="col-xs-12 col-sm-4">
                                    <div className="ad-approach-align">
                                        <div className="ad-approach-subtile-align">
                                            <img src={Config.HOST+'/assets/img/approach/scale-2.svg'} alt="scale"/>
                                        </div>
                                        <div className="ad-approach-subtile-align">
                                            <p className="ad-approach-sub-title">Marshalling plans</p>
                                        </div>
                                    </div>
                                    <p className="ad-approach-txts">Pencilling down the way forward and<br/> organizing required resource is quint essential and we do that religiously with an effective control process to measure.</p>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <div className="ad-approach-align">
                                        <div className="ad-approach-subtile-align">
                                            <img src={Config.HOST+'/assets/img/approach/notepad.svg'} alt="notepad"/>
                                        </div>
                                        <div className="ad-approach-subtile-align">
                                            <p className="ad-approach-sub-title">Diligence & Discipline</p>
                                        </div>
                                    </div>
                                    <p className="ad-approach-txts">
                                        We strictly adhere to the ETA and the commitment given to our clients since we highly value both of our time and effort involved. We proudly pat on our back for our timely delivery and effective communication
                                        <span className="ad-smiley-img"><img src={Config.HOST+'/assets/img/approach/smiley.svg'} alt="smiley"/></span>
                                    </p>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <div className="ad-approach-align">
                                        <div className="ad-approach-subtile-align">
                                            <img src={Config.HOST+'/assets/img/approach/customer-first.svg'} alt="customer-first"/>
                                        </div>
                                        <div className="ad-approach-subtile-align">
                                            <p className="ad-approach-sub-title">Customer-first</p>
                                        </div>
                                    </div>
                                    <p className="ad-approach-txts">At every juncture of our planning and development, we pivot the end consumers/users and make sure they get the best experience and satisfaction. EOD, it is what that matters the most!</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="ad-tech-banner-section">
                            <div className="container">
                                <div className="ad-approach-header text-center">
                                    <p className="ad-about-us-top-title">Technologies</p>
                                    <p className="ad-tech-title">we specialize</p>
                                </div>
                                <div className="row ad-tech-logo-container align-items-center">
                                    <div data-aos="zoom-in" data-aos-delay="300" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/html5-new.png'} alt="html"/>
                                        <p className="ad-tech-respective-title">HTML5</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="300" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img className="pt-2 pb-2" src={Config.HOST+'/assets/img/tech-logos/bootstrap.png'} alt="bootstrap"/>
                                        <p className="ad-tech-respective-title">Bootstrap</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="300" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img className="pt-2 pb-2" src={Config.HOST+'/assets/img/tech-logos/javascript.png'} alt="javascript"/>
                                        <p className="ad-tech-respective-title">Javascript</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="300" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img className="pt-2" src={Config.HOST+'/assets/img/tech-logos/jquery.png'} alt="jquery"/>
                                        <p className="ad-tech-respective-title">jQuery</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="300" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img className="pt-3 pb-3" src={Config.HOST+'/assets/img/tech-logos/PHP.png'} alt="php"/>
                                        <p className="ad-tech-respective-title">PHP</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="300" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img className="pt-2 pb-2" src={Config.HOST+'/assets/img/tech-logos/Laravel.png'} alt="Laravel"/>
                                        <p className="ad-tech-respective-title">Laravel</p>
                                    </div>
                                </div>
                                <div className="row ad-tech-logo-container ad-tech-logo-padd align-items-center">
                                    <div data-aos="zoom-in" data-aos-delay="400" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/magento.png'} alt="magento"/>
                                        <p className="ad-tech-respective-title">Magento</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="400" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/WordPress.svg'} alt="wordpress"/>
                                        <p className="ad-tech-respective-title">Wordpress</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="400" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/joomla.png'} alt="joomla"/>
                                        <p className="ad-tech-respective-title">Joomla</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="400" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/react.png'} alt="react"/>
                                        <p className="ad-tech-respective-title">React JS</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="400" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/angular.png'} alt="angular"/>
                                        <p className="ad-tech-respective-title">Angular JS</p>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-delay="400" className="col-x2-12 col-sm-6 col-md-4 col-lg-2 ad-tech-logo-img">
                                        <img src={Config.HOST+'/assets/img/tech-logos/figma.png'} alt="figma"/>
                                        <p className="ad-tech-respective-title">Figma</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="ad-team-container">
                        <div className="container">
                            <div className="ad-portfolio-title-container">
                                <div>
                                    <p className="ad-portfolio-top-title">The AD Team</p>
                                    <p className="ad-portfolio-main-title">Meet the Alchemists!</p>
                                </div>
                                <div className="ad-portfolio-view-project-align" >
                                    <a data-aos-delay="300" href="#home-contact-form" className="alchem-digi-portfolio-button-new button-gradient">Join the pack</a>
                                    <img src={'assets/img/dots.png'} alt="dots-img"/>
                                </div>
                            </div>
                            <div className="ad-team-sub-container">
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/satya.png'} alt="satya-ceo"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Sathya Prakash Samy</p>
                                            <p className="ad-team-alchemists-position">/CEO + Co-Founder</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/satya-packirisamy/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/gowtham.png'} alt="gowtham-creative-director"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Gowtham Anbu</p>
                                            <p className="ad-team-alchemists-position">/Creative Director + Co-Founder</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/mahtwog4/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/parithi.png'} alt="satya-technical-lead"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Ilamparithi K</p>
                                            <p className="ad-team-alchemists-position">/Technical Head + Co-Founder</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/ilamparithi-kaliyappan-20233a114/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/bairavan.png'} alt="bairavan-sen-dev"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Bairavan D</p>
                                            <p className="ad-team-alchemists-position">/Senior Developer + Co-Founder</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/bairavan/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/divakar.png'} alt="divakar-manager"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Divakar</p>
                                            <p className="ad-team-alchemists-position">/Business Analyst + Project Manager</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/divakar-anbalagan/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/karthick.png'} alt="karthick-seo"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Karthikeyan B</p>
                                            <p className="ad-team-alchemists-position">/Digital Media and Content Executive</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/karthikeyanbk/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/dinesh.png'} alt="dinesh-ui-dev"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Arunachala Dinesh K</p>
                                            <p className="ad-team-alchemists-position">/Frontend Developer</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/arunachaladinesh-kanagavelu-38599b16b/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/KVM.png'} alt="KVM-Mentor"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Karthik Moorthi</p>
                                            <p className="ad-team-alchemists-position">/Mentor</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/karthikmoorthi/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/dhamo.jpg'} alt="Dhamo-ui-dev"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Dhamodharan</p>
                                            <p className="ad-team-alchemists-position">/Frontend Developer</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/dhamodharan-86b837139"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                                    <div className="ad-team-section">
                                        <div className="ad-team-content-container">
                                            <div className="ad-team-member-image">
                                                <img src={Config.HOST+'/assets/img/team/swetha.png'} alt="Swetha-digi-cont-exe"/>
                                            </div>
                                            <p className="ad-team-alchemists-name">Swetha</p>
                                            <p className="ad-team-alchemists-position">/Digital Content Executive</p>
                                            <div className="ad-team-alchemists-social-media"><span><a target="_blank" href="https://www.linkedin.com/in/swetha-venkataramanan-676aaa1aa/"><i className="fab fa-linkedin"></i></a></span></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </section> */}
                    <section className="mt-5">
                        <div className="ad-client-home-container">
                            <div className="text-center">
                                <p className="ad-portfolio-top-title mb-1">SERVED BEST & BUILT TRUST</p>
                                <p className="ad-portfolio-main-title mb-0">Our clientele</p>
                                <div className="ad-clients-section-part">
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo langscape-logo"></div>
                                        {/* <img src={'assets/img/clients/langscape.jpg'} alt="langscape"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo carefree-logo"></div>
                                        {/* <img src={'assets/img/clients/carefree.jpg'} alt="carefree"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo ailaysa-logo"></div>
                                        {/* <img src={'assets/img/clients/ailaysa.jpg'} alt="ailaysa"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo caapid-logo"></div>
                                        {/* <img src={'assets/img/clients/caapid.jpg'} alt="Caapid"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo qpharmetra-logo"></div>
                                        {/* <img src={'assets/img/clients/qpharmetra.jpg'} alt="qphartmetra"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo aazhibooks-logo"></div>
                                        {/* <img src={'assets/img/clients/aazhibooks.jpg'} alt="aazhibooks"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo sri-herbals-logo"></div>
                                        {/* <img src={'assets/img/clients/sri_herbals.jpg'} alt="srida_herbals"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo leia-logo"></div>
                                        {/* <img src={'assets/img/clients/leia.jpg'} alt="leia"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo keys4life-logo"></div>
                                        {/* <img src={'assets/img/clients/keys4life.jpg'} alt="keys4life"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo aci-logo"></div>
                                        {/* <img id="home-contact-form" src={'assets/img/clients/aci.png'} alt="aci-automation"/> */}
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo milkpot-logo"></div>
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo sukom-logo"></div>
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo aptus-logo"></div>
                                    </div>
                                    <div className="ad-clients-logo new-about-us-client">
                                        <div className="common-client-logo dosa-logo"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </>
        );
    }
}

export default About