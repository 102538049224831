import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import React, {Component} from 'react'

import $ from "jquery"
import AllPageContactForm from './layout/AllPageContactForm'
import HeaderTransparent from './layout/Header-transparent'
import { Link } from 'react-router-dom'
import SlickSlider from './SlickSlider'
import HeadTags from './layout/HeadTags'
import { Helmet } from "react-helmet-async"
import BlogCarousel from "./pages/BlogCarousel"
import Testimonial from "./layout/AllpageTestimonial"
import Config from './pages/Config'
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

class Home extends React.Component{
    

    componentDidMount(){
        /** change value here to adjust parallax level */
        var parallax = -0.5;
        var $bg_images = $(".ad-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        $("a[href^='#'].ad-button-new").on('click', function(e) {
            e.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 1000, function(){
                window.location.hash = hash;
                var noHashURL = window.location.href.replace(/#.*$/, '');
                window.history.replaceState('', document.title, noHashURL) 
            });
        });

        $("a[href^='#'].ad-call-to-button").on('click', function(e) {
            e.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 1000, function(){
                window.location.hash = hash;
                var noHashURL = window.location.href.replace(/#.*$/, '');
                window.history.replaceState('', document.title, noHashURL) 
            });        
        });
    }

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const options2 = {
            items: 4,
            margin: 25,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: true,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: false,
            lazyLoad: true,
            autoplay: false,
            smartSpeed: 800,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1366: {
                    items: 4,
                },
                1400: {
                    items: 4,
                },
                1440: {
                    items: 3,
                },
                1540: {
                    items: 3,
                },
                1500: {
                    items: 4,
                },
                1800: {
                    items: 4,
                },
                1920: {
                    items: 5,
                },
            },
        };
        const options3 = {
            items: 4,
            margin: 25,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: true,
            navText: ["<span aria-label='Previous'><i class='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i class='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: false,
            lazyLoad: true,
            autoplay: false,
            smartSpeed: 800,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 2,
                },
                767: {
                    items: 2,
                },
            },
        };
        return(
            <>
                <HeadTags path='' />
                <HeaderTransparent />
                <section>
                    <SlickSlider/>
                </section>
                <section className="laptop-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <div className="about-txt-align">
                                    <div className="about-text-align">
                                        <h1 className="about-txt">about</h1>
                                    </div>
                                    <div className="laptop-img-align">
                                        <img className="lap-desk" src={Config.HOST+'/assets/img/laptop.jpg'} alt="laptop-img"/>
                                        <img className="lap-mob" src={Config.HOST+'/assets/img/home-bg-laptop-1.jpg'} alt="laptop-img"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="mt-5 mb-5">
                                    <h1 className="ad-laptop-title">Best website design and development company in Chennai</h1>
                                    {/* <h2 className="ad-laptop-title-1">Our heart pumps</h2> */}
                                    <h2 className="ad-laptop-title-1">CREATIVITY at its best.</h2>
                                    <p className="ad-laptop-title-2">We are fervent digital natives helping brands like yours embrace digital. If you think digital is what is keeping you from growth, reach out and we'll add alchemy to it!</p> 
                                    <p className="ad-laptop-title-2">Our digital transformation includes services such as website design and development, web application and SaaS product development, social media marketing, paid media marketing on Facebook, Instagram, Twitter, LinkedIn, Quora, Google, Pinterest, Hotstar etc.</p>
                                    <a href="#home-contact-form" className="alchem-digi-button-new button-gradient transition-issue">Reach Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="ad-portfolio-gallery" className="ad-portfolio-container">
                    <div className="container">
                        <div className="ad-portfolio-title-container transition-issue">
                            <div>
                                <h1 className="ad-portfolio-top-title">Here’s what makes us the top digital marketing agency in Chennai</h1>
                                <h2 className="ad-portfolio-main-title">Let our work speak for us</h2>
                            </div>
                            <div className="ad-portfolio-view-project-align">
                                <Link to="/portfolio" className="alchem-digi-portfolio-button-new button-gradient">view all projects</Link>
                                <img src={Config.HOST+'/assets/img/dots.png'} alt="dots-img"/>
                            </div>
                        </div>
                    </div>
                    <div id="image-gallery" className="portfolio-carousel-slider">
                        {typeof window != 'undefined' &&
                            <OwlCarousel {...options2}>
                                <div className="empty-section">
                                </div>
                                <div className="alchem-digi-cell portfolio-sizes">
                                    <img src={Config.HOST+'/assets/img/portfolio/ailaysa-new-portfolio.jpg'} alt="ailaysa-new-portfolio"/>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-items portfolio-top-head">
                                            <p>Ailaysa</p>
                                            <hr/>
                                        </div>
                                        <div className="portfolio-items portfolio-head">
                                            <p>Web Application</p>
                                        </div>
                                        <div className="portfolio-items portfolio-price">
                                        </div>
                                        <div className="portfolio-items portfolio-cart">
                                            <span>Logo | UI/UX Design & Development |<br/> Social Media Marketing</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="alchem-digi-cell portfolio-sizes">
                                    <img src={Config.HOST+'/assets/img/portfolio/peralai-new-portfolio.jpg'} alt="peralai-new-portfolio"/>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-items portfolio-top-head">
                                            <p>AazhiBooks</p>
                                            <hr/>
                                        </div>
                                        <div className="portfolio-items portfolio-head">
                                            <p>E-Commerce Website</p>
                                        </div>
                                        <div className="portfolio-items portfolio-price">
                                        </div>
                                        <div className="portfolio-items portfolio-cart">
                                            <span>Logo | UI/UX Design & Development</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="alchem-digi-cell portfolio-sizes">
                                    <img src={Config.HOST+'/assets/img/portfolio/qphartmetra-new-portfolio.jpg'} alt="qphartmetra-new-portfolio"/>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-items portfolio-top-head">
                                            <p>Qpharmetra</p>
                                            <hr/>
                                        </div>
                                        <div className="portfolio-items portfolio-head">
                                            <p>Brand Identity</p>
                                        </div>
                                        <div className="portfolio-items portfolio-price">
                                        </div>
                                        <div className="portfolio-items portfolio-cart">
                                            <span>Logo Redesign | Brand Collaterals</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="alchem-digi-cell portfolio-sizes">
                                    <img src={Config.HOST+'/assets/img/portfolio/life-balance-new-portfolio.jpg'} alt="life-balance-new-portfolio"/>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-items portfolio-top-head">
                                            <p>Life Balance</p>
                                            <hr/>
                                        </div>
                                        <div className="portfolio-items portfolio-head">
                                            <p>Graphic Design</p>
                                        </div>
                                        <div className="portfolio-items portfolio-price">
                                        </div>
                                        <div className="portfolio-items portfolio-cart">
                                            <span>Concept Art</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="alchem-digi-cell portfolio-sizes">
                                    <img src={Config.HOST+'/assets/img/portfolio/carefree-new-portfolio.jpg'} alt="carefree-new-portfolio"/>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-items portfolio-top-head">
                                            <p>Embrace Prevention Care</p>
                                            <hr/>
                                        </div>
                                        <div className="portfolio-items portfolio-head">
                                            <p>Website & Social </p>
                                        </div>
                                        <div className="portfolio-items portfolio-price">
                                        </div>
                                        <div className="portfolio-items portfolio-cart">
                                            <span>UI/UX Design & Development |<br/> Social Media Marketing</span>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        }
                    </div>
                    <div className="container portfolio-carousel-slider-container">
                        <div id="image-gallery" className="portfolio-carousel-slider-1">
                            {typeof window != 'undefined' &&
                                <OwlCarousel {...options3}>
                                    <div className="alchem-digi-cell portfolio-sizes">
                                        <img src={Config.HOST+'/assets/img/portfolio/ailaysa-new-portfolio.jpg'} alt="ailaysa-new-portfolio"/>
                                        <div className="portfolio-overlay">
                                            <div className="portfolio-items portfolio-top-head">
                                                <p>Ailaysa</p>
                                                <hr/>
                                            </div>
                                            <div className="portfolio-items portfolio-head">
                                                <p>Web Application</p>
                                            </div>
                                            <div className="portfolio-items portfolio-price">
                                            </div>
                                            <div className="portfolio-items portfolio-cart">
                                                <span>Logo | UI/UX Design & Development |<br/> Social Media Marketing</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alchem-digi-cell portfolio-sizes">
                                        <img src={Config.HOST+'/assets/img/portfolio/peralai-new-portfolio.jpg'} alt="peralai-new-portfolio"/>
                                        <div className="portfolio-overlay">
                                            <div className="portfolio-items portfolio-top-head">
                                                <p>AazhiBooks</p>
                                                <hr/>
                                            </div>
                                            <div className="portfolio-items portfolio-head">
                                                <p>E-Commerce Website</p>
                                            </div>
                                            <div className="portfolio-items portfolio-price">
                                            </div>
                                            <div className="portfolio-items portfolio-cart">
                                                <span>Logo | UI/UX Design & Development</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alchem-digi-cell portfolio-sizes">
                                        <img src={Config.HOST+'/assets/img/portfolio/qphartmetra-new-portfolio.jpg'} alt="qphartmetra-new-portfolio"/>
                                        <div className="portfolio-overlay">
                                            <div className="portfolio-items portfolio-top-head">
                                                <p>Qpharmetra</p>
                                                <hr/>
                                            </div>
                                            <div className="portfolio-items portfolio-head">
                                                <p>Brand Identity</p>
                                            </div>
                                            <div className="portfolio-items portfolio-price">
                                            </div>
                                            <div className="portfolio-items portfolio-cart">
                                                <span>Logo Redesign | Brand Collaterals</span>
                                            </div>
                                        </div>                                </div>
                                    <div className="alchem-digi-cell portfolio-sizes">
                                        <img src={Config.HOST+'/assets/img/portfolio/life-balance-new-portfolio.jpg'} alt="life-balance-new-portfolio"/>
                                        <div className="portfolio-overlay">
                                            <div className="portfolio-items portfolio-top-head">
                                                <p>Life Balance</p>
                                                <hr/>
                                            </div>
                                            <div className="portfolio-items portfolio-head">
                                                <p>Graphic Design</p>
                                            </div>
                                            <div className="portfolio-items portfolio-price">
                                            </div>
                                            <div className="portfolio-items portfolio-cart">
                                                <span>Concept Art</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="alchem-digi-cell portfolio-sizes">
                                        <img src={Config.HOST+'/assets/img/portfolio/carefree-new-portfolio.jpg'} alt="carefree-new-portfolio"/>
                                        <div className="portfolio-overlay">
                                            <div className="portfolio-items portfolio-top-head">
                                                <p>Carefree Chronic Care</p>
                                                <hr/>
                                            </div>
                                            <div className="portfolio-items portfolio-head">
                                                <p>Website & Social </p>
                                            </div>
                                            <div className="portfolio-items portfolio-price">
                                            </div>
                                            <div className="portfolio-items portfolio-cart">
                                                <span>UI/UX Design & Development |<br/> Social Media Marketing</span>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            }
                        </div>
                    </div>
                </section>
                <section className="home-services-mega-container" >
                    <div className="container">
                        <div className="text-center">
                            <h1 className="ad-portfolio-top-title">Best website design, development and digital marketing company in Chennai</h1>
                            <h2 className="ad-portfolio-main-title">Our Services</h2>
                            <h4 className="ad-services-sub-title">We alchemize digital transformation in these domains</h4>
                        </div>
                        <div className="ad-home-services-grid">
                            <div className="ad-services-grid-align">
                                <div>
                                    <div className="ad-home-mob-remove-right-border">
                                            <div className="ad-services-sections">
                                                <img src={Config.HOST+'/assets/img/services/Saas.svg'} alt="sass"/>
                                                <h2 className="ad-services-secction-txt">SaaS Development</h2>
                                                <p className="ad-services-secction-excerpt">Turn your offline traditional business model into online software-as-a service</p>
                                            </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-remove-right-border">
                                        <div className="ad-services-sections">
                                            <img className="ad-web-dev-img" src={Config.HOST+'/assets/img/services/web_dev.svg'} alt="web-dev"/>
                                            <h2 className="ad-services-secction-txt">Web Development</h2>
                                            <p className="ad-services-secction-excerpt">Add an internet identity to your service or product assets so that clients can find and trust you</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-add-right-border ad-home-mob-remove-right-border">
                                        <div className="ad-services-sections">
                                            <img src={Config.HOST+'/assets/img/services/brand_identity.svg'} alt="brand identity"/>
                                            <h2 className="ad-services-secction-txt">Brand identity</h2>
                                            <p className="ad-services-secction-excerpt">Brands can take you places, where names can't. Use elements that convey your value beyond utility</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-remove-right-border ad-add-right-border">
                                        <div className="ad-services-sections">
                                            <img src={Config.HOST+'/assets/img/services/Graphic_designing.svg'} alt="Graphic Designing"/>
                                            <h2 className="ad-services-secction-txt">Graphic Designing</h2>
                                            <p className="ad-services-secction-excerpt">Picture speaks a thousand words, and we converse to convert with our creative visualization</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-home-mob-remove-right-border ad-home-mob-add-bottom-border">
                                        <div className="ad-services-sections">
                                            <img src={Config.HOST+'/assets/img/services/digital_marketing.svg'} alt="Digital Marketing"/>
                                            <h2 className="ad-services-secction-txt">Digital Marketing</h2>
                                            <p className="ad-services-secction-excerpt">With a holistic approach, we help brands to establish a workable ROI-driven Digital transformation</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-home-service-box-bottom-border">
                                        <div className="ad-services-sections">
                                            <img src={Config.HOST+'/assets/img/services/SEO.svg'} alt="SEO"/>
                                            <h2 className="ad-services-secction-txt">SEO</h2>
                                            <p className="ad-services-secction-excerpt">Drive organic traffic, high quality leads and multiply sales by ranking your website in the top of SERP.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ad-service-btn-align">
                                <Link to="/services" className="alchem-digi-services-button-new button-gradient">View More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial/>
                <section className="ad-call-to-action">
                    <div className="container">
                        <div className="call-to-action-box transition-issue">
                            <div className="call-to-action-txt">
                                <p>Speak to humans, before you work with digital. <span>We will call you in 12 hours and discuss opportunity.</span></p>
                                <p className="call-section-content-remove">We will call you in 12 hours and discuss opportunity.</p>
                            </div>
                            <div className="call-to-action-btn">
                                <a href="#home-contact-form" className="alchem-digi-call-to-button button-gradient">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="common-padding-home-page">
                    <div className="container">
                        <div className="row new-align-items-center">
                            <div className="col-xs-12 col-sm-6 cmmn-hide">
                                <img className="social-media-market-img" src={Config.HOST+'/assets/img/social-media-marketing.jpg'} alt="responsive-caapid" />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="new-padding-container">
                                    <h1 className="res-dev-caapid-responsive-container-title mob-text-center text-left">Social Media and Digital marketing company</h1>
                                    <img className="social-media-market-img-mob" src={Config.HOST+'/assets/img/digital-marketing-mob.jpg'} alt="responsive-caapid" />
                                    <p className="res-dev-caapid-responsive-container-content mob-text-center text-left">Digital marketing is a boundless term that consists of SEO, Social media management (SMM), Search engine marketing (SEM), Google ads, Facebook/ Instagram ads, paid social, content marketing, social engagement, and much more. There are approximately 4.66 billion internet users across the world and it only makes sense for you to be where the customers are at. Digital marketing also lets you track your performance and optimize accordingly. With digital marketing, you can reach your TGA globally with precise targeting based on affinity, interests, behaviour, demographics, and so on.</p>
                                    <p className="res-dev-caapid-responsive-container-content mob-text-center text-left">Social media marketing helps in creating a business profile in our case, and lets you reach the exact TGA with some interesting and engaging content to drive awareness, conversation and ultimately convert them into sales. We offer Social media and Digital marketing services for various brands and companies across different industries like e-commerce, pharma/healthcare, education/consulting, BFSI, NBFC, education and so on across the world.</p>
                                    <p className="res-dev-ad-responsive-container-quote-content full-width mob-text-center text-left">Begin your digital alchemy with Alchem Digital. <span><a href="/contact">Reach out.</a></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="deskp-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7">
                                <div className="deskp-content-section">
                                    <h1 className="res-dev-caapid-responsive-container-title web-des-dev-color mob-text-center text-left">Website Design and Development company</h1>
                                    <p className="res-dev-caapid-responsive-container-content web-des-dev-width pt-3 web-des-dev-content-color mob-text-center text-left">Website is an essential aspect for any business for that matter. It may be a single page website development, corporate website development, e-commerce website development, CMS websites, landing page development, WordPress website development or blog website development. Our developers are technically skilled and adapt to the latest technologies. We have expertise in building and maintaining top quality, aesthetic websites under various platforms like Laravel, WordPress, Woo commerce, Magento, React JS and so forth.</p>
                                    <p className="res-dev-caapid-responsive-container-content web-des-dev-width web-des-dev-content-color mob-text-center text-left">It is fundamental these days to have cross-device compatibility and remain device-agnostic, while designing and developing websites. We understand the need and the importance of a responsive website through meticulous research. We have an array of websites and web applications that are dynamically built and work across multiple screen resolutions and different browser technologies, for our clients across the globe to our credit.</p>
                                    <p className="res-dev-ad-responsive-container-quote-content full-width web-des-dev-content-color mob-text-center text-left">Now, let us add alchemy to your website. <span><a href="/contact">Reach out.</a></span></p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-5">
                                <div className="deskp-img-section">
                                    <img src={Config.HOST+'/assets/img/website-design-dev-1.jpg'} alt="website-design-dev"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-clients-section">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <h1 className="res-dev-client-content-subtitle mob-text-center">The honour to our journey</h1>
                                    <h2 className="res-dev-client-content-title mob-text-center">Our valued<br/> clients</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 new-padd-left-add">
                                {typeof window != 'undefined' &&
                                    <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                {typeof window != 'undefined' && 
                                    <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-mega-container-1">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-blog">
                    <div className="home-page-blog-mega-container">
                        <div className="container">
                            <div className="home-page-blog-container-section">
                                <div className="home-page-blog-container">
                                    <div className="ad-portfolio-title-container ad-home-blog-title-container transition-issue">
                                        <div>
                                            <h1 className="ad-portfolio-top-title">Alchem Digital Blogs</h1>
                                            <h2 className="ad-portfolio-main-title">Explore everything digital</h2>
                                        </div>
                                        <div className="ad-portfolio-view-project-align">
                                            <Link to="/blog" className="alchem-digi-blog-button-new button-gradient">view all Posts</Link>
                                        </div>
                                    </div>
                                    <BlogCarousel />
                                </div>
                            </div>
                        </div>
                        <div className="home-page-blog-text-align">
                            <h1 className="home-page-blog-txt">tech & beyond</h1>
                        </div>
                    </div>
                </section>
                <section className="common-padding-home-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-4 mob-caapid-img-align faq-1-img">
                                <img className="faqs-first-img" src={Config.HOST+'/assets/img/faq-1-img.jpg'} alt="faq-1-img" />
                            </div>
                            <div className="col-xs-12 col-sm-8">
                                <div>
                                    <div className="res-dev-client-content-container">
                                        <h1 className="home-page-faq-section">Top Digital marketing company in Chennai</h1>
                                        <h2 className="res-dev-client-content-title">FAQs: Digital Marketing</h2>
                                    </div>
                                    <div id="accordion" className="myaccordion">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <span>What are some of the most common forms of digital marketing?</span>      
                                                </button>
                                            </h2>
                                            </div>
                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>A digital marketing company typically relies on social media marketing, search engine optimization, paid media marketing, and content marketing for their client's digital marketing needs.     </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <span>What are some of the benefits of digital marketing?</span>     
                                                </button>
                                            </h2>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                <p>A digital marketing agency can help you reach a wider set of audiences, increase your follower base, cost efficiency, improve sales and measurable results.</p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <span> How long does it take to rank on the first page of a search engine?</span>  
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>It should take anywhere between four to six months for your website to rank right on top of the Google SERP. As a top digital marketing and SEO agency in Chennai, we do multiple things right consistently, such as keyword research, competitor analysis, website performance, on-page, off-page optimizations to rank on the first page of the search engine.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingFour">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        <span>What are the social media platforms that we use for marketing?</span>     
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>As a leading social media marketing agency in Chennai, we expand our marketing expertise on various social media platforms like Facebook, Instagram, YouTube, LinkedIn, and Twitter. We also work on Google ad campaigns.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingFive">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        <span>How can search engine optimization help your company?</span>     
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>SEO practices are one of the cheapest ways to drive traffic to your websites. SEO is also one of the ways in which you can build your brand’s credibility. A leading digital marketing and SEO company in Chennai can help you rank on top of a search engine, thereby helping you drive more traffic to your website which will help in increasing its engagement and ultimately lead to a higher conversion rate.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingSix">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        <span>What is paid media marketing?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                                <div className="card-body">
                                                <p>Paid media marketing is the other way of bringing in customers to engage with your posts, amplify reach, drive website traffic & conversions, increase app downloads, and building your follower base by bidding and paying the social media platforms like Google and Facebook to help achieve the marketing objective.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingSeven">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        <span>How has the Coronavirus pandemic impacted digital marketing?</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>The surge in digital ad spending has let Facebook’s revenue surge 48%. Yes, just Facebook. The pandemic has created a huge change in consumer behavior and demands. People are now more open to accepting digital trends which have paved a way for a lot of change in curating a marketing strategy. With the increased competition, every digital marketing company is looking for a way to stand out from the crowd. Localization and Vernacular communication are something that a lot of digital media houses are focusing on now.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faq-bg">
                    <div className="container">
                        <div className="row column-reverse">
                            <div className="col-xs-12 col-sm-8">
                                    <div>
                                        <div className="res-dev-client-content-container">
                                            <h1 className="home-page-faq-section">Top Website design and development company in Chennai</h1>
                                            <h2 className="res-dev-client-content-title">FAQs: Website Development</h2>
                                        </div>
                                        <div id="newaccordion" className="myaccordion-2">
                                            <div className="card">
                                                <div className="card-header" id="newheadingOne">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                                        <span>What are the web technologies we specialize in?</span>     
                                                    </button>
                                                </h2>
                                                </div>
                                                <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                                <div className="card-body">
                                                    <p>Being a leading website design and development company in Chennai, we specialize in building websites and web applications using React Js, Core PHP, Magento, Laravel, WordPress, Python Django, and MySQL.</p>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="newheadingTwo">
                                                <h2 className="mb-0">
                                                    <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                                        <span>Do you provide responsive website that works across any dimensions?</span>     
                                                    </button>
                                                </h2>
                                                </div>
                                                <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                                <div className="card-body">
                                                    <p>Responsive web designing is one of our core strengths. We always design websites that are compatible across multiple devices and different screen resolutions which makes us a top web design and development agency in Chennai.</p>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="newheadingThree">
                                                    <h2 className="mb-0">
                                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseThree" aria-expanded="false" aria-controls="newcollapseThree">
                                                            <span>Have you guys built any web applications or SaaS products?</span>  
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="newcollapseThree" className="collapse" aria-labelledby="newheadingThree" data-parent="#newaccordion">
                                                    <div className="card-body">
                                                        <p>Being a top-notch SaaS design and development company in Chennai, we have built global SaaS products and multiple web applications with various complex functionalities and integrations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="newheadingFour">
                                                    <h2 className="mb-0">
                                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                            <span>How long does it take to build a website?</span>     
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                                    <div className="card-body">
                                                        <p>A static information website with 5-7 pages would take about 7 to 10 days approximately and a dynamic WordPress website would take 7-10 days approximately.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="newheadingFive">
                                                    <h2 className="mb-0">
                                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                            <span>Do you also take care of the website/web app maintenance? </span>     
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                                    <div className="card-body">
                                                        <p>Yes, of course. We pay attention to our client’s feedback and implement necessary changes if needed. We ensure that the application works like dynamite even after the launch with due diligence making us the best custom website design and development company in Chennai.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="newheadingSix">
                                                    <h2 className="mb-0">
                                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSix" aria-expanded="false" aria-controls="newcollapseSix">
                                                            <span>Can you revamp websites without any data loss? </span>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="newcollapseSix" className="collapse" aria-labelledby="newheadingSix" data-parent="#newaccordion">
                                                    <div className="card-body">
                                                    <p>We revamp websites using swap method. We will develop the new website in a completely new environment without making any changes to the current one. Thus, ensuring zero data loss.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="newheadingSeven">
                                                    <h2 className="mb-0">
                                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseSeven" aria-expanded="false" aria-controls="newcollapseSeven">
                                                            <span>How long does it take to build a SaaS product?</span>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id="newcollapseSeven" className="collapse" aria-labelledby="newheadingSeven" data-parent="#newaccordion">
                                                    <div className="card-body">
                                                        <p>SaaS product development timeline is purely based on the project requirement document. But, to give you an idea, the minimum time required to build an MVP version of a SaaS product with simple functionalities would take at least 45 days approximately.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-xs-12 col-sm-4 mob-caapid-img-align faq-2-img">
                                <img src={Config.HOST+'/assets/img/faq-2-img.jpg'} alt="faq-2-img" />
                            </div>
                        </div>
                        <div id="home-contact-form"></div>
                    </div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </>
        );
    }
}

export default Home
