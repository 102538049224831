import $ from "jquery"
import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import AllPageContactForm from '../../layout/AllPageContactForm'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'
import Header from '../../layout/Header'
import HeadTags from '../../layout/HeadTags'
import Config from "../Config"
import Testimonial from "../../layout/AllpageTestimonial"
const OwlCarousel = (typeof window !== 'undefined') && require('react-owl-carousel-autoheight')

// import HeadTags from '../layout/HeadTags'


// import PageTransition from '../PageTransition'
class WordpressDevelopement extends React.Component{


    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.intervalRef = React.createRef()
        
    }


    WordpressProcessContent = 
        [
            {
                "id" : 1,
                "title" : "Gather requisites",
                "img" : "process-1.svg",
                "description" : "To begin with, we will get a detailed understanding of your business goals and map out what you are looking for from your website. We consider your budget and work out what your website needs to complement your business goals and how you want your website to function."
            },
            {
                "id" : 2,
                "title" : "Design",
                "img" : "process-2.svg",
                "description" : "Once we have the design requirements in hand, we create a wireframe of how your website will look and begin creating a unique and customized design that will complement your business and its operations. We bring both our technical knowledge and creative abilities to bring your idea ideas to life."
            },
            {
                "id" : 3,
                "title" : "Develop",
                "img" : "process-3.svg",
                "description" : "Once we create a design that seems fit for you, we begin developing your website. Based on your requirement, we build the functionality of your website. Apart from including content, images, graphics, and videos, we write code for custom functionality needs and integrate them without any bugs."
            },
            {
                "id" : 4,
                "title" : "QA",
                "img" : "process-4.svg",
                "description" : "Once we finish developing your website, we test your website to make sure it is free of all bugs and errors before and make ensure that all functionalities are in place before we deliver them to you. By doing this, we make sure that your site is error-free."
            },
            {
                "id" : 5,
                "title" : "Delivery",
                "img" : "process-5.svg",
                "description" : "Before we officially launch, we soft launch your site to determine your site’s performance and fix existing issues if any in order to ensure smooth functioning of your site and deliver you a sustainable final product."
            },
            {
                "id" : 6,
                "title" : "Maintenance",
                "img" : "process-6.svg",
                "description" : "Once we deliver your website, we get the necessary feedback from you and make sure that you are completely satisfied with how your website works, and provide you with content updates, website updates, server monitoring, etc. if necessary."
            }
        ]


    toggle = (e, tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            setInterval(5000, this.intervalRef.current);
        }
    }


    componentDidMount(){
        // $('.nav-tabs-dropdown')
        // .on("click", "a:not('.active')", function(event) {  $(this).closest('div').removeClass("open");
        // })
        // .on("click", "a.active", function(event) {        $(this).closest('div').toggleClass("open");
        // });

        // Tab-Pane change function
        // function tabChange() {
        //     var tabs = $('.wordpress-process > li > a');
        //     var active = tabs.filter('.active');
        //     var next = active.parent().next('.wordpress-process > li').length
        //     ? 
        //     active.parent().next('.wordpress-process > li').find('a') 
        //     : 
        //     tabs.parent().filter('.wordpress-process > li:first-child').find('a');
        //     next.tab('show');
        // }

        // $('.tab-pane').hover(function() {
        //     clearInterval(tabCycle);
        // }, 
        // function() {
        //     tabCycle = setInterval(tabChange, 3500);
        // });

        // Tab Cycle function
        // tabChange()    
        // var tabCycle = setInterval(tabChange, 3500)
            
        // Tab click event handler
        // $(function(){
        //     $('.nav-pills.wordpress-process a').click(function(e) {
        //         e.preventDefault();
        //         clearInterval(tabCycle);
        //         $(this).tab('show')
        //         tabCycle = setInterval(tabChange, 3500);
        //     });
        // });

        var parallax = -0.6;
        var $bg_images = $(".wordpress-call-to-action");
        var offset_tops = [];
        $bg_images.each(function(i, el) {
            offset_tops.push($(el).offset().top);
        });

        $(window).scroll(function() {
            var dy = $(this).scrollTop();
            $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% " + (dy - ot) * parallax + "px");
            });
        });

        this.intervalRef.current = setInterval(() => {
            const counter = this.state.activeTab
            if(counter < this.WordpressProcessContent.length){
                this.setState({
                    activeTab: counter + 1,
                })
            }else{
                this.setState({
                    activeTab: 1,
                })
            }
        }, 5000);
    }


    componentWillUnmount() {
        this.stopTimer();
    }
    
    stopTimer = () => {
        clearInterval(this.intervalRef.current);
    };

    
    render(){
        const optionsClient = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        const optionsClient2 = {
            items: 1,
            mouseDrag: true,
            touchDrag: true,
            responsiveClass: true,
            nav: false,
            navText: ["<span aria-label='Previous'><i className='fas fa-arrow-left'></i></span>", "<span aria-label='Next'><i className='fas fa-arrow-right'></i></span>"],
            dots: false,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };
        return(
            <React.Fragment>
                <HeadTags path='wordpress-development-company-chennai' />
                <Header/>
                <section className="ad-blog-bg">
                    <div className="digi-mark-bg-img wordpress-bg-img">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div className="res-dev-content-sec">
                                        <h1 className="digi-mark-main-banner-title">WordPress Development Company in Chennai</h1>
                                        <h2 className="digi-mark-main-banner-subtitle">Improve your user experience and increase your clicks to conversions rate with the best WordPress development company in Chennai.</h2>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-intro-section">
                    <div className="container">
                        <div className="wordpress-content">
                            <h1 className="wordpress-intro-title">WordPress Development</h1>
                            <div className="digi-market-zig-zag-content">
                                <p>WordPress, a free and open-source CMS that is highly functional, is now one of the most commonly used website development tools that can be used to create a unique website for your business. Around 37% of the websites on the internet are WordPress sites. WordPress is one of the most popular CMS platforms and is a complete website builder too. However, WordPress can be suitable for more than just that. WordPress has been the go-to CMS platform for quite some time due to its customizable features that can be adapted by almost any website needs.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="digital-market-main wordpress-main-cont">
                    <div className="digital-market-glob-container-1">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-6">
                                <img src={`${Config.HOST}/assets/img/wordpress/wp-img-1.jpg`} />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-sub-container wordpress-padd-align">
                                    <div className="digi-market-zig-zag-content">
                                        <p>WordPress’ popularity can be linked with the fact that it is highly easy to use. WordPress can be quite easy to customize according to your business needs and can be ideal for anything beginning from a simple blog site to a complex E-Commerce website. Apart from this, WordPress sites can also be ideal for search engine optimization and can also be scalable as your business grows. Read on to know how else WordPress development can be beneficial for your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-benefits-bg">
                    <div className="container">
                        <h1 className="digi-benefits-title">Benefits of WordPress</h1>
                        <div className="digi-benefits-align wordpress-benefits-list">
                            <div className="nav flex-column nav-pills nav-tabs-dropdown" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="b-cost-effective-tab" data-toggle="pill" href="#cost-effective-tab" role="tab" aria-controls="cost-effective-tab" aria-selected="true">User-friendly</a>
                                <a className="nav-link" id="b-simply-limitless-tab" data-toggle="pill" href="#simply-limitless-tab" role="tab" aria-controls="simply-limitless-tab" aria-selected="false">Search Engine <span>Friendly</span></a>
                                <a className="nav-link" id="b-results-experiment-tab" data-toggle="pill" href="#results-experiment-tab" role="tab" aria-controls="results-experiment-tab" aria-selected="false">Cost-effective</a>
                                <a className="nav-link" id="b-brand-credibility-tab" data-toggle="pill" href="#brand-credibility-tab" role="tab" aria-controls="brand-credibility-tab" aria-selected="false">Scalable</a>
                                <a className="nav-link" id="b-customizable-tab" data-toggle="pill" href="#customizable-tab" role="tab" aria-controls="customizable-tab" aria-selected="false">Customizable</a>
                                <a className="nav-link" id="b-secured-tab" data-toggle="pill" href="#secured-tab" role="tab" aria-controls="secured-tab" aria-selected="false">Secured</a>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="cost-effective-tab" role="tabpanel" aria-labelledby="b-cost-effective-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-1.svg`} alt="benefits-icon-1"/>
                                                <h2 className="digi-market-title-benefit">User-friendly</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">One of the most attractive benefits of a WordPress website is its ease of use. We build you a website with an intuitive interface that will let you edit and upload content, and on the whole, manage your website with ease.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="simply-limitless-tab" role="tabpanel" aria-labelledby="b-simply-limitless-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-2.svg`} alt="infinity-icon"/>
                                                <h2 className="digi-market-title-benefit">Search Engine Friendly</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">SEO compatibility is a vital component for websites these days. We build WordPress sites that can be easily optimized for the search engine. WordPress’ features make it one of the best options for search engine optimization among other content management systems.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="results-experiment-tab" role="tabpanel" aria-labelledby="b-results-experiment-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-3.svg`} alt="statistics-icon"/>
                                                <h2 className="digi-market-title-benefit">Cost-effective</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">We build you high-performance websites leveraging the open-source WordPress platform that will increase your ROI potential, making it a cost-effective option for you.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="brand-credibility-tab" role="tabpanel" aria-labelledby="b-brand-credibility-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-4.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Scalable</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">WordPress websites can be especially ideal for companies that are constantly growing due to their exceptional scalable features. Adding pages and content to your WordPress website can be done at ease without compromising the quality of your site.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="customizable-tab" role="tabpanel" aria-labelledby="b-customizable-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-5.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Customizable</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">A WordPress website can be easily customized according to your business needs. WordPress comes with several customization options that can be used to customize your site for various purposes.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="secured-tab" role="tabpanel" aria-labelledby="b-secured-tab">
                                    <div className="digi-benefits-content-tab-bg">
                                        <ul className="digi-benefits-content-icons">
                                            <li>
                                                <img src={`${Config.HOST}/assets/img/wordpress/wordpress-benefits/benefits-icon-6.svg`} alt="diamond-icon"/>
                                                <h2 className="digi-market-title-benefit">Secured</h2>
                                            </li>
                                        </ul>
                                        <p className="digi-benefits-content">WordPress offers you strong security, making it one of the safest platforms to run your website on. At Alchem Digital, we make sure your WordPress site is free of any vulnerabilities and is secure from external threats before we deliver them to you.</p>
                                        <a href="#digi-market-page-contact" className="digi-benefits-content-button button-gradient transition-issue">Get in touch</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-process-cont">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">WordPress Development Process</h1>
                        <div className="wordpress-content-cont">
                            {/* <ul className="nav nav-pills wordpress-process mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-1-tab" data-toggle="pill" href="#pills-seo-content-1" role="tab" aria-controls="pills-seo-content-1" aria-selected="true">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Gather requisites</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-1"></div>
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align process-bottom-align">Gather requisites</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-2-tab" data-toggle="pill" href="#pills-seo-content-2" role="tab" aria-controls="pills-seo-content-2" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Design</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-2"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Design</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-3-tab" data-toggle="pill" href="#pills-seo-content-3" role="tab" aria-controls="pills-seo-content-3" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Develop</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-3"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Develop</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-4-tab" data-toggle="pill" href="#pills-seo-content-4" role="tab" aria-controls="pills-seo-content-4" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">QA</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-4"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">QA</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-5-tab" data-toggle="pill" href="#pills-seo-content-5" role="tab" aria-controls="pills-seo-content-5" aria-selected="false">
                                        <h4 className="padd-bottom-12 opacity-0 process-top-align">Delivery</h4>
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-5"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 process-bottom-align">Delivery</h4>
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pills-seo-content-6-tab" data-toggle="pill" href="#pills-seo-content-6" role="tab" aria-controls="pills-seo-content-6" aria-selected="false">
                                        <h4 className="padd-bottom-12 process-top-align">Maintenance</h4> 
                                        <div className="seo-process-circles">
                                            <div className="seo-process-img-6"></div>                                        
                                        </div>
                                        <h4 className="padd-top-12 opacity-0 process-bottom-align">Maintenance</h4>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active active-anim" id="pills-seo-content-1" role="tabpanel" aria-labelledby="pills-seo-content-1-tab">
                                    <div className="wordpress-process-content">
                                        <h3>Gather requisites</h3>
                                        <p>To begin with, we will get a detailed understanding of your business goals and map out what you are looking for from your website. We consider your budget and work out what your website needs to complement your business goals and how you want your website to function.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-2" role="tabpanel" aria-labelledby="pills-seo-content-2-tab">
                                    <div className="wordpress-process-content">
                                        <h3>Design</h3>
                                        <p>Once we have the design requirements in hand, we create a wireframe of how your website will look and begin creating a unique and customized design that will complement your business and its operations. We bring both our technical knowledge and creative abilities to bring your idea ideas to life.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-3" role="tabpanel" aria-labelledby="pills-seo-content-3-tab">
                                    <div className="wordpress-process-content">
                                        <h3>Develop</h3>
                                        <p>Once we create a design that seems fit for you, we begin developing your website. Based on your requirement, we build the functionality of your website. Apart from including content, images, graphics, and videos, we write code for custom functionality needs and integrate them without any bugs.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-4" role="tabpanel" aria-labelledby="pills-seo-content-4-tab">
                                    <div className="wordpress-process-content">
                                        <h3>QA</h3>
                                        <p>Once we finish developing your website, we test your website to make sure it is free of all bugs and errors before and make ensure that all functionalities are in place before we deliver them to you. By doing this, we make sure that your site is error-free.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-5" role="tabpanel" aria-labelledby="pills-seo-content-5-tab">
                                    <div className="wordpress-process-content">
                                        <h3>Delivery</h3>
                                        <p>Before we officially launch, we soft launch your site to determine your site’s performance and fix existing issues if any in order to ensure smooth functioning of your site and deliver you a sustainable final product.</p>
                                    </div>
                                </div>
                                <div className="tab-pane fade active-anim" id="pills-seo-content-6" role="tabpanel" aria-labelledby="pills-seo-content-6-tab">
                                    <div className="wordpress-process-content">
                                        <h3>Maintenance</h3>
                                        <p>Once we deliver your website, we get the necessary feedback from you and make sure that you are completely satisfied with how your website works, and provide you with content updates, website updates, server monitoring, etc. if necessary.</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Local SEO</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '1' ? `${Config.HOST}/assets/img/seo/seo-process-1.svg` : `${Config.HOST}/assets/img/seo/seo-process-1-black.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">Local SEO</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <h4 className="padd-bottom-12">Keyword Research</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '2' ? `${Config.HOST}/assets/img/seo/seo-process-2-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-2.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Keyword Research</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">On-page Optimization</h4>
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '3' ? `${Config.HOST}/assets/img/seo/seo-process-3-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-3.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12">On-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <h4 className="padd-bottom-12">Off-page Optimization</h4> 
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '4' ? `${Config.HOST}/assets/img/seo/seo-process-4-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-4.svg`}  alt="seo-process"/>
                                        </div>
                                        <h4 className="padd-top-12 opacity-0">Off-page Optimization</h4>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ SeoProcessActive: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <h4 className="padd-bottom-12 opacity-0">Continuous Optimization</h4>  
                                        <div className="seo-process-circles">
                                            <img src={this.state.activeTab === '5' ? `${Config.HOST}/assets/img/seo/seo-process-5-white.svg` : `${Config.HOST}/assets/img/seo/seo-process-5.svg`}  alt="seo-process"/>     
                                        </div>   
                                        <h4 className="padd-top-12">Continuous Optimization</h4>                            
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <div className="seo-process-content">
                                        <h3>Local SEO</h3>
                                        <p>With local SEO, you can optimize your website for a specific geographical area. Local brick and mortar stores typically benefit from local SEO. Investing in local SEO can help you in increasing the footfall for your business, increase visibility and gain more enquiries and customers. Google typically takes relevance, prominence, and proximity as its deciding factors when it comes to local SEO and at Alchem Digital, we optimize for your business website accordingly, leveraging Google My Business.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="seo-process-content">
                                        <h3>Keyword Research</h3>
                                        <p>At Alchem Digital, we understand the importance of fulfilling a person’s search intent. Our extensive keyword research lets us understand what the audience is looking for and provide them with relevant information. Keyword research is our first step when we consider optimizing your website for the search engines. Keywords are what is used by users online to fulfill their search intent. To identify what your audience is looking for and what keywords they use to get them, one must do extensive keyword research. Keyword research can help you know what keywords you rank for, competitors keywords, keyword search volume, keyword search difficulty what gets you more traffic, and what ultimately works for your website giving you more space to optimize your website accordingly. Keyword research will help in driving more traffic to your website for the relevant searches on Google.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <div className="seo-process-content">
                                        <h3>On-page Optimization</h3>
                                        <p>What is the point of extensive keyword research if your content does not fulfill your searcher’s intent? Google, or any search engine for that matter, constantly changes its algorithm to provide the best information for its users. Therefore, we make sure that we stay up-to-date with the changing algorithm to rank better on the search engine. On-page optimization includes everything beginning from your metadata, your content, header tags, image optimization, internal linking, and more. We produce content with both the user and the search engine in mind to make the best use of both.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="4">
                                    <div className="seo-process-content">
                                        <h3>Off-page Optimization</h3>
                                        <p>As digital marketers, we understand the importance of Off-page optimization. Off-page optimization techniques help in increasing the credibility of your website. At Alchem Digital, we stay on top of the latest off-page optimization techniques like high-quality link building/backlinks from relevant directories and websites with high domain authority and page authority, social bookmarking, etc. to increase the chances of ranking atop the Google SERP visibility of your website.</p>
                                    </div>
                                </TabPane>
                                <TabPane tabId="5">
                                    <div className="seo-process-content">
                                        <h3>Continuous Optimization</h3>
                                        <p>As we mentioned earlier, search engine algorithms keep changing and your website needs to be optimized regularly to rank on top of the SERP. Consistent result requires consistent efforts. SEO is extremely competitive and it is vital to consistently optimize website content, publish blogs, build credible backlinks, etc. We produce high-quality content on a regular basis to increase the visibility, credibility, and trustworthiness of your site. Our consistent content and publishing strategy will help your website rank on top of the search engine.</p>
                                    </div>
                                </TabPane>
                            </TabContent> */}
                            <Nav tabs>
                                {
                                    this.WordpressProcessContent.map((item, index) => {
                                    return (
                                        <NavItem className={this.state.activeTab === item.id  ? "progressline" : ""} key={item.id}>
                                            <NavLink className={classnames({ SeoProcessActive: this.state.activeTab >= item.id})}>
                                                <h4 className={"padd-bottom-12 process-top-align " + (item.id % 2 !== 0 ? "opacity-0" : "")}>{item.title}</h4>
                                                <div className="seo-process-circles" onClick={(e) => {this.toggle(e, item.id);}}>
                                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/`+item.img}  alt="e-com-process"/>
                                                </div>
                                                <h4 className={"padd-top-12 process-bottom-align " + (item.id % 2 == 0 ? "opacity-0" : "")}>{item.title}</h4>
                                            </NavLink>
                                        </NavItem>
                                    );
                                })}
                            </Nav>
                            <TabContent className="common-process-space" activeTab={this.state.activeTab}>
                                {
                                    this.WordpressProcessContent.map((item, index) => {
                                    return (
                                        <TabPane key={item.id} tabId={item.id} className="active-anim">
                                            <div className="seo-process-content">
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </TabPane>
                                    );
                                })}
                            </TabContent>
                        </div>
                        <div className="mob-seo-process mob-brand-process">
                            <div className="mob-seo-process-content">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-1.svg`} alt="seo-process"/>
                                    <h4>Gather requisites</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>To begin with, we will get a detailed understanding of your business goals and map out what you are looking for from your website. We consider your budget and work out what your website needs to complement your business goals and how you want your website to function.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-2.svg`} alt="seo-process"/>
                                    <h4>Design</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we have the design requirements in hand, we create a wireframe of how your website will look and begin creating a unique and customized design that will complement your business and its operations. We bring both our technical knowledge and creative abilities to bring your idea ideas to life.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-3.svg`} alt="seo-process"/>
                                    <h4>Develop</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we create a design that seems fit for you, we begin developing your website. Based on your requirement, we build the functionality of your website. Apart from including content, images, graphics, and videos, we write code for custom functionality needs and integrate them without any bugs.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-4.svg`} alt="seo-process"/>
                                    <h4>QA</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we finish developing your website, we test your website to make sure it is free of all bugs and errors before and make ensure that all functionalities are in place before we deliver them to you. By doing this, we make sure that your site is error-free.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-5.svg`} alt="seo-process"/>
                                    <h4>Delivery</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Before we officially launch, we soft launch your site to determine your site’s performance and fix existing issues if any in order to ensure smooth functioning of your site and deliver you a sustainable final product.</p>
                                </div>
                            </div>
                            <div className="mob-seo-process-content mt-5">
                                <div className="mob-seo-process-img">
                                    <img src={`${Config.HOST}/assets/img/wordpress/wordpress-process/process-6.svg`} alt="seo-process"/>
                                    <h4>Maintenance</h4>
                                </div>
                                <div className="mob-seo-process-content">
                                    <p>Once we deliver your website, we get the necessary feedback from you and make sure that you are completely satisfied with how your website works, and provide you with content updates, website updates, server monitoring, etc. if necessary.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-call-to-action">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xs-12 col-sm-8">
                                <p className="digi-market-call-to-action-title">Need a high-functioning website?</p>
                            </div>
                            <div className="col-xs-12 col-sm-4 text-center">
                                <a href="#digi-market-page-contact" className="res-dev-call-to-action-button button-gradient transition-issue" >Ping us</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wordpress-services digi-market-services">
                    <div className="container">
                        <h1 className="res-dev-key-features-title">WordPress Solutions We Provide</h1>
                        <div className="wordpress-services-grid">
                            <div className="ad-services-grid-align">
                                <div>
                                    <div className="ad-home-mob-remove-right-border wordpress-grid-width">
                                            <div className="ad-services-sections wordpress-grid-height">
                                                <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-1.svg'} alt="service-1"/>
                                                <h2 className="ad-services-secction-txt">Custom WordPress Web Development</h2>
                                                <p className="ad-services-secction-excerpt">We build you high-quality websites that are not just secure but are responsive, fast, and are in line with your business needs. We make sure that your WordPress website is unique in its design and functionalities, and is also securely built to transform your digital presence.</p>
                                            </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-remove-right-border wordpress-grid-width">
                                        <div className="ad-services-sections wordpress-grid-height">
                                            <img className="ad-web-dev-img" src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-2.svg'} alt="service-2"/>
                                            <h2 className="ad-services-secction-txt">CMS Development</h2>
                                            <p className="ad-services-secction-excerpt">We build Content Management Systems that are easy to manage, and that you can update content on even without much technical expertise. We build Content Management Systems that are SEO-friendly and efficient enough to increase your Return on Investment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-add-right-border ad-home-mob-remove-right-border wordpress-grid-width">
                                        <div className="ad-services-sections wordpress-grid-height">
                                            <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-3.svg'} alt="service-3"/>
                                            <h2 className="ad-services-secction-txt">E-Commerce Solution</h2>
                                            <p className="ad-services-secction-excerpt">We create tailor-made and functional E-commerce solutions for your business that will transform your digital presence with our impressive UI and UX services. Our WordPress E-commerce solutions can help you showcase your products aesthetically and provide your potential customers with a seamless experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-remove-right-border ad-add-right-border wordpress-grid-width">
                                        <div className="ad-services-sections wordpress-grid-height">
                                            <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-4.svg'} alt="service-4"/>
                                            <h2 className="ad-services-secction-txt">WordPress Migration</h2>
                                            <p className="ad-services-secction-excerpt">WordPress migration can be a daunting process, but our professional expertise makes it easy. Our technical expertise allows us to shift your website from another platform to WordPress easily without any data loss.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-bottom-border ad-home-mob-remove-right-border ad-home-mob-add-bottom-border wordpress-grid-width">
                                        <div className="ad-services-sections wordpress-grid-height">
                                            <img src={Config.HOST+'/assets/img/wordpress/wordpress-services/service-5.svg'} alt="service-5"/>
                                            <h2 className="ad-services-secction-txt">Custom Theme Development</h2>
                                            <p className="ad-services-secction-excerpt">At Alchem Digital, we understand the impact visual elements create on users. We create a customized WordPress theme that will grab your users’ attention and turn clicks into potential customers. We offer you tailor-made themes that will perfectly fit your business requirements.</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="ad-home-service-box-right-border ad-home-service-box-bottom-border wordpress-grid-width">
                                        <div className="ad-services-sections wordpress-grid-height wordpress-last-grid">
                                            <div className="res-dev-key-feature-sections">
                                                <h2 className="digi-market-services-last-box-content">Level up your digital presence</h2>
                                                <Link to='/contact' className="digi-market-content-button button-gradient">Contact us now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="digi-marketing-technologies wordress-specialize-section">
                    <div className="container">
                        <h1 className="res-dev-client-content-subtitle text-center">TECHNOLOGIES</h1>
                        <h2 className="digi-marketing-technologies-title">we specialize</h2>
                        <div className="digi-marketing-tech-mega-container">
                            <ul className="digi-marketing-img-cont">
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-1">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">HTML</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-2">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Bootstrap</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-3">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Javascript</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-4">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title tech-text-algin-4">jQuery</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="digi-img-align">
                                        <div className="img-cont">
                                            <div className="img-main digi-tech-img-5">
                                            </div>
                                        </div>
                                        <p className="tech-we-used-title">Wordpress</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section> */}
                <section className="wordpress-laptop-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 digi-market-mob">
                                <img src={`${Config.HOST}/assets/img/wordpress/wp-img-2.png`} alt="wp-img-2" />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <div className="digi-market-serve-you-container wordpress-content-container">
                                    <h1 className="digi-market-serve-you-title">Why Choose Alchem Digital for Your WordPress Development Services?</h1>
                                    <p className="digi-market-serve-you-content">At Alchem Digital, we have a comprehensive set of teams comprising competent UI/UX developers and designers working together to provide you with the best WordPress site possible that is tailor-made for your business. Our customer-centric approach lets us provide our clients with the best possible service. Our team of expert developers builds you websites that are fast and compatible across various devices. We provide our clients with high-performance websites that are unique and search engine friendly.</p>
                                    <p className="digi-market-serve-you-content">Our team of seasoned developers strives to give you the best end-products irrespective of the complexity of the website. We put your requirements first and build websites that are intuitive in nature and are user-friendly. We also make sure we test your products before we deliver them to make sure your final products are bug-free and perform to their fullest. Our agile approach has facilitated us to ensure that we give our clients the best final products. Overall, we provide you with unparalleled quality of the website to best complement your business needs.</p>
                                    <Link to="/contact" className="digi-benefits-content-button button-gradient transition-issue">Contact Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-page-clients-section">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-xs-12 col-sm-5 pt-4">
                                <div className="res-dev-client-content-container">
                                    <h1 className="res-dev-client-content-subtitle mob-text-center">THE HONOUR TO OUR JOURNEY</h1>
                                    <h2 className="res-dev-client-content-title mob-text-center">Our valued <br/> clients</h2>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-7 new-padd-left-add">
                                {typeof window !== 'undefined' && 
                                    <OwlCarousel {...optionsClient} className="desk-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                }
                                {typeof window !== 'undefined' &&
                                    <OwlCarousel {...optionsClient2} className="mob-clients-carousel">
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo carefree-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo milkpot-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo caapid-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sri-herbals-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-mega-container-1">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aazhibooks-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo leia-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo dosa-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aptus-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo langscape-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo ailaysa-logo"></div>
                                                </div>
                                            </div>
                                            <div className="home-page-client-logos-container-1">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo keys4life-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo aci-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-page-clients-mega-container">
                                            <div className="home-page-client-logos-container">
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo qpharmetra-logo"></div>
                                                </div>
                                                <div className="res-dev-client-logos">
                                                    <div className="res-dev-common-client-logo sukom-logo"></div>
                                                </div>
                                            </div>
                                        </div>
                                </OwlCarousel>
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonial />
                <section className="wordpress-dev-faq">
                    <div className="container">
                        <div>
                            <div className="res-dev-client-content-container mb-5">
                                <h1 className="home-page-faq-section">FAQs</h1>
                                <h2 className="res-dev-client-content-title">Top WordPress Development company in Chennai</h2>
                            </div>
                            <div id="newaccordion" className="myaccordion-2">
                                <div className="card">
                                    <div className="card-header" id="newheadingOne">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link" data-toggle="collapse" data-target="#newcollapseOne" aria-expanded="true" aria-controls="newcollapseOne">
                                            <span>How long does it take to build a WordPress website?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseOne" className="collapse show" aria-labelledby="newheadingOne" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>The answer to that question will depend on what you want your WordPress website to do. The time to build a WordPress website for your business may vary depending on what type of design you choose and what functionalities you are looking for.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingTwo">
                                    <h2 className="mb-0">
                                        <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseTwo" aria-expanded="false" aria-controls="newcollapseTwo">
                                            <span>Would you provide a custom theme for my WordPress site?</span>     
                                        </button>
                                    </h2>
                                    </div>
                                    <div id="newcollapseTwo" className="collapse" aria-labelledby="newheadingTwo" data-parent="#newaccordion">
                                    <div className="card-body">
                                        <p>Yes, we do provide custom theme designs for your WordPress site that is in line with your specific business requirements.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFour">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFour" aria-expanded="false" aria-controls="newcollapseFour">
                                                <span>How much will it cost to develop a WordPress site for my business?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFour" className="collapse" aria-labelledby="newheadingFour" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>The cost of your WordPress site may vary according to your requirements. Feel free to <span><Link to="/contact">contact us</Link></span> and discuss the pricing of your website.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newheadingFive">
                                        <h2 className="mb-0">
                                            <button className="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#newcollapseFive" aria-expanded="false" aria-controls="newcollapseFive">
                                                <span>Will my WordPress site be responsive?</span>     
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="newcollapseFive" className="collapse" aria-labelledby="newheadingFive" data-parent="#newaccordion">
                                        <div className="card-body">
                                            <p>Yes absolutely! All the websites that we build, including WordPress sites, are compatible across all devices.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="digi-market-page-contact"></div>
                </section>
                <section  className="ad-form-section">
                    <AllPageContactForm/>
                </section>
            </React.Fragment>
        );
    }
}

export default WordpressDevelopement